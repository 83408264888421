import { format, isFuture, parseISO } from 'date-fns';
import DOMPurify from 'dompurify';
import { convertUTCtoTZ, showTimezoneLabel } from './timeHelpers';

export const titleCase = (str) => {
  const splitStr = str.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(' ');
};

export const formatEventDate = (event, short, includeEnd = true) => {
  const fmtString = short ? 'M/d/yy' : 'MMMM d, yyyy';

  const zonedStartTime = convertUTCtoTZ(event.startDateUtc);

  const date = format(zonedStartTime, fmtString);
  const startTime = format(zonedStartTime, 'h:mm a');
  const tzLabel = showTimezoneLabel();
  let endTime = '';

  if (includeEnd) {
    const zonedEndTime = convertUTCtoTZ(event.endDateUtc);
    endTime = format(zonedEndTime, 'h:mm a');
  }

  return `${date}, ${startTime}${includeEnd ? ' - ' + endTime : ''} ${tzLabel}`;
};

export const formatToZonedDateTime = (
  datetime,
  fmtString = 'MMM d, yyyy, h:mm:ss a',
  noDateStr = 'N/A'
) => {
  try {
    if (!datetime) return noDateStr;
    const zonedStartTime = convertUTCtoTZ(datetime);
    return format(zonedStartTime, fmtString) + ' ' + showTimezoneLabel();
  } catch (e) {
    return noDateStr;
  }
};

export const formatRoundDate = (round) => {
  let output;

  try {
    // If time is not set in the frontend then the database automatically sets the time to midnight
    // upon insertion. Use this as a special case of not showing a time.
    if (round.timeUtc) {
      const zonedStartTime = convertUTCtoTZ(round.dateTimeUtc);
      output =
        format(zonedStartTime, 'MMMM d, yyyy - h:mm a') +
        ' ' +
        showTimezoneLabel();
    } else {
      output = format(parseISO(round.dateTimeUtc), 'MMMM d, yyyy');
    }
  } catch (e) {
    output = 'Not Scheduled Yet';
  }

  return output;
};

export const formatStartDate = (d, short = false) => {
  if (!d) return 'Unknown';
  const fmtString = short ? 'M/d/yy' : 'MMMM d, yyyy';
  const date = parseISO(d);
  return isFuture(date) ? format(date, fmtString) : 'Available Now';
};

export const allowLinkTarget = () => {
  DOMPurify.addHook('afterSanitizeAttributes', function (node) {
    if ('target' in node) {
      node.setAttribute('target', '_blank');
      node.setAttribute('rel', 'noopener');
    }
  });
};

export const textLineBreaksToHtml = (text) => {
  text = replaceURLs(text);
  text = replaceDMs(text);

  allowLinkTarget();
  const html = text.replace(/(?:\r\n|\r|\n)/g, '<br>');
  return DOMPurify.sanitize(html);
};

export const formatDollars = (n) => {
  let val = typeof n === 'number' ? n.toString() : n || '';
  val = val.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return '$' + val;
};

/**
 * Add commas to numbers when they hit 4 digits
 *
 * @param {string | number} val
 */
export const formatCommaNumbers = (val) => {
  if (!val) return null;

  return val.toString().replace(/(\d+)(\d{3})/g, `$1,$2`);
};

/**
 * Finds and replaces all instances of standalone URLs with the proper HTML
 * anchor tag.
 *
 * @param {string} text The text to search.
 * @returns {string} Modified text with URLs replaced.
 */
const replaceURLs = (text) => {
  const urlRegex = /((https?|ftps?):\/\/[^"<\s]+)(?![^<>]*>|[^"]*?<\/a)/gi;

  return text.replace(urlRegex, (url) => {
    return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
  });
};

/**
 * Finds and replaces all instances of our direct mentions and replaces with the
 * proper formatting.
 *
 * @param {string} text The text to search.
 * @returns {string} The modified text with DMs replaced.
 */
const replaceDMs = (text) => {
  const dmRegex = /@\[([a-zA-Z\s-]+)\]\(\d+\)/g;

  return text.replace(dmRegex, '<b>@$1</b>');
};

export const getPartnerStatus = (slug) => {
  switch (slug) {
    case 'draft':
      return 'Draft';
    case 'active':
      return 'Active';
    case 'on-hold':
      return 'On Hold';
    case 'retired':
      return 'Retired';
    default:
      return '';
  }
};
