export const SHEET_PARAM = 'sheet';
export const TAB_PARAM = 'tab';
export const INTERVIEW_CLOSING_NOTES_SHEET_ID = 'interviewClosingNotes';
export const INTERVIEW_ID_PARAM = 'interviewId';
export const CLOSING_NOTES_SHEET_ID = 'roleClosingNotes';
export const ROLE_ID_PARAM = 'jobId';
export const ALIGNMENT_SCORE_SHEET_ID = 'alignmentScore';

export const PIPELINE_PATHS = {
  root: '/pipeline-plus' as const,
  interviews: () => `${PIPELINE_PATHS.root}/interviews` as const,
};
