import { infiniteQueryOptions } from '@tanstack/react-query';
import { Params } from 'api/types/params';
import api from 'dataService/api';
import { addFullNameToDataItems } from 'util/addFullNameToDataItems';
import { createInfiniteQuery } from 'util/createInfiniteQuery';

const STAFF_KEYS = {
  all: ['staff'] as const,
  filter: (params: Params) => [...STAFF_KEYS.all, 'filter', params] as const,
  list: (params: Params) => [...STAFF_KEYS.all, 'list', params] as const,
};

export function useInfiniteStaffFilter(params: { [key: string]: unknown }) {
  return createInfiniteQuery({
    fetchFn: async (params, { signal }) => {
      const response = await api.get(`/staff`, {
        ...params,
        signal,
      });

      return response.data;
    },
    select: (data) => {
      return addFullNameToDataItems(data);
    },
    queryKeyFn: (params) => STAFF_KEYS.filter(params),
  })({
    params,
  });
}

export const STAFF_QUERY_OPTIONS = {
  infiniteStaffList: (params: { [key: string]: unknown }) => {
    const { limit = 20 } = params ?? {};

    return infiniteQueryOptions({
      queryFn: async ({ pageParam = 1, signal }) => {
        const response = await api.get(`/staff`, {
          params: {
            ...params,
            limit,
            skip: (pageParam - 1) * (limit as number),
          },
          signal,
        });

        return response.data;
      },
      queryKey: STAFF_KEYS.list({
        params: {
          ...params,
          limit,
        },
      }),
      initialPageParam: 1,
      getNextPageParam: (lastPage, _, lastPageParam) => {
        if (lastPage.items.length < (limit as number)) {
          return undefined;
        }

        return lastPageParam + 1;
      },
      select: addFullNameToDataItems,
    });
  },
};
