import { QueryClient } from '@tanstack/react-query';
import { JOBS_QUERY_OPTIONS } from 'api/jobs';
import { LoadingContainer } from 'components/ds/Spinner';
import {
  ListNotCurrentProcessTable,
  ListNotCurrentProcessTableContainer,
  NOT_IN_PROCESS_TABLE_PARAMS_KEY,
} from 'components/jobs/brainstorm/ListNotCurrentProcess';
import {
  IN_PROCESS_TABLE_PARAMS_KEY,
  ListProcessTable,
  ListProcessTableContainer,
} from 'components/jobs/brainstorm/ListProcess';
import * as React from 'react';
import { LoaderFunctionArgs } from 'react-router-dom';
import { getTableParamsFromRequest } from 'util/tableHelpers';

export async function jobInterviewsLoader(
  queryClient: QueryClient,
  { request, params }: LoaderFunctionArgs
) {
  try {
    const { jobId } = params ?? {};

    if (!jobId) {
      throw new Error('Job ID is required to load interviews');
    }

    queryClient.ensureQueryData(
      JOBS_QUERY_OPTIONS.processList(
        jobId,
        getTableParamsFromRequest({
          request,
          key: IN_PROCESS_TABLE_PARAMS_KEY,
          filters: {
            current: true,
          },
        })
      )
    );

    queryClient.ensureQueryData(
      JOBS_QUERY_OPTIONS.processList(
        jobId,
        getTableParamsFromRequest({
          request,
          key: NOT_IN_PROCESS_TABLE_PARAMS_KEY,
          filters: {
            current: false,
          },
        })
      )
    );

    return null;
  } catch (error) {
    return null;
  }
}

export function JobInterviews() {
  return (
    <div className="stack-y-12">
      <ListProcessTableContainer>
        <React.Suspense fallback={<LoadingContainer level="component" />}>
          <ListProcessTable />
        </React.Suspense>
      </ListProcessTableContainer>
      <ListNotCurrentProcessTableContainer>
        <React.Suspense fallback={<LoadingContainer level="component" />}>
          <ListNotCurrentProcessTable />
        </React.Suspense>
      </ListNotCurrentProcessTableContainer>
    </div>
  );
}
