import { Popover } from '@radix-ui/react-popover';
import { useSuspenseInfiniteQuery } from '@tanstack/react-query';
import { ComboboxData } from 'components/ComboboxField/types/ComboboxField.types';
import { ListLoadMore } from 'components/ListLoadMore';
import {
  ComboboxCheck,
  ComboboxContent,
  ComboboxInput,
  ComboboxItem,
  ComboboxList,
  ComboboxTrigger,
} from 'components/ds/Combobox';
import { Spinner } from 'components/ds/Spinner';
import * as fuzzy from 'fuzzy';
import { useDebounce } from 'hooks/useDebounce';
import * as React from 'react';
import { ControllerRenderProps, FieldValues } from 'react-hook-form';
import { useInView } from 'react-intersection-observer';

type AsyncComboboxFieldProps<TFieldValues extends FieldValues = FieldValues> =
  Omit<ControllerRenderProps<TFieldValues>, 'value' | 'ref'> & {
    value?: ComboboxData | null;
    displayName: string;
    onComboSelect?: (value: ComboboxData | null) => void;
    infiniteQueryOptions: (params: { [key: string]: unknown }) => any;
  };
export const AsyncComboboxField = React.forwardRef<
  React.ElementRef<typeof ComboboxTrigger>,
  AsyncComboboxFieldProps
>(
  (
    {
      displayName,
      infiniteQueryOptions,
      value,
      onChange,
      onBlur,
      onComboSelect,
      ...rest
    },
    ref
  ) => {
    const [open, setOpen] = React.useState(false);
    const nameLowercase = displayName.toLowerCase();

    return (
      <Popover
        open={open}
        onOpenChange={(open) => {
          setOpen(open);

          if (!open) {
            onBlur();
          }
        }}
      >
        <ComboboxTrigger
          {...rest}
          aria-placeholder={
            value?.name == null ? `Select ${nameLowercase}...` : undefined
          }
          ref={ref}
        >
          {value?.name ?? `Select ${nameLowercase}...`}
        </ComboboxTrigger>
        <ComboboxContent shouldFilter={false}>
          <React.Suspense
            fallback={
              <div className="grid h-[300px] place-items-center">
                <Spinner size="sm" />
              </div>
            }
          >
            <AsyncComboboxList
              label={nameLowercase}
              infiniteQueryOptions={infiniteQueryOptions}
              selection={value ?? null}
              onItemSelect={(item) => {
                onChange(item);
                onComboSelect?.(item);
                setOpen(false);
              }}
            />
          </React.Suspense>
        </ComboboxContent>
      </Popover>
    );
  }
);
AsyncComboboxField.displayName = 'AsyncComboboxField';

function AsyncComboboxList<TComboData extends ComboboxData>({
  label,
  infiniteQueryOptions,
  selection,
  onItemSelect,
}: {
  label: string;
  infiniteQueryOptions: (params: { [key: string]: unknown }) => any;
  selection: TComboData | null;
  onItemSelect: (value: TComboData | null) => void;
}) {
  const [search, setSearch] = React.useState('');
  const debouncedSearchTerm = useDebounce(search, 500);
  const params = React.useMemo(() => {
    return debouncedSearchTerm ? { term: debouncedSearchTerm } : {};
  }, [debouncedSearchTerm]);
  const deferredParams = React.useDeferredValue(params);
  const { data, hasNextPage, fetchNextPage, isFetchingNextPage, isError } =
    useSuspenseInfiniteQuery(infiniteQueryOptions(deferredParams));
  const isDeferred = params !== deferredParams;

  const filteredAndSortedData = React.useMemo(() => {
    const flattenedData = data.pages.flatMap((page: any) => {
      return page.items;
    });
    if (!selection || !fuzzy.match(debouncedSearchTerm, selection.name)) {
      return flattenedData;
    }

    const filteredData = flattenedData.filter((item) => {
      return item.id !== selection.id;
    });

    return [selection, ...filteredData];
  }, [data, selection, debouncedSearchTerm]);

  const { ref: loadMoreRef, inView: loadMoreInView } = useInView();
  React.useEffect(() => {
    if (loadMoreInView && hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, fetchNextPage, loadMoreInView]);

  return (
    <React.Fragment>
      <ComboboxInput
        loading={isDeferred}
        placeholder={`Search ${label}...`}
        onValueChange={(value) => {
          setSearch(value);
        }}
      />
      {filteredAndSortedData.length > 0 ? (
        <ComboboxList>
          {filteredAndSortedData.map((item) => {
            const isSelected = selection?.id === item.id;

            return (
              <ComboboxItem
                key={item.id}
                value={item.id.toString()}
                data-checked={isSelected}
                onSelect={() => {
                  onItemSelect(isSelected ? null : item);
                }}
              >
                {item.ui ?? item.name}
                <ComboboxCheck checked={isSelected} />
              </ComboboxItem>
            );
          })}
          {hasNextPage && (
            <ListLoadMore loading={isFetchingNextPage} ref={loadMoreRef} />
          )}
        </ComboboxList>
      ) : isDeferred ? (
        <div className="py-6 text-center text-sm text-ds-text-primary">
          Searching...
        </div>
      ) : isError ? (
        <div className="py-6 text-center text-sm text-ds-text-primary">
          Error fetching results.
        </div>
      ) : (
        <div className="py-6 text-center text-sm text-ds-text-primary">
          No results
        </div>
      )}
    </React.Fragment>
  );
}
