import * as React from 'react';

/** Source: https://usehooks-ts.com/react-hook/use-debounce */
export function useDebounce<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = React.useState<T>(value);

  React.useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}

/** Source: https://usehooks-ts.com/react-hook/use-debounce */
export function useDebounceWithCallback<T>(
  value: T,
  delay?: number,
  cb?: (arg: T) => void
): T {
  const [debouncedValue, setDebouncedValue] = React.useState<T>(value);
  const callbackRef = React.useRef(cb);
  const previousValueRef = React.useRef<T>(value);

  // Update the ref to the current callback on each render,
  // without triggering the effect.
  // see https://overreacted.io/making-setinterval-declarative-with-react-hooks/
  React.useEffect(() => {
    callbackRef.current = cb;
  }, [cb]);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (previousValueRef.current !== value) {
        setDebouncedValue(value);
        // Use the current callback from the ref.
        callbackRef.current?.(value);
      }

      // Update the previous value after the check.
      previousValueRef.current = value;
    }, delay || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}
