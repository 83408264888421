import * as CommandPrimitive from 'cmdk';
import { badgeIconVariants, badgeVariants } from 'components/ds/Badge';
import { Icon, IconUse } from 'components/ds/icons/Icon';
import { PopoverContent, PopoverTrigger } from 'components/ds/Popover';
import { IconLoader } from 'components/icons/IconLoader/IconLoader';
import * as React from 'react';
import { twMerge } from 'tailwind-merge';
import { VariantProps } from 'tailwind-variants';

export const ComboboxTrigger = React.forwardRef<
  React.ElementRef<typeof PopoverTrigger>,
  React.ComponentPropsWithoutRef<typeof PopoverTrigger>
>(({ className, children, ...rest }, ref) => {
  return (
    <>
      <PopoverTrigger
        role="combobox"
        ref={ref}
        className={twMerge(
          'group flex h-10 w-full items-center justify-between whitespace-nowrap rounded-md border border-ds-stroke-tertiary bg-ds-field-1 px-3 py-1 text-left text-sm text-ds-text-primary focus:border-ds-neutral-400 focus:bg-ds-bg-foundation focus:text-ds-text-primary focus:outline-none focus:ring-2 focus:ring-ds-stroke-secondary disabled:cursor-not-allowed disabled:opacity-50 aria-[invalid=true]:border-ds-state-error aria-[placeholder]:text-ds-text-secondary aria-[invalid=true]:ring-2 aria-[invalid=true]:ring-ds-red-light disabled:aria-[readonly=true]:border-ds-stroke-tertiary disabled:aria-[readonly=true]:bg-ds-bg-foundation disabled:aria-[readonly=true]:text-ds-text-tertiary disabled:aria-[readonly=true]:opacity-100',
          className
        )}
        {...rest}
      >
        <span className="line-clamp-1 inline-block min-h-0 min-w-0 flex-1 justify-start truncate">
          {children}
        </span>
        <Icon
          aria-hidden
          className="h-4 w-4 shrink-0 group-disabled:group-aria-[readonly=true]:text-ds-icon-tertiary"
        >
          <IconUse id="expand-up-down-line" />
        </Icon>
      </PopoverTrigger>
    </>
  );
});
ComboboxTrigger.displayName = 'ComboboxTrigger';

export function ComboboxContent({
  children,
  className,
  shouldFilter = true,
}: {
  shouldFilter?: boolean;
  className?: string;
  children?: React.ReactNode;
}) {
  return (
    <PopoverContent
      align="start"
      side="bottom"
      sideOffset={8}
      className={twMerge(
        'w-[var(--radix-popover-trigger-width)] overflow-hidden shadow-sm',
        className
      )}
    >
      <CommandPrimitive.CommandRoot shouldFilter={shouldFilter}>
        {children}
      </CommandPrimitive.CommandRoot>
    </PopoverContent>
  );
}

type ComboboxInputProps = React.ComponentPropsWithoutRef<
  typeof CommandPrimitive.CommandInput
> & {
  loading?: boolean;
};
export const ComboboxInput = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.CommandInput>,
  ComboboxInputProps
>(({ loading, className, ...rest }, ref) => {
  return (
    <div className="flex flex-row-reverse items-center border-b border-ds-stroke-tertiary">
      <CommandPrimitive.CommandInput
        className={twMerge(
          'peer flex h-10 w-full border-none bg-transparent px-3 py-1 font-primary text-sm text-ds-text-primary outline-none transition-colors placeholder:text-ds-text-placeholder focus:outline-none disabled:cursor-not-allowed',
          className
        )}
        ref={ref}
        {...rest}
      />
      <span
        className="-mr-3 flex w-auto shrink-0 items-center px-3 text-ds-icon-secondary"
        aria-hidden
      >
        {loading ? (
          <IconLoader animate className="h-4 w-4" />
        ) : (
          <Icon className="h-4 w-4">
            <IconUse id="search-line" />
          </Icon>
        )}
      </span>
    </div>
  );
});
ComboboxInput.displayName = 'ComboboxInput';

export const ComboboxList = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.CommandList>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.CommandList>
>(({ className, children, ...rest }, ref) => {
  return (
    <CommandPrimitive.CommandList ref={ref} {...rest}>
      <CommandPrimitive.CommandGroup
        className={twMerge('max-h-[300px] overflow-y-auto p-1', className)}
      >
        {children}
      </CommandPrimitive.CommandGroup>
    </CommandPrimitive.CommandList>
  );
});
ComboboxList.displayName = 'ComboboxList';

interface ComboboxItemProps
  extends React.ComponentPropsWithoutRef<typeof CommandPrimitive.CommandItem> {
  'data-checked'?: boolean;
}
export const ComboboxItem = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.CommandItem>,
  ComboboxItemProps
>(({ className, children, ...rest }, ref) => {
  return (
    <CommandPrimitive.CommandItem
      className={twMerge(
        'relative flex w-full cursor-default select-none items-center justify-between gap-1 overflow-clip rounded p-2 text-sm text-ds-text-secondary outline-none aria-selected:bg-ds-bg-weaker aria-selected:text-ds-text-primary data-[disabled]:pointer-events-none data-[checked=true]:text-ds-text-primary data-[disabled]:opacity-50',
        className
      )}
      ref={ref}
      {...rest}
    >
      {children}
    </CommandPrimitive.CommandItem>
  );
});
ComboboxItem.displayName = 'ComboboxItem';

interface ComboboxCheckProps {
  checked?: boolean;
}
export function ComboboxCheck({ checked }: ComboboxCheckProps) {
  return (
    <Icon
      className={twMerge('h-4 w-4', checked ? 'opacity-100' : 'opacity-0')}
      aria-hidden
    >
      <IconUse id="checkbox-check" />
    </Icon>
  );
}

export const ComboboxEmpty = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.CommandEmpty>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.CommandEmpty>
>(({ className, ...rest }, ref) => (
  <CommandPrimitive.CommandEmpty
    ref={ref}
    className={twMerge(
      'py-6 text-center text-sm text-ds-text-primary',
      className
    )}
    {...rest}
  />
));
ComboboxEmpty.displayName = 'ComboboxEmpty';

export const ComboboxSelectionGroup = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<'div'>
>(({ className, ...rest }, ref) => {
  return (
    <div
      ref={ref}
      className={twMerge('flex flex-wrap gap-2', className)}
      {...rest}
    />
  );
});
ComboboxSelectionGroup.displayName = 'ComboboxSelectionGroup';

export const ComboboxSelectionBadge = React.forwardRef<
  HTMLButtonElement,
  React.ComponentPropsWithoutRef<'button'> &
    Pick<VariantProps<typeof badgeVariants>, 'colorScheme' | 'shape'>
>(
  (
    { className, children, colorScheme = 'neutral', shape = 'pill', ...rest },
    ref
  ) => {
    return (
      <button
        ref={ref}
        className={badgeVariants({
          interactive: true,
          colorScheme,
          shape,
          className,
        })}
        {...rest}
      >
        {children}
        <Icon className={badgeIconVariants()}>
          <IconUse id="close-line" />
        </Icon>
      </button>
    );
  }
);
ComboboxSelectionBadge.displayName = 'ComboboxSelectionBadge';
