import styled from 'styled-components';
import { bp } from 'styles/helpers';
import screen from 'superior-mq';

const ButtonGroup = styled.div`
  display: grid;
  grid-column-gap: 12px;
  grid-auto-flow: column;
  justify-content: start;

  ${(props) =>
    props.span &&
    `
    grid-column: span ${props.span};
  `}

  ${screen.below(
    bp.mobileSm,
    `
    grid-column-gap: 8px;
  `
  )}
`;

export default ButtonGroup;
