import { rem } from 'polished';
import styled from 'styled-components';
import screen from 'superior-mq';
import { bp, hover } from '../styles/helpers';
import InlineLink from './InlineLink';
import UnstyledButton from './UnstyledButton';

interface ButtonPropsBase {
  $theme?:
    | 'secondary'
    | 'error'
    | 'secondary-error'
    | 'orange'
    | 'thumbs-up'
    | 'thumbs-down';
  $small?: boolean;
  $subtheme?: 'onboarding';
  fluid?: boolean;
}

const StyledButton = styled(InlineLink)<ButtonPropsBase>`
  display: inline-block;
  padding: 0 30px;
  height: 48px;
  color: var(--btn-color, var(--white));
  background-color: var(--btn-bg, var(--primary-green));
  font-size: 1rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 46px;
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;
  border-radius: 6px;
  border: 1px solid var(--btn-border, transparent);
  text-align: center;
  transition: background-color var(--default-duration);

  &[disabled] {
    opacity: 0.8;
    cursor: not-allowed;
  }

  ${screen.below(
    bp.mobileSm,
    `
    font-size: ${rem(14)};
  `
  )}

  ${(props) =>
    props.$small &&
    `
    height: 36px;
    line-height: 34px;
    padding: 0 16px;
    font-size: ${rem(14)};

    ${screen.below(
      bp.mobileSm,
      `
      font-size: ${rem(13)};
    `
    )}
  `}

  ${(props) =>
    props.$theme === 'secondary' &&
    `
    --btn-color: var(--primary-green);
    --btn-color-hover: var(--dark-green);
    --btn-bg: transparent;
    --btn-bg-hover: var(--light-green);
    --btn-border: currentColor;
  `}

  ${(props) =>
    props.$theme === 'error' &&
    `
    --btn-bg: var(--error-red);
    --btn-bg-hover: var(--error-red-dark);
  `}

  ${(props) =>
    props.$theme === 'secondary-error' &&
    `
    --btn-color: var(--error-red);
    --btn-color-hover: var(--error-red-dark);
    --btn-bg: transparent;
    --btn-bg-hover: var(--error-red-light);
    --btn-border: currentColor;
  `}

  ${(props) =>
    props.$theme === 'orange' &&
    `
    --btn-color: var(--orange);
    --btn-color-hover: var(--dark-orange);
    --btn-bg: transparent;
    --btn-bg-hover: transparent;
    --btn-border: var(--orange);
    --btn-border-hover: var(--dark-orange);
  `}

  ${(props) =>
    props.$subtheme === 'onboarding' &&
    `
    &[disabled] {
      background-color: var(--border-gray);
      cursor: default;
    }
  `}

  ${(props) =>
    props.$theme === 'thumbs-up' &&
    `
    width: 100%;
    --btn-color: var(--border-gray);
    --btn-color-hover: var(--brand-teal);
    --btn-bg: transparent;
    --btn-bg-hover: transparent;
    --btn-border: currentColor;
  `}

  ${(props) =>
    props.$theme === 'thumbs-down' &&
    `
    width: 100%;
    --btn-color: var(--border-gray);
    --btn-color-hover: var(--error-red-dark);
    --btn-bg: transparent;
    --btn-bg-hover: transparent;
    --btn-border: currentColor;
  `}

  &:not([disabled]) {
    ${hover(`
      background-color: var(--btn-bg-hover, var(--dark-green));
      border: 1px solid var(--btn-border-hover, currentColor);
      color: var(--btn-color-hover, var(--white));
    `)}
  }

  ${(props) =>
    props.fluid &&
    `
    width: 100%;
  `}
`;

interface ButtonLinkProps
  extends Omit<React.ComponentPropsWithoutRef<'a'>, 'href'> {
  href: string;
  children: React.ReactNode;
}
interface ButtonButtonProps
  extends Omit<React.ComponentPropsWithoutRef<'button'>, 'onClick'> {
  onClick?: (e: any) => void;
  children: React.ReactNode;
  as?: React.ElementType;
}
type ButtonProps = (ButtonLinkProps | ButtonButtonProps) & ButtonPropsBase;
const Button = (props: ButtonProps) => {
  const { children, ...rest } = props;
  const buttonProps =
    'href' in props && !('onClick' in props)
      ? ({ href: props.href, ...rest } as ButtonLinkProps)
      : ({ as: UnstyledButton, ...rest } as ButtonButtonProps);

  return <StyledButton {...buttonProps}>{children}</StyledButton>;
};

export default Button;
