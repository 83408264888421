import { prefetchJobDetail, useSuspenseJobDetail } from 'api/jobs';
import Button from 'components/Button';
import { ButtonLink } from 'components/ds/Button';
import {
  TabNavigation,
  TabNavigationItem,
} from 'components/layout-v2/TabNavigation';
import { useModal } from 'components/Modal';
import CreateTaskModal from 'components/notifications/CreateTaskModal';
import ResourceInfoBlock from 'components/UI/participant/ResourceInfoBlock';
import { GAME_PLAN_SEARCH_PARAM } from 'modules/team/participant/components/ParticipantSearch/constants/participant-search.constants';
import { PARTICIPANT_PATHS } from 'modules/team/participant/constants/participant.constants';
import { Helmet } from 'react-helmet';
import { Outlet, useParams } from 'react-router-dom';

export async function jobLoader({ params }, queryClient) {
  try {
    return await prefetchJobDetail(params?.jobId, queryClient);
  } catch (_error) {
    return null;
  }
}

export function Job() {
  const { jobId } = useParams();
  const { modalOpen, modalData, toggleModal } = useModal();
  const { data: job } = useSuspenseJobDetail(jobId);

  return (
    <div className="content-container">
      <Helmet title={job.title} />

      <div className="flex flex-wrap justify-between gap-3 pb-12">
        <ResourceInfoBlock
          job={job}
          partner={job.partner}
          primaryTitleSize="large"
          primaryURL={job.url}
          showHighPriority
        />

        <div className="stack-x-4">
          <ButtonLink
            to={{
              pathname: PARTICIPANT_PATHS.root,
              search: `${GAME_PLAN_SEARCH_PARAM}=${jobId}`,
            }}
            className="relative overflow-visible"
          >
            Game Plan{' '}
            <span
              aria-hidden
              className="absolute right-0 top-0 inline-block -translate-y-1/2 translate-x-2 rounded bg-ds-secondary-lighter px-1 py-0.5 text-center text-xs text-ds-secondary-darker shadow-sm"
            >
              Beta
            </span>
          </ButtonLink>
          <Button
            $small
            onClick={() => {
              toggleModal('createTask', {
                jobId: job.id,
                partnerId: job.partner?.id,
              });
            }}
          >
            + Task
          </Button>
        </div>
      </div>

      <TabNavigation>
        <TabNavigationItem to={`/jobs/${jobId}`} end>
          Game Plan
        </TabNavigationItem>
        <TabNavigationItem to={`/jobs/${jobId}/interviews`} end>
          Interviews
        </TabNavigationItem>
        <TabNavigationItem to={`/jobs/${jobId}/edit`} end>
          Role Info
        </TabNavigationItem>
        <TabNavigationItem to={`/jobs/${jobId}/insight`} end>
          Insight
        </TabNavigationItem>
        <TabNavigationItem to={`/jobs/${jobId}/edit/statuses`} end>
          Status
        </TabNavigationItem>
      </TabNavigation>

      <div className="pt-8">
        <Outlet />
      </div>

      <CreateTaskModal
        modalOpen={'createTask' === modalOpen}
        modalData={modalData}
        onRequestClose={toggleModal}
      />
    </div>
  );
}
