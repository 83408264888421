import { getImpersonation } from 'api/user/user.helpers';
import { FeatureAnnouncements } from 'components/feature-announcements/FeatureAnnouncements';
import { CommandMenuProvider } from 'components/layout-v2/CommandMenu';
import {
  SidebarNavProvider,
  useSidebarNavState,
} from 'components/layout-v2/context/SidebarNavContext';
import {
  MobileNavMenuPortalContainer,
  MobileNavMenuPortalProvider,
} from 'components/layout-v2/MobileNav';
import { NavMenu } from 'components/layout-v2/NavigationMenu';
import { PageActionsPortalProvider } from 'components/layout-v2/PageLayout';
import { SidebarNav } from 'components/layout-v2/SidebarNav';
import {
  SiteHeader,
  SiteHeaderPortalProvider,
} from 'components/layout-v2/SiteHeader';
import ImpersonationBar from 'components/layout/ImpersonationBar';
import useMediaQuery from 'hooks/useMediaQuery';
import { useReactQuerySubscription } from 'hooks/useReactQuerySubscription';
import { useElementStore } from 'stores/element-store';

interface PrivateAppLayoutProps {
  children: React.ReactNode;
}
export function PrivateAppLayout({ children }: PrivateAppLayoutProps) {
  useReactQuerySubscription();

  return (
    <CommandMenuProvider>
      <MobileNavMenuPortalProvider>
        <SidebarNavProvider>
          <SiteHeaderPortalProvider>
            <PageActionsPortalProvider>
              <Layout>{children}</Layout>
            </PageActionsPortalProvider>
          </SiteHeaderPortalProvider>
        </SidebarNavProvider>
      </MobileNavMenuPortalProvider>
    </CommandMenuProvider>
  );
}

interface LayoutProps {
  children: React.ReactNode;
}
function Layout({ children }: LayoutProps) {
  const isTablet = useMediaQuery('(min-width: 768px)');
  const { isOpen } = useSidebarNavState() ?? {};
  const impersonation = getImpersonation();
  const setElement = useElementStore((state) => state.setElement);

  return (
    <div
      className="relative h-full min-h-screen bg-ds-bg-foundation"
      style={
        {
          '--siteheader-position-y': impersonation ? '40px' : '0px',
          '--siteheader-height': isTablet ? '71px' : '58px',
          /**
           * Sidebar width is always `0` on mobile. Otherwise respect the
           * `isOpen` state value.
           */
          '--sidebar-width': isTablet ? (isOpen ? '268px' : '81px') : '0px',
        } as React.CSSProperties
      }
    >
      <aside className="pointer-events-auto inset-0 z-30 hidden w-[var(--sidebar-width)] md:fixed md:flex">
        <SidebarNav>
          <NavMenu isOpen={isOpen} />
        </SidebarNav>
      </aside>
      <ImpersonationBar impersonation={impersonation} />
      <div className="sticky left-0 top-[var(--siteheader-position-y)] z-20 h-[var(--siteheader-height)] w-[calc(100%_-_var(--sidebar-width))] translate-x-[var(--sidebar-width)]">
        <SiteHeader />
      </div>

      <div className="pointer-events-none fixed left-0 top-[var(--siteheader-height)] z-20 h-full min-h-[calc(100dvh_-_var(--siteheader-height))] w-full md:hidden">
        {/** Mobile Nav Menu will be injected into the DOM here */}
        <MobileNavMenuPortalContainer />
      </div>
      <main className="relative z-10 w-full pl-[var(--sidebar-width)] text-ds-text-primary">
        {children}
      </main>
      <div
        className="pointer-events-none fixed inset-0 top-[var(--siteheader-height)] z-10 w-[calc(100%_-_var(--sidebar-width))] translate-x-[var(--sidebar-width)]"
        ref={(node) => {
          setElement({ name: 'layoutSheetRef', element: node });
        }}
      />
      <FeatureAnnouncements />
    </div>
  );
}
