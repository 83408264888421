import AspectRatio from 'components/AspectRatio';
import Modal from 'components/Modal';
import VideoEmbed from 'components/VideoEmbed';
import { size } from 'polished';
import styled from 'styled-components';
import { absoCenter } from 'styles/helpers';
import UnstyledButton from './UnstyledButton';

const CloseButton = styled(UnstyledButton)`
  ${size('45px')}
  position: fixed;
  top: 20px;
  right: 20px;
  border-radius: 50%;
  color: var(--primary-green);
  background: var(--white);

  svg {
    ${absoCenter()}
    ${size('20px')}
  }
`;

const VideoModal = ({ modalOpen, modalData, onRequestClose }) => {
  return (
    <Modal isOpen={!!modalOpen} onRequestClose={onRequestClose} large>
      <AspectRatio>
        <VideoEmbed
          url={typeof modalData === 'string' ? modalData : undefined}
        />
      </AspectRatio>
      <CloseButton onClick={onRequestClose}>
        <svg>
          <use xlinkHref="#x-icon" />
        </svg>
      </CloseButton>
    </Modal>
  );
};

export default VideoModal;
