import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { JOBS_KEYS, JOBS_QUERY_OPTIONS } from 'api/jobs';
import { PARTICIPANT_QUERIES } from 'api/participants';
import { PARTNER_QUERY_OPTIONS } from 'api/partners';
import { AsyncComboboxField } from 'components/ComboboxField/AsyncComboboxField';
import { Button } from 'components/ds/Button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'components/ds/Form';
import { toast } from 'components/ds/Toast/Toast';
import api from 'dataService/api';
import * as React from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { z } from 'zod';

export function AddToBrainstormForm({
  participant,
  onSuccessfulSubmit,
  onCancel,
}: {
  participant: {
    id: number;
    name: string;
  };
  onSuccessfulSubmit?: () => void;
  onCancel?: () => void;
}) {
  const queryClient = useQueryClient();
  const form = useForm<AddToBrainstormFormInput>({
    mode: 'onSubmit',
    resolver: zodResolver(AddToBrainstormFormSchema),
    shouldUnregister: true,
    defaultValues: {
      participant,
    },
  });
  const { handleSubmit, control, resetField } = form;
  const partner = useWatch({
    control,
    name: 'partner',
  });
  const jobsQueryOptions = React.useMemo(
    () => getJobsListQueryOptions(partner?.id),
    [partner?.id]
  );
  const addToBrainstormMutation = useMutation({
    mutationFn: async ({
      jobId,
      participantId,
    }: {
      jobId: number;
      participantId: number;
    }) => {
      await api.post(`/jobs/${jobId}/brainstorm`, {
        participants: [participantId],
      });
      return {
        jobId,
        participantId,
      };
    },
    onSuccess: ({ jobId, participantId }) => {
      queryClient.invalidateQueries({
        queryKey: [JOBS_KEYS.detail(jobId)],
      });
      queryClient.invalidateQueries({
        queryKey: PARTICIPANT_QUERIES.participant(participantId.toString())
          .queryKey,
      });
      onSuccessfulSubmit?.();
    },
    onError: () => {
      toast.error('Failed to add to brainstorm', {
        description: 'Please try again.',
      });
    },
  });

  return (
    <Form {...form}>
      <form
        onSubmit={handleSubmit((data) => {
          addToBrainstormMutation.mutate({
            jobId: data.job.id,
            participantId: data.participant.id,
          });
        })}
        className="stack-y-6"
      >
        <div className="grid grid-cols-[repeat(auto-fill,minmax(280px,1fr))] gap-6">
          <FormField
            control={control}
            name="partner"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>Partner</FormLabel>
                  <FormControl>
                    <AsyncComboboxField
                      {...field}
                      displayName="Partner"
                      infiniteQueryOptions={
                        PARTNER_QUERY_OPTIONS.infinitePartnersList
                      }
                      onComboSelect={() => {
                        resetField('job');
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
          <FormField
            control={control}
            name="job"
            disabled={!partner?.id}
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>Job</FormLabel>
                  <FormControl>
                    <AsyncComboboxField
                      {...field}
                      displayName="Job"
                      infiniteQueryOptions={jobsQueryOptions}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
          <FormField
            control={control}
            name="participant"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>Participant</FormLabel>
                  <FormControl>
                    <AsyncComboboxField
                      {...field}
                      displayName="Participant"
                      infiniteQueryOptions={
                        PARTICIPANT_QUERIES.infiniteParticipantsList
                      }
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
        </div>
        <div>
          <div className="justify-end stack-x-4">
            <Button
              variant="secondary"
              onClick={(e) => {
                e.preventDefault();
                onCancel?.();
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={addToBrainstormMutation.isPending}
              isLoading={addToBrainstormMutation.isPending}
              type="submit"
            >
              Add to Game Plan
            </Button>
          </div>
        </div>
      </form>
    </Form>
  );
}

const AddToBrainstormFormSchema = z.object({
  partner: z.object(
    {
      id: z.coerce.number(),
      name: z.string(),
    },
    {
      errorMap: () => ({ message: 'Please select a partner.' }),
    }
  ),
  job: z.object(
    {
      id: z.coerce.number(),
      name: z.string(),
    },
    {
      errorMap: () => ({ message: 'Please select a job.' }),
    }
  ),
  participant: z.object(
    {
      id: z.coerce.number(),
      name: z.string(),
    },
    {
      errorMap: () => ({ message: 'Please select a participant.' }),
    }
  ),
});
type AddToBrainstormFormInput = z.input<typeof AddToBrainstormFormSchema>;

function getJobsListQueryOptions(partnerId?: number) {
  return (params: { [key: string]: unknown }) => {
    return JOBS_QUERY_OPTIONS.infiniteJobsList({
      ...params,
      ...(partnerId ? { partner: partnerId } : {}),
    });
  };
}
