import { Button } from 'components/ds/Button';
import { Icon, IconUse } from 'components/ds/icons/Icon';
import { Skeleton } from 'components/ds/Skeleton';
import { useFilterGroup } from 'components/filters-new/context/FilterGroupContext';
import { twMerge } from 'tailwind-merge';

interface FilterGroupProps {
  className?: string;
  children: React.ReactNode;
}
export function FilterGroup({ children, className }: FilterGroupProps) {
  const { actions } = useFilterGroup();
  return (
    <FilterGroupContainer className={className}>
      <FilterContainer>{children}</FilterContainer>
      <FilterGroupClearButton
        onClear={() => {
          actions.clearFilters();
        }}
      />
    </FilterGroupContainer>
  );
}

export function FilterGroupSkeleton({ count = 7 }: { count?: number }) {
  return (
    <FilterGroupContainer>
      <FilterContainer>
        {Array.from({ length: count }).map((_, i) => (
          <Skeleton key={i} className="h-[32px] w-20" />
        ))}
      </FilterContainer>
      <FilterGroupClearButton />
    </FilterGroupContainer>
  );
}

export function FilterGroupContainer({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <div
      className={twMerge(
        'w-full flex-wrap items-center overflow-x-auto bg-transparent p-1 stack-x-2 md:flex-nowrap',
        className
      )}
    >
      {children}
    </div>
  );
}

export function FilterContainer({ children }: { children: React.ReactNode }) {
  return <div className="flex-wrap stack-x-2">{children}</div>;
}

export function FilterGroupClearButton({ onClear }: { onClear?: () => void }) {
  return (
    <Button
      variant="ghost"
      onClick={() => {
        onClear?.();
      }}
      suffix={
        <Icon>
          <IconUse id="close-line" />
        </Icon>
      }
    >
      Clear
    </Button>
  );
}
