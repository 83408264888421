import { InfiniteData } from '@tanstack/react-query';
import { createInfiniteDataTransform } from 'util/createInfiniteDataTransform';

export function addFullNameToDataItems(
  data: InfiniteData<
    { items: { id: number; firstName: string; lastName: string }[] },
    number
  >
) {
  return createInfiniteDataTransform(data, (item) => {
    return {
      ...item,
      name: `${item.firstName} ${item.lastName}`,
    };
  });
}
