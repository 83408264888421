import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { Button, ButtonIcon } from 'components/ds/Button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'components/ds/Dialog';
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from 'components/ds/Drawer';
import { DropdownMenuItem } from 'components/ds/DropdownMenu';
import { Form, FormField, FormItem, FormMessage } from 'components/ds/Form';
import { Icon, IconUse } from 'components/ds/icons/Icon';
import api from 'dataService/api';
import useMediaQuery from 'hooks/useMediaQuery';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';
import { text } from 'styles/text';
import { z } from 'zod';

export function ResumeUploadAction({
  children,
}: {
  children: React.ReactNode;
}) {
  const isAboveTablet = useMediaQuery('(min-width: 768px)');
  const { open, setOpen } = useResumeUploadDialog();

  return isAboveTablet ? (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <DropdownMenuItem
          className="items-center stack-x-1"
          onSelect={(event) => {
            // Prevent the dropdown from closing when the delete button is clicked.
            event.preventDefault();
          }}
        >
          <Icon className="h-4 w-4">
            <IconUse id="file-upload-line" />
          </Icon>
          Upload Resume
        </DropdownMenuItem>
      </DialogTrigger>

      <DialogContent>
        <DialogHeader>
          <DialogTitle>Upload File</DialogTitle>
          <DialogDescription>
            Upload a resume for this participant.
          </DialogDescription>
        </DialogHeader>
        {children}
      </DialogContent>
    </Dialog>
  ) : (
    <Drawer open={open} onOpenChange={setOpen}>
      <DrawerTrigger asChild>
        <DropdownMenuItem
          className="items-center stack-x-1"
          onSelect={(event) => {
            // Prevent the dropdown from closing when the delete button is clicked.
            event.preventDefault();
          }}
        >
          <Icon className="h-4 w-4">
            <IconUse id="file-upload-line" />
          </Icon>
          Upload Resume
        </DropdownMenuItem>
      </DrawerTrigger>

      <DrawerContent className="gap-4 p-6">
        <DrawerHeader>
          <DrawerTitle>Upload File</DrawerTitle>
          <DrawerDescription>
            Upload a resume for this participant.
          </DrawerDescription>
        </DrawerHeader>
        {children}
      </DrawerContent>
    </Drawer>
  );
}

const ResumeUploadDialogContext = React.createContext<{
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
} | null>(null);

export function ResumeUploadDialogProvider({
  children,
}: {
  children?: React.ReactNode;
}) {
  const [open, setOpen] = React.useState(false);

  const value = React.useMemo(() => ({ open, setOpen }), [open]);

  return (
    <ResumeUploadDialogContext.Provider value={value}>
      {children}
    </ResumeUploadDialogContext.Provider>
  );
}

function useResumeUploadDialog() {
  const value = React.useContext(ResumeUploadDialogContext);

  if (!value) {
    throw new Error(
      'ResumeUploadDialogContext must be used within a ResumeUploadDialogProvider'
    );
  }

  return value;
}

const FORM_ID = 'participant-resume-upload-form' as const;
interface ParticipantResumeUploadFormProps {
  onSuccessfulUpload?: () => void;
}
export function ParticipantResumeUploadForm({
  onSuccessfulUpload,
}: ParticipantResumeUploadFormProps) {
  const { setOpen } = useResumeUploadDialog();
  const { participantId } = useParams();
  const formRef = React.useRef<HTMLFormElement>(null);
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const [isDropping, setIsDropping] = React.useState(false);
  const form = useForm<ParticipantResumeUploadFormInput>({
    mode: 'onChange',
    resolver: zodResolver(ParticipantResumeUploadFormSchema),
  });
  const { control, setValue, watch, handleSubmit, resetField } = form;
  const files = watch('resume');
  const fileUploadMutation = useMutation({
    mutationFn: async (data: ParticipantResumeUploadFormInput) => {
      const { data: signedUrlData } = await api.post<{
        url: string;
        key: string;
      }>('/files/presigned-url', {
        userId: participantId,
        type: 'resume',
        fileType: 'application/pdf',
      });
      const { url: signedUrl, key } = signedUrlData;

      const s3UploadResponse = await axios.put(
        signedUrl,
        data.resume?.item(0),
        { headers: { 'Content-Type': 'application/pdf' } }
      );

      if (s3UploadResponse.status !== 200) {
        throw new Error('Upload to was unsuccessful');
      }

      await api.post('/files/resume-uploaded', {
        userId: participantId,
        key,
      });
    },
    onSuccess: () => {
      setOpen(false);
      toast.success('Resume was successfully uploaded');
      onSuccessfulUpload?.();
    },
    onError: () => {
      toast.error('Failed to upload resume');
    },
  });

  function handleDrop(evt: React.DragEvent) {
    const files = evt.dataTransfer.files;

    setValue('resume', files, {
      shouldValidate: true,
    });
  }

  return (
    <>
      <Form {...form}>
        <form
          ref={formRef}
          id={FORM_ID}
          onSubmit={handleSubmit((data) => {
            fileUploadMutation.mutate(data);
          })}
        >
          <FormField
            name="resume"
            control={control}
            render={({ field }) => {
              const { name, onChange } = field;
              return (
                <FormItem>
                  <div
                    onDragOver={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setIsDropping(true);

                      e.dataTransfer.dropEffect = 'copy';
                    }}
                    onDragLeave={(evt) => {
                      evt.stopPropagation();
                      evt.preventDefault();
                      setIsDropping(false);
                      evt.dataTransfer.dropEffect = 'move';
                    }}
                    onDrop={(evt) => {
                      evt.stopPropagation();
                      evt.preventDefault();
                      setIsDropping(false);
                      handleDrop(evt);
                    }}
                    data-drop-target={isDropping}
                    className="items-center justify-center rounded border border-dashed border-ds-stroke-tertiary bg-ds-bg-weaker p-8 text-ds-text-tertiary transition-colors stack-y-3 data-[drop-target=true]:border-solid data-[drop-target=true]:border-ds-primary-base data-[drop-target=true]:bg-ds-primary-lightest data-[drop-target=true]:text-ds-primary-dark"
                  >
                    <span
                      className={text({
                        variant: '14',
                        className: 'text-inherit',
                      })}
                    >
                      Drop files to upload or
                    </span>
                    <div>
                      <Button
                        variant="outline"
                        size="sm"
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === 'Space') {
                            e.preventDefault();
                            fileInputRef.current?.click();
                          }
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          if (fileInputRef.current?.value) {
                            fileInputRef.current.value = '';
                          }
                          fileInputRef.current?.click();
                        }}
                      >
                        Select File
                      </Button>
                    </div>
                    <input
                      type="file"
                      name={name}
                      onChange={(e) => {
                        onChange(e.target.files);
                      }}
                      ref={fileInputRef}
                      accept=".pdf"
                      capture={false}
                      style={{ display: 'none' }}
                    />
                  </div>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
        </form>
      </Form>
      {files != null && files.length > 0 && (
        <>
          <ul className="m-0 list-none overflow-hidden p-0">
            {Array.from(files).map((file) => {
              return (
                <li
                  key={file.name}
                  className="items-center rounded bg-ds-bg-weaker p-5 stack-x-2"
                >
                  <div className="flex-1 items-center overflow-hidden stack-x-2">
                    <Icon className="flex-shrink-0">
                      <IconUse id="file-pdf-2-line" />
                    </Icon>
                    <span
                      className={text({
                        variant: '14',
                        className: 'min-w-0 flex-1 truncate',
                      })}
                    >
                      {file.name}
                    </span>
                  </div>
                  <Button
                    svgOnly
                    size="sm"
                    variant="ghost-outline"
                    onClick={() => {
                      resetField('resume');
                    }}
                    className="flex-shrink-0"
                  >
                    <ButtonIcon>
                      <IconUse id="close-line" />
                    </ButtonIcon>
                    <span className="sr-only">Remove File</span>
                  </Button>
                </li>
              );
            })}
          </ul>

          <DialogFooter>
            <Button
              onClick={() => {
                setOpen(false);
              }}
              size="sm"
              variant="outline"
            >
              Cancel
            </Button>

            <Button
              form={FORM_ID}
              disabled={fileUploadMutation.isPending}
              isLoading={fileUploadMutation.isPending}
              size="sm"
              variant="secondary"
              type="submit"
            >
              Upload
            </Button>
          </DialogFooter>
        </>
      )}
    </>
  );
}

const MAX_FILE_SIZE = 1024 * 1024 * 2; // 2MB
const ParticipantResumeUploadFormSchema = z.object({
  resume: z
    .custom<FileList | undefined>()
    .refine((files) => files != null, 'File is required')
    .refine((files) => files?.length === 1, 'Only one file is permitted')
    .refine((files) => {
      return Array.from(files ?? []).every((f) => f.type === 'application/pdf');
    }, 'File must be a PDF')
    .refine(
      (files) =>
        Array.from(files ?? []).every((file) => file.size < MAX_FILE_SIZE),
      'File must be under 2MB'
    ),
});
type ParticipantResumeUploadFormInput = z.input<
  typeof ParticipantResumeUploadFormSchema
>;
