import type {
  CognitoRefreshToken,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { queryClient } from 'api/client';
import { USER_OPTIONS } from 'api/user/user';
import { getImpersonation } from 'api/user/user.helpers';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import config from '../config';

export const baseURL = config.api;

const api = axios.create({
  baseURL,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
  },
});

async function authenticationTokenRefresh() {
  try {
    // measure performance
    const session = await Auth.currentSession();

    if (isAccessTokenExpired(session)) {
      const refreshToken = session.getRefreshToken();
      const refreshedSession = await refreshSession(refreshToken);
      const newToken = refreshedSession.getAccessToken().getJwtToken();
      queryClient.invalidateQueries({ queryKey: USER_OPTIONS.user().queryKey });

      // Return the new token
      return newToken;
    }

    // Return the existing token
    return session.getAccessToken().getJwtToken();
  } catch (_err) {
    // If the user is not authenticated, return null
    queryClient.removeQueries({ queryKey: USER_OPTIONS.user().queryKey });
    return null;
  }
}

// This interceptor intercepts API responses and refreshes the user's access token if necessary
api.interceptors.request.use(async (request) => {
  try {
    const token = await authenticationTokenRefresh();
    request.headers.Authorization = `Bearer ${token}`;

    let impersonation;

    if (!impersonation && typeof window !== 'undefined') {
      impersonation = getImpersonation();
    }

    if (impersonation?.username) {
      request.headers['X-BL-Impersonate'] = impersonation.username;
    }

    return request;
  } catch (err) {
    return request;
  }
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.__CANCEL__) {
      return Promise.reject(new Error('Cancelled.'));
    }

    if (!error.response) {
      return Promise.reject(
        new Error('An unknown error has occurred. Please try again.')
      );
    }

    switch (error.response.status) {
      case 400:
        return Promise.reject(new Error('Bad request.'));
      case 404:
        return Promise.reject(new Error('Resource not found.'));
      case 401:
      case 403:
        return Promise.reject(new Error('Unauthorized.'));
      case 409:
        return Promise.reject(
          new Error('There was a conflict with existing data.')
        );
      case 418:
        return Promise.reject(new Error('418'));
      case 429:
        return Promise.reject(
          new Error(
            'Resource requested too many times. Please try again later.'
          )
        );
      case 500: {
        return Promise.reject(
          new Error('An unknown error has occurred. Please try again.')
        );
      }
      default: {
        return Promise.reject(
          new Error('An unknown error has occurred. Please try again.')
        );
      }
    }
  }
);

// Refresh token if expired
// This function checks if the user's ID token has expired
function isAccessTokenExpired(session: CognitoUserSession): boolean {
  const accessTokenExpire = session.getAccessToken().getExpiration();
  const currentTimeSeconds = Math.round(new Date().getTime() / 1000);
  return accessTokenExpire < currentTimeSeconds;
}

// This function refreshes the user's session using their refresh token
async function refreshSession(
  refreshToken: CognitoRefreshToken
): Promise<CognitoUserSession> {
  const currentAuthenticatedUser = await Auth.currentAuthenticatedUser();
  return new Promise((resolve, reject) => {
    currentAuthenticatedUser.refreshSession(
      refreshToken,
      (err: any, data: any) => {
        if (err) {
          Auth.signOut();
          reject(err);
        } else {
          resolve(data);
        }
      }
    );
  });
}

export default api;
