import Button from 'components/Button';
import ButtonGroup from 'components/ButtonGroup';
import { toast } from 'components/ds/Toast/Toast';
import Input from 'components/forms/Input';
import Grid from 'components/Grid';
import { H4 } from 'components/H';
import Modal from 'components/Modal';
import dataService from 'dataService';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { formatFormValues } from 'util/forms';

const defaultValues = {
  context: '',
};

const mapToFormValues = (status) => ({
  context: status?.context || '',
});

const UpdateStatusModal = ({ modalOpen, modalData, onRequestClose }) => {
  const {
    handleSubmit,
    register,
    formState: { isDirty, errors },
    reset,
  } = useForm({
    mode: 'onTouched',
    shouldUnregister: true,
    defaultValues,
  });

  const onSubmit = (values) => {
    const formData = formatFormValues(values);

    dataService
      .patch(
        `${modalData?.endpoint}/${
          modalData?.jobId || modalData?.userId
        }/statuses/${modalData?.status?.id}`,
        formData
      )
      .then(() => {
        onRequestClose(true);
        toast.success('Status updated');
      })
      .catch(() => {
        onRequestClose(false);
        toast.error('Error updating status');
      });
  };

  // Empty the form on modal close
  useEffect(() => {
    if (!modalOpen) {
      reset({ ...defaultValues });
    }

    if (modalData?.status) {
      reset({ ...defaultValues, ...mapToFormValues(modalData.status) });
    }
  }, [modalOpen, modalData, reset]);

  return (
    <Modal
      isOpen={modalOpen === 'updateStatus'}
      onRequestClose={() => {
        onRequestClose(false);
      }}
      large
    >
      <H4>Update {modalData?.status?.type} Status Context</H4>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid formGrid>
          <Grid.Item>
            <Input
              {...register('context', {
                required: 'Please add context to this status',
              })}
              type="text"
              label="Context"
              placeholder="Context"
              errors={errors}
            />
          </Grid.Item>

          <Grid.Item span={2} as={ButtonGroup}>
            <Button type="submit" disabled={!isDirty} $small>
              Update Context
            </Button>

            <Button
              type="button"
              onClick={() => {
                onRequestClose();
              }}
              $theme="secondary"
              $small
            >
              Cancel
            </Button>
          </Grid.Item>
        </Grid>
      </form>
    </Modal>
  );
};

UpdateStatusModal.propTypes = {
  modalOpen: PropTypes.string.isRequired,
  modalData: PropTypes.object,
  onRequestClose: PropTypes.func,
};

export default UpdateStatusModal;
