import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { Auth } from 'aws-amplify';
import { Button } from 'components/ds/Button';
import { toast } from 'components/ds/Toast/Toast';
import Input from 'components/forms/Input';
import Grid from 'components/Grid';
import { headingOne } from 'components/H';
import LoginForm from 'components/LoginForm';
import { useAuth } from 'context/Auth';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import {
  LoaderFunctionArgs,
  Navigate,
  redirect,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import styled from 'styled-components';
import { bp } from 'styles/helpers';
import screen from 'superior-mq';
import { z } from 'zod';

const OrangeDesignElement = styled.img`
  position: absolute;
  left: -175px;
  top: 240px;
  height: 980px;
  z-index: -1;

  ${screen.below(
    bp.laptopSm,
    `
    height: 660px;
    left: -100px;
  `
  )}

  ${screen.below(
    '1023px',
    `
    height: 530px;
    left: -100px;
  `
  )}

  ${screen.below(
    bp.portrait,
    `
    height: 430px;
    top: unset;
    bottom: -300px;
    left: -85px;
  `
  )}
`;

const TealDesignElement = styled.img`
  height: 490px;
  position: absolute;
  right: -332px;
  top: 360px;
  z-index: -1;

  ${screen.below(
    bp.laptopSm,
    `
    height: 382px;
    right: -240px;
  `
  )}

  ${screen.below(
    '1090px',
    `
    height: 300px;
  `
  )}


  ${screen.below(
    bp.portrait,
    `
    rotate: 180deg;
    transform: scaleX(-1);
    top: -156px;
    height: 250px;
    right: -148px;
  `
  )}

  ${screen.below(
    bp.mobileSm,
    `
    right: -200px;
  `
  )}
`;

async function confirmSignIn({
  challengeAnswer,
  user,
}: {
  challengeAnswer: string;
  user: any;
}) {
  // need to save temporary user to a context
  await Auth.confirmSignIn(user, challengeAnswer, 'SOFTWARE_TOKEN_MFA');
}

export async function twoFactorAuthLoader({ request }: LoaderFunctionArgs) {
  try {
    const url = new URL(request.url);
    const redirectTo = url.searchParams.get('redirectTo') || '/';
    const user = await Auth.currentAuthenticatedUser();

    if (user) {
      // Redirect to home if user is already logged in
      return redirect(redirectTo);
    }

    return null;
  } catch (_error) {
    return null;
  }
}

const TwoFactorAuthFormSchema = z.object({
  otp: z
    .string()
    .min(1, 'Please enter your OTP code')
    .min(6, 'Please enter a valid OTP code'),
});

type TwoFactorAuthForm = z.infer<typeof TwoFactorAuthFormSchema>;

export function TwoFactorAuth() {
  const { tempUser } = useAuth();
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get('redirectTo') || '/';
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<TwoFactorAuthForm>({
    mode: 'onBlur',
    resolver: zodResolver(TwoFactorAuthFormSchema),
  });
  const mutation = useMutation({
    mutationFn: confirmSignIn,
    onSuccess: () => {
      navigate(redirectTo, { replace: true });
    },
    onError: (error) => {
      switch (true) {
        case 'code' in error && error.code === 'CodeMismatchException': {
          toast.error('Invalid OTP code. Please try again.');
          break;
        }
        case 'code' in error && error.code === 'NotAuthorizedException': {
          toast.error('Login session has expired, please log in again.');
          return navigate(`/login?redirectTo=${redirectTo}`, { replace: true });
        }
        default: {
          toast.error('Something went wrong, please try again.');
          break;
        }
      }
    },
  });
  function onSubmit(data: TwoFactorAuthForm) {
    const { otp } = data;
    mutation.mutate({
      challengeAnswer: otp,
      user: tempUser,
    });
  }

  if (!tempUser) {
    return <Navigate to={`/login?redirectTo=${redirectTo}`} replace />;
  }

  return (
    <LoginForm.Main>
      <OrangeDesignElement src="/images/breakline-orange-design-element.svg" />
      <TealDesignElement src="/images/breakline-teal-design-element.svg" />
      <Helmet title="Two Factor Authentication (2FA)" />
      <LoginForm.Content>
        <LoginForm.Form onSubmit={handleSubmit(onSubmit)}>
          <h1 className={headingOne({ fixed: true, className: 'text-center' })}>
            Two Factor Authentication
          </h1>
          <Grid formGrid>
            <Input
              {...register('otp')}
              // @ts-expect-error Input component is not typed correctly
              type="text"
              label="One-time Password"
              placeholder="123456"
              errors={errors}
              span={2}
              required
            />

            <div className="col-span-2">
              <Button
                type="submit"
                isLoading={mutation.isPending}
                disabled={mutation.isPending}
                className="w-full"
                size="lg"
              >
                Submit
              </Button>
            </div>
          </Grid>
        </LoginForm.Form>
      </LoginForm.Content>
    </LoginForm.Main>
  );
}
