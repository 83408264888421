import { ErrorMessage } from '@hookform/error-message';
import UnstyledButton from 'components/UnstyledButton';
import { useCombobox } from 'downshift';
import { at } from 'lodash';
import { rem, size } from 'polished';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { bp } from 'styles/helpers';
import screen from 'superior-mq';
import dataService from '../../dataService';
import DownshiftStyles from './DownshiftStyles';
import HelperText from './HelperText';
import Label from './Label';

const InputOuterWrap = styled.div`
  position: relative;

  ${(props) =>
    props.styles &&
    css`
      ${props.styles}
    `}
`;

const InputWrap = styled.div`
  display: flex;
  height: 48px;
  background-color: var(--off-white);
  border: var(--border);
  border-radius: var(--border-radius);
  overflow: hidden;

  &[aria-expanded='true'] {
    background-color: var(--white);
    border-color: var(--primary-green);
    box-shadow: var(--focus-shadow);
  }

  svg {
    ${size(8, 15)};
  }

  ${(props) =>
    props.$small &&
    `
    height: 36px;
    line-height: 34px;
    font-size: ${rem(14)};
    font-weight: 500;
    background-color: var(--white);
    border-color: var(--primary-green);

    svg {
      width: 13px;
      height: 6px;
      vertical-align: super;
    }

    input {
      color: var(--primary-green);
    }
  `}

  ${(props) => props.width && `width: ${props.width}px;`}

  ${(props) =>
    props.disabled &&
    `
    opacity: .4;
  `}
`;

const ComboWrap = styled.div`
  position: relative;

  ${(props) =>
    props.span &&
    `
    grid-column: span ${props.span};
  `}

  ${screen.below(
    bp.tablet,
    `
    grid-column: span 2;
  `
  )}
`;

const ComboInput = styled.input`
  flex: 1 1 auto;
  padding: 12px 12px;
  appearance: none;
  border: 0;
  background-color: inherit;
  outline: none;
  font: inherit;
  line-height: 1;

  &::placeholder {
    opacity: 1;
    color: var(--placeholder-gray);
  }
`;

const ToggleButton = styled(UnstyledButton)`
  padding: 12px;
  vertical-align: center;
`;

const SearchMenu = styled(DownshiftStyles.SearchMenu)`
  ${
    '' /* position: absolute;
  width: 100%;
  max-height: 200px;
  margin: 0; */
  }
  ${'' /* overflow: auto; */}
  ${
    '' /* background: var(--white);
  z-index: 1; */
  }
`;

const PopOver = styled(DownshiftStyles.Popover)``;

const MenuItem = styled(DownshiftStyles.MenuItem)`
  ${(props) =>
    props.highlight &&
    `
    background-color: var(--off-white);
  `}
`;

/**
 * A single select controlled form component built with Downshift. Meant to be
 * used with react-hook-form for validation and error display. If static options
 * are used the component will auto highlight as the user types (like a native
 * select element). If apiPath is used, the options are filtered as the user
 * types instead.
 *
 * @param {string} name -The name of the input (for tracking validation errors)
 * @param {string} label - Text to use as the lable for the form field.
 * @param {Array} [options] -The available options. Format should be [{value:1,
 *   label:'someplace},...]
 * @param {string} [apiPath] -If this prop is set, the above options are
 *   ignored. Instead the given API path is called to retrieve the options. The
 *   user search term is passed as a param (search).
 * @param {string} [apiParams] -param string to add to the API request
 * @param {Function} [optionFormatter] -A function to transform option data to
 *   the correct format, [{value:1, label:'someplace},...]
 * @param {Number} [optionLimit] -Limit the number of options to show at a time.
 * @param {Function} onChange - Function to call when value changes
 *   (react-hook-form handler).
 * @param {Function} onBlur - Function to call when element looses focus
 *   (react-hook-form handler).
 * @param {Object} value - Value if the field field (react-hook-form managed).
 * @param {Object} errors - Errors to display (react-hook-form managed).
 * @param {bool} hideLabel - If true, the lable is output but not displayed.
 * @param {bool} showToggleButton - If true, the menu toggle button will be
 *   displayed
 * @param {bool} small - If true, the input will be made smaller.
 * @param {bool} placeMenuAbove - If true, the menu will be placed above the
 *   input instead of under.
 */
const Combo = ({
  name,
  label,
  required,
  placeholder,
  options,
  apiPath,
  apiParams,
  optionFormatter,
  optionLimit = 20,
  onChange,
  onBlur,
  value,
  errors = {},
  hideLabel,
  showToggleButton = true,
  small,
  width,
  className,
  span,
  inputWrapStyles,
  disabled,
  placeMenuAbove,
  boldLabel = false,
  paragraphText = false,
  noResultsLabel,
  ...props
}) => {
  const [userInput, setUserInput] = useState(value?.label || '');

  const { data, isLoading, isError } = dataService.useGet(() => {
    // API is only called if path was set.
    if (!apiPath || disabled) return false;
    const searchTerm = value.label === userInput ? '' : userInput;
    const path = apiParams ? `${apiPath}?${apiParams}&` : `${apiPath}?`;
    return `${path}limit=${optionLimit}${searchTerm && `&term=${searchTerm}`}`;
  });

  // If apiPath is passed in we should use returned data as the items.
  // Otherwise use the static options
  const items = useMemo(() => {
    const rawItems =
      apiPath && !isError && data ? data.items || [] : options || [];
    return optionFormatter ? rawItems.map(optionFormatter) : rawItems;
  }, [data, isError, options, optionFormatter, apiPath]);

  const handleSelectedItemChange = ({ selectedItem }) => {
    onChange(selectedItem);
  };

  const setHighlight = (term) => {
    let foundIndex = -1;
    if (items && items.length > 0 && term !== '' && !apiPath) {
      foundIndex = items.findIndex((item) =>
        item.label.toLowerCase().startsWith(term.toLowerCase())
      );
    }
    setHighlightedIndex(foundIndex);
  };

  const onInputChange = ({ inputValue }) => {
    setUserInput(inputValue);
    setHighlight(inputValue);
    if (inputValue === '') selectItem({});
  };

  // Clear display if value is set to empty.
  useEffect(() => {
    if (!value?.label) setUserInput('');
  }, [value]);

  const {
    isOpen,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getToggleButtonProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    setHighlightedIndex,
    openMenu,
    selectItem,
  } = useCombobox({
    items,
    itemToString: (item) => item?.label,
    onSelectedItemChange: handleSelectedItemChange,
    onInputValueChange: onInputChange,
    selectedItem: value,
  });

  return (
    <ComboWrap $small={small} className={className} span={span} {...props}>
      {label && (
        <Label
          {...getLabelProps()}
          hideLabel={hideLabel}
          $bold={boldLabel}
          $paragraphText={paragraphText}
        >
          {label}
          {required ? '*' : null}
        </Label>
      )}
      <InputOuterWrap styles={inputWrapStyles}>
        <InputWrap {...getComboboxProps({ $small: small, width, disabled })}>
          <ComboInput
            {...getInputProps({
              placeholder,
              value: userInput,
              onBlur: (evt) => {
                !userInput && selectItem({});
                onBlur && onBlur(evt);
              },
              onClick: openMenu,
              disabled: disabled,
            })}
          />
          {showToggleButton && !disabled && (
            <ToggleButton
              type="button"
              {...getToggleButtonProps()}
              aria-label="toggle menu"
            >
              <svg>
                <use xlinkHref="#chevron" />
              </svg>
            </ToggleButton>
          )}
        </InputWrap>

        <PopOver
          {...getMenuProps()}
          isOpen={isOpen}
          style={{
            ...(placeMenuAbove ? { bottom: small ? '36px' : '48px' } : {}),
          }}
        >
          <SearchMenu>
            {isOpen && isLoading && userInput && apiPath && (
              <div>Loading...</div>
            )}

            {noResultsLabel && isOpen && !isLoading && !items?.length ? (
              <div style={{ padding: 10 }}>{noResultsLabel}</div>
            ) : null}
            {isOpen &&
              (!isLoading || !apiPath) &&
              items.map((item, index) => (
                <MenuItem
                  highlight={highlightedIndex === index}
                  key={`${item.label}${index}`}
                  {...getItemProps({ item, index })}
                >
                  {item.prettyLabel || item.label}
                </MenuItem>
              ))}
          </SearchMenu>
        </PopOver>
      </InputOuterWrap>
      {at(errors, [name])[0] && (
        <HelperText>
          <HelperText.Error>
            <ErrorMessage errors={errors} name={name} />
          </HelperText.Error>
        </HelperText>
      )}
    </ComboWrap>
  );
};

Combo.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
  apiPath: PropTypes.string,
  apiParams: PropTypes.string,
  optionFormatter: PropTypes.func,
  optionLimit: PropTypes.number,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.object,
  errors: PropTypes.object,
  hideLabel: PropTypes.bool,
  showToggleButton: PropTypes.bool,
  placeholder: PropTypes.string,
  noResultsLabel: PropTypes.string,
  span: PropTypes.number,
};

export default Combo;
