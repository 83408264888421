import { QueryClient } from '@tanstack/react-query';
import { USER_OPTIONS } from 'api/user/user';
import { redirect } from 'react-router-dom';

export async function onboardedGuardLoader(queryClient: QueryClient) {
  const data = await queryClient.ensureQueryData(USER_OPTIONS.user());

  if (!data?.codeOfConduct) {
    return redirect('/onboarding');
  }

  return null;
}
