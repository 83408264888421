import { tv } from 'tailwind-variants';

export const navAction = tv({
  slots: {
    container:
      'group flex w-full items-center rounded-lg text-left text-sm leading-tight text-ds-text-primary hover:bg-ds-bg-weaker focus:bg-ds-bg-weaker overflow-clip transition-colors focus:outline-none',
    text: 'block',
  },
  variants: {
    state: {
      expanded: {
        container: 'px-3 py-2',
        text: 'opacity-100',
      },
      collapsed: {
        container: 'w-10 h-10',
        text: 'opacity-0 pointer-events-none',
      },
    },
    theme: {
      outlined: {
        container:
          'ring-1 ring-transparent hover:ring-ds-stroke-tertiary focus:ring-ds-stroke-tertiary',
      },
      bare: '',
    },
  },
  defaultVariants: {
    state: 'expanded',
    theme: 'bare',
  },
});
