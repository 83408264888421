import { removeImpersonation } from 'api/user/user.helpers';
import UnstyledButton from 'components/UnstyledButton';
import styled from 'styled-components';
import { bp } from 'styles/helpers';
import screen from 'superior-mq';

const StyledBar = styled.div`
  max-height: 40px;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  color: var(--white);
  background-color: var(--primary-green);
  text-align: center;
  padding: 8px 32px;
  z-index: 20;

  a {
    color: var(--white);

    &:hover {
      color: var(--orange);
    }
  }

  ${screen.below(
    bp.tablet,
    `
    display: none;
  `
  )}
`;

const DisableImpersonationButton = styled(UnstyledButton)`
  color: var(--white);
  margin-left: 10px;

  &:hover {
    color: var(--orange);
  }
`;

const ImpersonationBar = ({ impersonation }) => {
  if (!impersonation) return null;

  const type =
    impersonation?.type === 'participant' ? 'Participant' : 'Recruiter';

  return (
    <StyledBar>
      WARNING: You are currently impersonating user #{impersonation.id}: {type}{' '}
      - {impersonation.firstName} {impersonation.lastName}
      <DisableImpersonationButton
        onClick={() => {
          removeImpersonation();
          window.location = '/';
        }}
      >
        (Cancel)
      </DisableImpersonationButton>
    </StyledBar>
  );
};

export default ImpersonationBar;
