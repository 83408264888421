import { DevTool } from '@hookform/devtools';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  QueryClient,
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { INTERVIEWS_QUERY_OPTIONS } from 'api/interviews';
import { JOBS_QUERY_OPTIONS } from 'api/jobs';
import { PARTICIPANT_QUERIES } from 'api/participants';
import { PARTNER_QUERY_OPTIONS } from 'api/partners';
import { AsyncComboboxField } from 'components/ComboboxField/AsyncComboboxField';
import InterviewIntroModal from 'components/dash/csm/InterviewIntroModal';
import { Button, ButtonLink } from 'components/ds/Button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'components/ds/Form';
import { Input } from 'components/ds/Input';
import { LoadingContainer } from 'components/ds/Spinner';
import { toast } from 'components/ds/Toast/Toast';
import {
  PageActions,
  PageHeader,
  PageHeading,
} from 'components/layout-v2/PageLayout';
import { useModal } from 'components/Modal';
import api from 'dataService/api';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useForm, useWatch } from 'react-hook-form';
import {
  defer,
  LoaderFunctionArgs,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { idFromObject } from 'util/idFromObj';
import { isNonEmptyString } from 'util/isNonEmptyString';
import { z } from 'zod';

export function interviewLoader(
  queryClient: QueryClient,
  { params }: LoaderFunctionArgs
) {
  const { interviewId } = params;

  if (!interviewId) {
    throw new Error('Interview ID is required to load interview');
  }

  try {
    // Prefetch the interview data and store it in the client cache.
    const interviewPromise = queryClient.ensureQueryData(
      INTERVIEWS_QUERY_OPTIONS.detail(interviewId)
    );

    return defer({
      interviewPromise,
    });
  } catch (_error) {
    return null;
  }
}

export function EditInterview() {
  return (
    <div className="content-container">
      <Helmet title="Edit Interview" />
      <PageHeader>
        <PageHeading>Edit Interview</PageHeading>
        <PageActions>
          <ButtonLink to="/interviews/create">Add New Interview</ButtonLink>
        </PageActions>
      </PageHeader>
      <React.Suspense fallback={<LoadingContainer level="component" />}>
        <EditInterviewForm />
      </React.Suspense>
    </div>
  );
}

const EditInterviewFormSchema = z
  .object({
    partner: z
      .object({
        id: z.coerce.number(),
        name: z.string(),
      })
      .nullable()
      .optional()
      .transform(idFromObject),
    nonPartnerName: z.string().optional(),
    job: z
      .object({
        id: z.coerce.number(),
        name: z.string(),
      })
      .nullable()
      .optional()
      .transform(idFromObject),
    title: z.string().optional(),
    recruiter: z
      .object({
        id: z.coerce.number(),
        name: z.string(),
      })
      .nullable()
      .optional()
      .transform(idFromObject),
    participant: z
      .object(
        {
          id: z.coerce.number(),
          name: z.string(),
        },
        {
          errorMap: () => ({ message: 'Please select a participant' }),
        }
      )
      .transform(idFromObject),
  })
  .superRefine((arg, ctx) => {
    if (!arg.partner && !isNonEmptyString(arg.nonPartnerName)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Partner or Non-Partner is required',
        path: ['partner'],
      });
    }

    if (arg.partner && isNonEmptyString(arg.nonPartnerName)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Only Partner or Non-Partner is allowed, not both',
        path: ['partner'],
      });
    }

    if (!arg.job && !isNonEmptyString(arg.title)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Posted Job or Non-Posted Job is required',
        path: ['job'],
      });
    }

    if (arg.job && isNonEmptyString(arg.title)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Only one Posted Job or Non-Posted Job is allowed, not both',
        path: ['job'],
      });
    }
  })
  .transform(({ participant, ...rest }) => {
    // Adding additional fields to the object for backend
    return {
      ...rest,
      participantId: participant,
    };
  });

type EditInterviewFormInput = z.input<typeof EditInterviewFormSchema>;
type EditInterviewFormOutput = z.output<typeof EditInterviewFormSchema>;

function formatPersonObj(person?: {
  id: number;
  firstName: string;
  lastName: string;
}) {
  if (!person) return null;

  return {
    id: person.id,
    firstName: person.firstName,
    lastName: person.lastName,
    name: `${person.firstName} ${person.lastName}`,
  };
}

function transformPartner(partner?: { id: number; name: string }) {
  if (!partner) return null;

  return {
    id: partner.id,
    name: partner.name,
  };
}

function transformJob(job?: {
  id: number;
  title: string;
  locations?: { id: number; name: string }[];
}) {
  if (!job) return null;

  const locationString = createLocationsString(job.locations);

  return {
    id: job.id,
    name: `${job.title}${
      locationString.length > 0 ? ` - ${locationString}` : ''
    }`,
  };
}

function EditInterviewForm() {
  const queryClient = useQueryClient();
  const { modalOpen, toggleModal, modalData } = useModal();
  const navigate = useNavigate();

  const interviewsMutation = useMutation({
    mutationFn: async (payload: EditInterviewFormOutput & { id: number }) => {
      const { id, ...rest } = payload;
      const response = await api.patch(`/interviews/${id}`, rest);

      return response.data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries();
      toast.success('Interview saved successfully.');
    },
    onError: () => {
      toast.error('There was an error saving the interview.');
    },
  });
  const { interviewId } = useParams();
  const { data: interview } = useSuspenseQuery(
    INTERVIEWS_QUERY_OPTIONS.detail(interviewId!)
  );

  const form = useForm<EditInterviewFormInput>({
    mode: 'onBlur',
    resolver: zodResolver(EditInterviewFormSchema),
    defaultValues: {
      partner: transformPartner(interview?.partner),
      job: transformJob(interview?.job),
      nonPartnerName: interview?.nonPartnerName ?? '',
      title: interview?.title ?? '',
      recruiter: formatPersonObj(interview?.recruiter),
      participant: formatPersonObj(interview?.participant) ?? undefined,
    },
  });

  const { handleSubmit, control, resetField, trigger } = form;
  const partner = useWatch({
    control,
    name: 'partner',
  });
  const hasExistingPartner = typeof partner?.id === 'number';

  const jobsQueryOptions = React.useMemo(
    () => getJobsListQueryOptions(partner?.id),
    [partner?.id]
  );

  const recruitersQueryOptions = React.useMemo(
    () => PARTNER_QUERY_OPTIONS.infiniteRecruitersList(partner?.id ?? null),
    [partner?.id]
  );

  function onSubmit(data: EditInterviewFormOutput) {
    try {
      if (typeof Number(interviewId) !== 'number') {
        throw new Error('Interview ID is not a number.');
      }

      const payload = {
        ...data,
        id: Number(interviewId),
      };

      interviewsMutation.mutate(payload);
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      }
      toast.error('An unknown error occurred while saving the interview.');
    }
  }

  return (
    <Form {...form}>
      <form
        onSubmit={handleSubmit((data) => {
          onSubmit(data as unknown as EditInterviewFormOutput);
        })}
        className="stack-y-8"
      >
        <div className="grid grid-cols-1 gap-x-4 gap-y-6 md:grid-cols-2">
          <FormField
            control={control}
            name="partner"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>Partner</FormLabel>
                  <FormControl>
                    <AsyncComboboxField
                      {...field}
                      displayName="Partner"
                      infiniteQueryOptions={
                        PARTNER_QUERY_OPTIONS.infinitePartnersList
                      }
                      onComboSelect={() => {
                        resetField('job', {
                          defaultValue: null,
                        });
                        resetField('recruiter', {
                          defaultValue: null,
                        });
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
          <FormField
            control={control}
            name="nonPartnerName"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>Non-Partner</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      onBlur={() => {
                        trigger('partner');
                        field.onBlur();
                      }}
                      type="text"
                    />
                  </FormControl>
                </FormItem>
              );
            }}
          />
          <FormField
            control={control}
            name="job"
            disabled={!hasExistingPartner}
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>Job Title (select a posted role)</FormLabel>
                  <FormControl>
                    <AsyncComboboxField
                      {...field}
                      displayName="Job Title"
                      infiniteQueryOptions={jobsQueryOptions}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
          <FormField
            control={control}
            name="title"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>Job Title (if not posted online)</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      onBlur={() => {
                        trigger('job');
                        field.onBlur();
                      }}
                      type="text"
                    />
                  </FormControl>
                </FormItem>
              );
            }}
          />

          <FormField
            control={control}
            name="recruiter"
            disabled={!hasExistingPartner}
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>Recruiter</FormLabel>
                  <FormControl>
                    <AsyncComboboxField
                      {...field}
                      displayName="Recruiter"
                      infiniteQueryOptions={recruitersQueryOptions}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />

          <FormField
            control={control}
            name="participant"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>Participant (read-only)</FormLabel>
                  <FormControl>
                    <AsyncComboboxField
                      {...field}
                      displayName="Participant"
                      aria-readonly
                      disabled // We don't want to allow changing the participant for editing
                      infiniteQueryOptions={
                        PARTICIPANT_QUERIES.infiniteParticipantsList
                      }
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
        </div>
        <div className="stack-x-3">
          <Button
            type="submit"
            disabled={interviewsMutation.isPending}
            isLoading={interviewsMutation.isPending}
          >
            Save Changes
          </Button>
          <Button
            variant="destructive"
            onClick={(e: any) => {
              e.preventDefault();

              toggleModal('confirmDelete', { id: interviewId });

              return false;
            }}
          >
            Delete
          </Button>
        </div>
        <DevTool control={control} />
      </form>
      <InterviewIntroModal
        modalOpen={modalOpen}
        requestClose={(update: any) => {
          if (typeof update === 'boolean' && update) {
            navigate('/pipeline-plus/interviews');
            return;
          }

          toggleModal();
        }}
        modalData={modalData}
      />
    </Form>
  );
}

function createLocationsString(locations?: { name: string }[]) {
  return locations?.map((location) => location.name).join(' | ') ?? '';
}

function getJobsListQueryOptions(partnerId?: number) {
  return (params: { [key: string]: unknown }) => {
    return JOBS_QUERY_OPTIONS.infiniteJobsList({
      ...params,
      ...(partnerId ? { partner: partnerId } : {}),
    });
  };
}
