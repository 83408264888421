import * as React from 'react';
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from 'util/bodyScrollLock';

/**
 * A hook that locks the body scroll when a specified element is mounted.
 *
 * @param shouldLock Whether the body scroll should be locked.
 * @returns A ref that should be passed to the element we want to allow
 *   scrolling on when the body scroll is locked.
 */
export function useBodyScrollLock<T extends HTMLElement = HTMLElement>(
  shouldLock: boolean
) {
  const scrollLockElementRef = React.useRef<T>(null);

  React.useEffect(() => {
    if (!scrollLockElementRef.current) {
      clearAllBodyScrollLocks();
      return;
    }
    if (shouldLock) {
      disableBodyScroll(scrollLockElementRef.current, {
        reserveScrollBarGap: false,
        allowTouchMove: (el: any) => {
          if (!el) return false;
          while (el && el !== document.body) {
            if (typeof el.className === 'string') {
              if (el.className.indexOf('body-scroll-lock-ignore') > -1) {
                return true;
              }
            }

            el = el.parentElement;
          }
          return false;
        },
      });
    } else {
      enableBodyScroll(scrollLockElementRef.current);
    }
  }, [shouldLock]);

  React.useEffect(() => {
    const ele = scrollLockElementRef.current;
    return () => {
      if (ele) {
        enableBodyScroll(ele);
      }
    };
  }, []);

  return scrollLockElementRef;
}
