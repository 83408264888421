import * as React from 'react';
import { tv, VariantProps } from 'tailwind-variants';

interface SkeletonGroupProps extends VariantProps<typeof skeletonStyles> {
  children: React.ReactNode;
}
export function SkeletonGroup({
  color,
  animate,
  children,
}: SkeletonGroupProps) {
  return (
    <SkeletonContext.Provider value={{ color, animate }}>
      {children}
    </SkeletonContext.Provider>
  );
}

interface SkeletonProps
  extends Omit<React.ComponentPropsWithoutRef<'div'>, 'color'>,
    VariantProps<typeof skeletonStyles> {}
export function Skeleton({
  color: colorInput,
  animate: animateInput,
  className,
  ...rest
}: SkeletonProps) {
  const context = React.useContext(SkeletonContext);
  const color = colorInput ?? context.color;
  const animate = animateInput ?? context.animate;

  return (
    <div className={skeletonStyles({ color, animate, className })} {...rest} />
  );
}

const SKELETON_DEFAULT_VARIANTS = {
  color: 'foundation',
  animate: true,
} as const;

const skeletonStyles = tv({
  base: 'rounded-md',
  variants: {
    color: {
      foundation: 'bg-ds-neutral-200',
      inverted: 'bg-ds-neutral-100',
      'layer-1': 'bg-ds-neutral-100',
      'layer-2': 'bg-ds-neutral-200',
    },
    animate: {
      true: 'animate-pulse',
    },
  },
  defaultVariants: SKELETON_DEFAULT_VARIANTS,
});

const SkeletonContext = React.createContext<
  VariantProps<typeof skeletonStyles>
>(SKELETON_DEFAULT_VARIANTS);
