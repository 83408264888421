import { QueryClient } from '@tanstack/react-query';
import { PARTICIPANT_QUERIES } from 'api/participants';
import BreaklineParticipantView from 'components/participants/participant/BlParticipant/BreaklineParticipantView';
import { LoaderFunctionArgs, useParams } from 'react-router-dom';

export function participantLoader(
  queryClient: QueryClient,
  { params }: LoaderFunctionArgs
) {
  const { participantId } = params;

  if (!participantId) {
    throw new Error('Participant ID is required to load participant');
  }

  queryClient
    .prefetchQuery(PARTICIPANT_QUERIES.participant(participantId))
    .catch(() => {
      // Ignore errors and handle them in the component error boundary instead
      return null;
    });

  return null;
}
export function Participant() {
  const { participantId } = useParams();

  return (
    <div className="content-container">
      <BreaklineParticipantView id={participantId} />
    </div>
  );
}
