export const GAME_PLAN_SEARCH_PARAM = 'gamePlan' as const;

export const FILTER_KEYS = {
  locations: 'locations',
  skills: 'skills',
  functions: 'functions',
  segments: 'segments',
  outreach: 'outreach',
  participantManager: 'participantManager',
  tracks: 'tracks',
  compensation: 'compensation',
  active: 'active',
  decision: 'decision',
  statuses: 'statuses',
} as const;
