import { useQuery } from '@tanstack/react-query';
import { DASHBOARD_QUERY_OPTIONS } from 'api/dashboard';
import { useLogout, useUserProfile } from 'api/user/user';
import { isBreakLineUser } from 'api/user/user.helpers';
import { CurrentUserAvatar } from 'components/CurrentUserAvatar';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'components/ds/DropdownMenu';
import { Icon, IconUse } from 'components/ds/icons/Icon';
import { Kbd } from 'components/ds/Kbd';
import { Logomark } from 'components/ds/Logomark';
import { toast } from 'components/ds/Toast/Toast';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'components/ds/Tooltip';
import { CommandMenu, useCommandMenu } from 'components/layout-v2/CommandMenu';
import { MobileNav } from 'components/layout-v2/MobileNav';
import useMediaQuery from 'hooks/useMediaQuery';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { Link, useNavigate } from 'react-router-dom';

export function SiteHeader() {
  const { data: user } = useUserProfile();
  const [, setSiteHeaderPortal] =
    React.useContext(SiteHeaderPortalContext) ?? [];

  return (
    <div className="pointer-events-auto flex h-full items-center justify-between gap-2 border-b border-solid border-ds-stroke-tertiary bg-ds-bg-foundation px-5 md:px-6">
      <Link
        to="/"
        aria-label="Go to homepage"
        className="grid h-10 w-10 place-items-center md:hidden"
      >
        <Logomark aria-hidden className="w-[30px]" />
        <span className="sr-only">Go to homepage</span>
      </Link>
      <div ref={setSiteHeaderPortal} />
      <div className="ml-auto stack-x-2">
        <div className="flex items-center divide-ds-stroke-tertiary stack-x-[5px] md:divide-x md:stack-x-3">
          <div className="flex md:stack-x-3">
            {user && isBreakLineUser(user.type) && <CommandMenu />}

            <NotficationsWidget />
          </div>
          <UserProfileWidget />
        </div>
        <MobileNav />
      </div>
    </div>
  );
}

const SiteHeaderPortalContext = React.createContext<
  | [
      HTMLDivElement | null,
      React.Dispatch<React.SetStateAction<HTMLDivElement | null>>,
    ]
  | null
>(null);

interface SiteHeaderPortalProviderProps {
  children: React.ReactNode;
}
export function SiteHeaderPortalProvider({
  children,
}: SiteHeaderPortalProviderProps) {
  const siteHeaderPortalState = React.useState<HTMLDivElement | null>(null);

  return (
    <SiteHeaderPortalContext.Provider value={siteHeaderPortalState}>
      {children}
    </SiteHeaderPortalContext.Provider>
  );
}

interface SiteHeaderProps {
  children: React.ReactNode;
}
export function SiteHeaderPortal({ children }: SiteHeaderProps) {
  const [portalContentElement] =
    React.useContext(SiteHeaderPortalContext) ?? [];

  return portalContentElement
    ? ReactDOM.createPortal(children, portalContentElement)
    : null;
}

function NotficationsWidget() {
  const [open, setOpen] = React.useState(false);
  const { data } = useQuery(DASHBOARD_QUERY_OPTIONS.unreadCount());
  const { data: user } = useUserProfile();
  const hasUnread = data != null && data.total > 0;

  return (
    <TooltipProvider>
      <Tooltip open={open} onOpenChange={setOpen}>
        <TooltipTrigger asChild>
          <Link
            to="/notifications"
            aria-label="View Notifications"
            className="group relative grid h-10 w-10 place-items-center rounded ring-1 ring-transparent transition-all hover:bg-ds-bg-weaker hover:ring-ds-stroke-tertiary focus:bg-ds-bg-weaker focus:outline-none focus:ring-ds-stroke-tertiary"
          >
            <Icon
              className="col-span-full row-span-full h-4 w-4 text-ds-icon-primary md:h-5 md:w-5"
              aria-hidden
            >
              <IconUse id="notification-2-line" />
            </Icon>

            {/** Unread indicator */}
            {hasUnread && (
              <span className="pointer-events-none relative col-span-full row-span-full block h-4 w-4 select-none md:h-5 md:w-5">
                <span
                  aria-hidden
                  className="pointer-events-none absolute -right-0.5 -top-0.5 block h-3 w-3 rounded-full border-2 border-solid border-ds-bg-foundation bg-ds-secondary-base transition-colors group-hover:border-ds-bg-weaker"
                />
              </span>
            )}
          </Link>
        </TooltipTrigger>
        <TooltipContent
          side="bottom"
          align="end"
          sideOffset={10}
          className="w-[268px] border border-ds-stroke-tertiary bg-ds-bg-foundation p-0 text-ds-text-primary shadow-sm"
        >
          <div className="flex items-center justify-between border-b border-ds-stroke-secondary px-3 py-3">
            <span className="text-sm font-medium">Notificiations</span>
          </div>
          <div className="flex gap-1.5 bg-ds-bg-weaker px-1.5 py-2">
            <NotificationCollectionItem
              label="FYI"
              count={data?.notifications ?? 0}
            >
              <NotificationCollectionItemLink
                label="View All FYIs"
                href="/notifications"
                onClick={() => {
                  setOpen(false);
                }}
              />
            </NotificationCollectionItem>
            {isBreakLineUser(user?.type ?? '') && (
              <NotificationCollectionItem
                label="Tasks"
                count={data?.tasks ?? 0}
              >
                <NotificationCollectionItemLink
                  label="View All Tasks"
                  href="/notifications/tasks"
                  onClick={() => {
                    setOpen(false);
                  }}
                />
              </NotificationCollectionItem>
            )}
          </div>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}

interface NotificationCollectionItemProps {
  label: string;
  count: number;
  children?: React.ReactNode;
}
function NotificationCollectionItem({
  label,
  count,
  children,
}: NotificationCollectionItemProps) {
  const countText = count > 99 ? '99+' : count;

  return (
    <div className="linkbox group relative flex flex-1 justify-between rounded border border-ds-stroke-tertiary bg-ds-bg-foundation p-3 transition-colors focus-within:bg-ds-bg-foundation/50 hover:bg-ds-bg-foundation/50">
      <div className="stack-y-1.5">
        <p className="m-0 text-sm font-normal text-ds-text-secondary">
          {label}
        </p>
        <p className="m-0 text-2xl">
          <span className="sr-only">{count}</span>
          <span aria-hidden>{countText}</span>
        </p>
      </div>
      {children}
    </div>
  );
}

interface NotificationCollectionItemLinkProps {
  label: string;
  href: string;
  onClick?: () => void;
}
function NotificationCollectionItemLink({
  label,
  href,
  onClick,
}: NotificationCollectionItemLinkProps) {
  return (
    <Link
      to={href}
      onClick={() => {
        onClick?.();
      }}
      className="linkOverlay flex h-min"
    >
      <span className="absolute right-3 grid h-6 w-6 translate-y-1 place-items-center rounded-full bg-ds-primary-lighter opacity-0 transition group-focus-within:translate-y-0 group-focus-within:opacity-100 group-hover:translate-y-0 group-hover:opacity-100">
        <Icon className="h-3 w-3 text-ds-primary-dark" aria-hidden>
          <IconUse id="ri-link" />
        </Icon>
      </span>
      <span className="sr-only">{label}</span>
    </Link>
  );
}

function UserProfileWidget() {
  const { data: user } = useUserProfile();
  const isTablet = useMediaQuery('(min-width: 768px)');
  const logoutMutation = useLogout();
  const navigate = useNavigate();
  const [, setOpen] = useCommandMenu();
  const fullName = `${user?.firstName ?? ''} ${user?.lastName ?? ''}`;

  return (
    <DropdownMenu>
      <div className="md:pl-3">
        {isTablet ? (
          <>
            {/** Desktop Trigger */}
            <DropdownMenuTrigger
              aria-label={fullName}
              className="items-center overflow-clip rounded-md px-2 py-1 ring-1 ring-transparent transition-all stack-x-3 hover:bg-ds-bg-weaker hover:ring-ds-stroke-tertiary focus:bg-ds-bg-weaker focus:outline-none focus:ring-ds-stroke-tertiary"
            >
              <span className="items-center stack-x-2">
                <CurrentUserAvatar />
                <span className="text-xs text-ds-text-primary">
                  {user?.firstName}
                </span>
              </span>
              <span className="block" aria-hidden>
                <Icon className="h-4 w-4 text-ds-icon-primary" aria-hidden>
                  <IconUse id="arrow-down-s-line" />
                </Icon>
              </span>
            </DropdownMenuTrigger>
          </>
        ) : (
          <>
            {/** Mobile Trigger */}
            <DropdownMenuTrigger
              aria-label={fullName}
              className="grid h-10 w-10 place-items-center rounded ring-1 ring-transparent transition-all hover:ring-ds-stroke-tertiary focus:outline-none focus:ring-ds-stroke-tertiary"
            >
              <CurrentUserAvatar size="sm" />
            </DropdownMenuTrigger>
          </>
        )}

        <DropdownMenuContent align="end" sideOffset={10} className="w-[250px]">
          <header className="items-start px-4 pb-[10px] pt-4 stack-x-3">
            <CurrentUserAvatar />

            <div className="min-w-0 stack-y-0">
              <p className="m-0 w-full truncate text-sm text-ds-text-primary">
                {user?.firstName} {user?.lastName}
              </p>
              <p className="m-0 w-full truncate text-sm text-ds-text-tertiary">
                {user?.email}
              </p>
            </div>
          </header>
          <DropdownMenuSeparator />
          <DropdownMenuGroup className="relative">
            <DropdownMenuItem asChild>
              <Link
                to="/"
                className="items-center justify-between no-underline stack-x-2"
              >
                Dashboard
              </Link>
            </DropdownMenuItem>

            <DropdownMenuItem asChild>
              <Link
                to="/account"
                className="items-center justify-between no-underline stack-x-2"
              >
                Settings
                <Icon className="h-4 w-4 text-ds-icon-primary" aria-hidden>
                  <IconUse id="settings-4-line" />
                </Icon>
              </Link>
            </DropdownMenuItem>

            {user && isBreakLineUser(user?.type) && (
              <DropdownMenuItem asChild>
                <button
                  onClick={() => {
                    setOpen(true);
                  }}
                  className="group w-full items-center justify-between text-left stack-x-2"
                >
                  Search
                  <Kbd className="transition-colors group-hover:bg-ds-bg-foundation">
                    <span>⌘</span>
                    <span>K</span>
                  </Kbd>
                </button>
              </DropdownMenuItem>
            )}
          </DropdownMenuGroup>
          <DropdownMenuSeparator />
          <DropdownMenuGroup className="relative">
            <DropdownMenuItem className="w-full text-left stack-x-2" asChild>
              <button
                onClick={() => {
                  logoutMutation.mutate(undefined, {
                    onSuccess: () => {
                      navigate('/login', { replace: true });
                    },
                    onError: () => {
                      toast.error('Logout was unsuccessful');
                    },
                  });
                }}
              >
                <Icon aria-hidden className="h-4 w-4 text-ds-icon-primary">
                  <IconUse id="logout-box-line" />
                </Icon>
                Logout
              </button>
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </div>
    </DropdownMenu>
  );
}
