import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { createUseInfiniteFilter } from 'api/filters';
import { useInfiniteFunctionalAreasFilter } from 'api/functional-areas';
import { JOBS_KEYS, JOBS_QUERY_OPTIONS } from 'api/jobs';
import { LOCATIONS_KEYS } from 'api/locations';
import { useInfinitePersonasFilter } from 'api/personas';
import { useInfiniteSkillsFilter } from 'api/skills';
import { useInfiniteStaffFilter } from 'api/staff';
import { useInfiniteTracksFilter } from 'api/tracks';
import { Button, ButtonIcon, ButtonLink } from 'components/ds/Button';
import { IconUse } from 'components/ds/icons/Icon';
import { toast } from 'components/ds/Toast/Toast';
import {
  Tooltip,
  TooltipContent,
  TooltipGroup,
  TooltipProvider,
  TooltipTrigger,
} from 'components/ds/Tooltip';
import { AsyncFilter } from 'components/filters-new/AsyncFilter';
import { useFilterGroup } from 'components/filters-new/context/FilterGroupContext';
import { mapFilterGroupStateToRecord } from 'components/filters-new/helpers/transformFilters';
import { StaticFilter } from 'components/filters-new/StaticFilter';
import { FilterGroup } from 'components/filters-new/ui/FilterGroup';
import { H4 } from 'components/H';
import {
  TableLoader,
  TableOverlay,
} from 'components/jobs/components/TableOverlay';
import Pagination from 'components/Pagination';
import Search, { useSearch } from 'components/Search';
import TagList from 'components/TagList';
import ResourceInfoBlock from 'components/UI/participant/ResourceInfoBlock';
import UnstyledList from 'components/UnstyledList';
import { useComponentSearchParams } from 'context/ComponentSearchParams';
import api from 'dataService/api';
import { AddToBrainstormSheet } from 'modules/team/jobs/components/AddToBrainstormSheet';
import { PARTICIPANT_PATHS } from 'modules/team/participant/constants/participant.constants';
import { PIPELINE_QUERIES } from 'modules/team/pipeline/api/pipeline';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { bp } from 'styles/helpers';
import screen from 'superior-mq';
import { createInfiniteQuery } from 'util/createInfiniteQuery';
import { formatCommaNumbers, formatStartDate } from 'util/formatText';
import {
  experienceOptions,
  getDateAvailableOptions,
  getOptionFromValue,
  participantWorkAuthOptions,
  totalCompensationOptions,
} from 'util/forms';
import {
  useDefaultTableSearchParams,
  useTableSearchParamsCallbacks,
} from 'util/tableHelpers';

const UserSearch = styled(Search)`
  padding-bottom: 0;
`;

const CandidateItem = styled.li`
  padding: 30px 0;

  &:not(:last-child) {
    border-bottom: solid 1px var(--border-gray);
  }
`;

const CandidateSummary = styled.div`
  padding-left: 30px;
  margin-bottom: 20px;
  flex: 0 0 45%;

  ${screen.below(bp.mobile, `padding-left: 0;`)}
`;

const CandidateDetail = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  font-size: ${rem(15)};

  p {
    margin-top: 0;
  }

  ${screen.below(
    bp.mobile,
    `
    flex-direction: column;

    & > * {
      flex: 0 0 100%;
    }
  `
  )}
`;

const CandidateTags = styled(UnstyledList)`
  flex: 0 0 50%;
  display: flex;
  flex-direction: column;

  ul {
    display: inline;
  }
`;

// Should be its own component
export const ResultsHeader = styled.div`
  font-size: ${rem(15)};

  ${screen.below(
    bp.portrait,
    `
      margin-bottom: 10px;
    `
  )}
`;

const mapName = (i) => i.name;

const TagRow = styled.li`
  display: flex;
  font-size: ${rem(15)};
  margin-bottom: 10px;
`;

const TagListTitle = styled.h3`
  font-size: ${rem(15)};
  display: inline-block;
  flex: 0 0 160px;
  text-align: right;
  margin-right: 15px;
  line-height: inherit;

  ${screen.below(bp.mobile, `text-align: left;`)}
`;

export const ADD_PARTICIPANT_TABLE_KEY = 'table_addParticipants';

export function AddParticipants({ children }) {
  return (
    <section>
      <H4 as="h2">Add Participants To Game Plan</H4>
      {children}
    </section>
  );
}

/**
 * @param {string} id The ID of the job
 * @param {function} onUpdate Function to call when a participant is added
 *   (allows other components to react)
 * @param {object} removed Just a signal that another component has removed a
 *   user (allows this component to react (call mutate to get fresh data))
 * @returns
 */
const AddParticipantsTable = () => {
  const { jobId } = useParams();
  const { searchTerm, searchSubmit } = useSearch();
  const { params } = useComponentSearchParams();
  const [searchParams, setSearchParams] = params;
  const { filters } = useFilterGroup();
  const { handlePageChange, handlePerRowsChange } =
    useTableSearchParamsCallbacks({
      key: ADD_PARTICIPANT_TABLE_KEY,
      setSearchParams,
    });
  const mergedFilters = React.useMemo(() => {
    return {
      ...mapFilterGroupStateToRecord(filters),
      ...(searchTerm
        ? {
            term: searchTerm,
          }
        : {}),
    };
  }, [searchTerm, filters]);
  const { queryParams, parsedSearchParams } = useDefaultTableSearchParams({
    key: ADD_PARTICIPANT_TABLE_KEY,
    filters: mergedFilters,
    searchParams,
  });
  const deferredParams = React.useDeferredValue(queryParams);
  const isDeferred =
    JSON.stringify(queryParams) !== JSON.stringify(deferredParams);
  const { data, isFetching } = useSuspenseQuery(
    JOBS_QUERY_OPTIONS.potentialParticipantsList(jobId, deferredParams)
  );
  const isPending = isFetching || isDeferred;

  const handleSearch = (e) => {
    handlePageChange(1);
    searchSubmit(e);
  };

  return (
    <div>
      <UserSearch label="Search..." onSubmit={handleSearch} />

      <div className="py-5">
        <FilterGroup>
          <AsyncFilter
            groupId="location"
            label="Location"
            useInfiniteFilter={useInfiniteLocationsFilter}
          />
          <StaticFilter
            groupId="dateAvailable"
            label="Date Available"
            filters={dateAvailableFilters}
          />
          <StaticFilter
            groupId="experience"
            label="Experience"
            filters={experienceFilters}
          />
          <AsyncFilter
            groupId="function"
            label="Function"
            useInfiniteFilter={useInfinitePersonasFilter}
          />
          <AsyncFilter
            groupId="segment"
            label="Segment"
            useInfiniteFilter={useInfiniteFunctionalAreasFilter}
          />
          <AsyncFilter
            groupId="skill"
            label="Skills"
            useInfiniteFilter={useInfiniteSkillsFilter}
          />
          <AsyncFilter
            groupId="track"
            label="Tracks"
            useInfiniteFilter={useInfiniteTracksFilter}
          />
          <AsyncFilter
            groupId="partnerFit"
            label="Partner Fit For"
            useInfiniteFilter={useInfinitePartnersFilter}
          />
          <AsyncFilter
            groupId="cohort"
            label="Cohort"
            useInfiniteFilter={useInfiniteCohortsFilter}
          />
          <StaticFilter
            groupId="totalCompensation"
            label="Total Comp."
            filters={totalCompensationFilters}
          />
          <AsyncFilter
            groupId="exo"
            label="Participant Manager"
            useInfiniteFilter={useInfiniteExoStaffFilter}
          />
        </FilterGroup>
      </div>

      {data?.items?.length ? (
        <ResultsHeader>{formatCommaNumbers(data.total)} results</ResultsHeader>
      ) : null}

      <TableOverlay pending={isPending}>
        {data && data.items?.length ? (
          <UnstyledList>
            {data.items.map((row) => {
              const workAuth = getOptionFromValue(
                row?.profile?.workAuth,
                participantWorkAuthOptions
              );

              return (
                <CandidateItem key={row.id}>
                  <div className="grid grid-cols-1 gap-y-2.5 sm:grid-cols-2">
                    <ResourceInfoBlock
                      participant={row}
                      primaryTitleSize="large"
                      participantNameLinkInNewTab={false}
                    />

                    <div className="justify-self-end">
                      <TooltipGroup className="stack-x-0/inline">
                        <AddParticipantAction userId={row.id} />

                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <ButtonLink
                                to={PARTICIPANT_PATHS.detail(row.id)}
                                variant="ghost"
                                svgOnly
                              >
                                <ButtonIcon aria-hidden>
                                  <IconUse id="profile-line" />
                                </ButtonIcon>
                                <span className="sr-only">View Profile</span>
                              </ButtonLink>
                            </TooltipTrigger>
                            <TooltipContent side="bottom">
                              View Profile
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>

                        <TooltipProvider>
                          <Tooltip>
                            <AddToBrainstormSheet
                              trigger={
                                <TooltipTrigger asChild>
                                  <Button svgOnly variant="ghost">
                                    <ButtonIcon aria-hidden>
                                      <IconUse id="briefcase-line" />
                                    </ButtonIcon>
                                    <span className="sr-only">
                                      Game Plan Different Role
                                    </span>
                                  </Button>
                                </TooltipTrigger>
                              }
                              participant={{
                                id: Number(row.id),
                                name: `${row.firstName} ${row.lastName}`,
                              }}
                            />

                            <TooltipContent side="bottom" align="end">
                              Game Plan Different Role
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      </TooltipGroup>
                    </div>
                  </div>

                  <CandidateDetail>
                    <CandidateSummary>
                      {row.firstName} is {row.profile.oneLiner}
                    </CandidateSummary>
                    <CandidateTags>
                      {row.profile?.skills?.length ? (
                        <TagRow>
                          <TagListTitle>Skills:</TagListTitle>
                          <TagList
                            tags={row.profile.skills.map(mapName)}
                            limit={2}
                            compact
                          />
                        </TagRow>
                      ) : null}

                      {row.profile?.locations?.length ? (
                        <TagRow>
                          <TagListTitle>Locations:</TagListTitle>
                          <TagList
                            tags={row.profile.locations.map(mapName)}
                            limit={3}
                            compact
                          />
                        </TagRow>
                      ) : null}

                      {row.profile?.dateAvailable ? (
                        <TagRow>
                          <TagListTitle>Date Available:</TagListTitle>
                          {formatStartDate(row.profile.dateAvailable)}
                        </TagRow>
                      ) : null}

                      {row.profile?.totalCompensation ? (
                        <TagRow>
                          <TagListTitle>Total Comp:</TagListTitle>
                          {row.profile.totalCompensation}
                        </TagRow>
                      ) : null}

                      {workAuth?.label ? (
                        <TagRow>
                          <TagListTitle>Work Auth:</TagListTitle>
                          {workAuth.label}
                        </TagRow>
                      ) : null}

                      {row.exo ? (
                        <TagRow>
                          <TagListTitle>Participant Manager:</TagListTitle>
                          {row.exo.firstName} {row.exo.lastName}
                        </TagRow>
                      ) : null}
                    </CandidateTags>
                  </CandidateDetail>
                </CandidateItem>
              );
            })}
          </UnstyledList>
        ) : null}
        <TableLoader pending={isPending} />
      </TableOverlay>

      <Pagination
        rowCount={data && data.total}
        currentPage={parsedSearchParams.page}
        rowsPerPage={parsedSearchParams.limit}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerRowsChange}
      />
    </div>
  );
};

function AddParticipantAction({ userId }) {
  const brainstormParticipantsMutation = useUpdateBrainstormParticipants();

  const updateParticipants = (userId) => {
    brainstormParticipantsMutation.mutate(userId, {
      onSuccess: () => {
        toast.success('Participant added');
      },
      onError: () => {
        toast.error('Could not update participants');
      },
    });
  };

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            onClick={() => {
              updateParticipants(userId);
            }}
            disabled={brainstormParticipantsMutation.isPending}
            isLoading={brainstormParticipantsMutation.isPending}
            svgOnly
            variant="ghost"
            prefix={
              <ButtonIcon>
                <IconUse id="add-fill" />
              </ButtonIcon>
            }
            aria-label="Add to Game Plan"
          />
        </TooltipTrigger>
        <TooltipContent side="bottom">Add to Game Plan</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}

const useInfinitePartnersFilter = createUseInfiniteFilter('partners');
const useInfiniteCohortsFilter = createUseInfiniteFilter('cohorts');
function useInfiniteExoStaffFilter(params) {
  return useInfiniteStaffFilter({ ...params, role: 'exo' });
}

function useInfiniteLocationsFilter(params) {
  return createInfiniteQuery({
    fetchFn: async (params, { signal }) => {
      const response = await api.get(`/locations`, {
        ...params,
        signal,
      });

      return response.data;
    },
    queryKeyFn: (params) => LOCATIONS_KEYS.filter(params),
  })({
    params: {
      ...params,
      filtered: 'participant',
    },
  });
}

const totalCompensationFilters = totalCompensationOptions.map((option) => ({
  id: String(option.value),
  name: option.label,
}));
const dateAvailableFilters = getDateAvailableOptions().map((option) => ({
  id: String(option.value),
  name: option.label,
}));
const experienceFilters = experienceOptions.map((option) => ({
  id: String(option.value),
  name: option.label,
}));

AddParticipantsTable.propTypes = {
  id: PropTypes.string,
  removed: PropTypes.object,
};

function useUpdateBrainstormParticipants() {
  const queryClient = useQueryClient();
  const { jobId } = useParams();
  /** @param {string} userId */
  async function addParticipantToBrainstorm(userId) {
    const response = await api.post(`/jobs/${jobId}/brainstorm`, {
      participants: [userId],
    });

    return response.data;
  }
  return useMutation({
    mutationFn: addParticipantToBrainstorm,
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries({
          queryKey: JOBS_KEYS.detail(jobId),
        }),
        queryClient.invalidateQueries({
          queryKey: PIPELINE_QUERIES.all(),
        }),
      ]);
    },
  });
}

export default AddParticipantsTable;
