import { useBodyScrollLock } from 'hooks/useBodyScrollLock';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import screen from 'superior-mq';
import { bp } from '../styles/helpers';

const Content = styled.div`
  position: relative;
  width: 100%;
  max-width: ${(props) => (props.large ? 896 : 540)}px;
  min-width: 480px;
  border: 0;
  outline: none;
  background-color: var(--white);
  padding: 40px 40px 40px 28px;
  border-left: 12px solid
    ${(props) =>
      props.error ? 'var(--error-red-dark)' : 'var(--ultrabright-green)'};
  border-radius: 6px;
  overflow-y: auto;

  ${screen.below(
    bp.mobileSm,
    `
    padding: 16px;
    border: 0;
    max-width: 100%;
    min-width: 0;
  `
  )}
`;

/** Modal component */
const GullsModal = ({
  isOpen,
  onRequestClose = () => {},
  className,
  appElement = '#root',
  large,
  ...props
}) => {
  ReactModal.setAppElement(appElement);
  const modalRef = useBodyScrollLock(!!isOpen);

  return (
    <>
      <ReactModal
        isOpen={!!isOpen}
        onRequestClose={onRequestClose}
        overlayClassName="Overlay"
        className="Modal"
        portalClassName={className}
        ref={modalRef}
        {...props}
      >
        <Content large={large}>{props.children}</Content>
      </ReactModal>
    </>
  );
};

const ResponsiveModal = styled(GullsModal)`
  .Overlay {
    overflow-y: scroll;
    z-index: 100;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 48px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    ${screen.below(
      bp.desktopSm,
      `
      padding: 0 60px;
    `
    )}

    ${screen.below(
      bp.mobileSm,
      `
      padding: 16px;
    `
    )}
  }

  .Modal {
    display: flex;
    justify-content: center;
    align-content: stretch;
    max-height: 100%;
    width: 100%;
    max-width: 896px;
  }
`;

ResponsiveModal.propTypes = {
  isOpen: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onRequestClose: PropTypes.func,
  className: PropTypes.string,
  appElement: PropTypes.string,
};

export default ResponsiveModal;
