import { Icon, IconUse } from 'components/ds/icons/Icon';
import { Logo } from 'components/ds/Logo';
import { Logomark } from 'components/ds/Logomark';
import { useSidebarNavState } from 'components/layout-v2/context/SidebarNavContext';
import { navAction } from 'components/layout-v2/styles/layout.styles';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

interface SidebarNavProps {
  children?: React.ReactNode;
}
export function SidebarNav({ children }: SidebarNavProps) {
  const { isOpen, setIsOpen } = useSidebarNavState() ?? {};
  const { container } = navAction({
    state: isOpen ? 'expanded' : 'collapsed',
  });

  return (
    <div className="flex h-screen w-full flex-col border-r border-solid border-ds-stroke-tertiary bg-ds-bg-foundation">
      <header
        className={twMerge(
          'flex flex-wrap items-center justify-between gap-5',
          isOpen
            ? 'h-[var(--siteheader-height)] px-6'
            : 'justify-center px-5 py-6'
        )}
      >
        <Link
          to="/"
          aria-label="Go to homepage"
          className={twMerge(
            'block w-full',
            isOpen ? 'max-w-[150px]' : 'max-w-[30px]'
          )}
        >
          {isOpen ? (
            <Logo aria-hidden theme="dark" />
          ) : (
            <Logomark aria-hidden />
          )}
        </Link>
        <button
          className={container({
            theme: 'outlined',
            state: 'collapsed',
            className:
              'hidden h-10 w-10 place-items-center [@media(min-width:1281px)]:grid',
          })}
          aria-label={`${isOpen ? 'Collapse' : 'Expand'} Sidebar Navigation`}
          onClick={() => setIsOpen?.((prev) => !prev)}
        >
          <Icon className="text-ds-icon-primary" aria-hidden>
            <IconUse
              id={isOpen ? 'sidebar-fold-line' : 'sidebar-unfold-line'}
            />
          </Icon>
        </button>
      </header>
      <div className="flex min-h-0 flex-1">{children}</div>
    </div>
  );
}
