import * as React from 'react';

interface ExternalLinkProps extends React.ComponentPropsWithoutRef<'a'> {
  href: string;
  target?: string;
}

const ExternalLink = React.forwardRef<HTMLAnchorElement, ExternalLinkProps>(
  ({ href, target = '_blank', children, ...rest }, ref) => {
    // Check if the href contains a protocol, if not, add https://
    const formattedHref = href.match(/^(https?:)?\/\//)
      ? href
      : `https://${href}`;

    return (
      <a
        href={formattedHref}
        target={target}
        rel={target === '_blank' ? 'noreferrer noopener' : undefined}
        ref={ref}
        {...rest}
      >
        {children}
      </a>
    );
  }
);

ExternalLink.displayName = 'ExternalLink';

export default ExternalLink;
