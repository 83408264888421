import { DevTool } from '@hookform/devtools';
import { zodResolver } from '@hookform/resolvers/zod';
import { useSuspenseQuery } from '@tanstack/react-query';
import { PARTICIPANT_QUERIES } from 'api/participants';
import { STAFF_QUERY_OPTIONS } from 'api/staff';
import { AsyncComboboxField } from 'components/ComboboxField/AsyncComboboxField';
import { Button } from 'components/ds/Button';
import { Checkbox } from 'components/ds/Checkbox';

import { USER_ADMIT_DECISIONS } from 'api/user/user.constants';
import { Badge } from 'components/ds/Badge';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'components/ds/Form';
import { Input } from 'components/ds/Input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ds/Select';
import { SheetClose } from 'components/ds/Sheet';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { phonePattern, totalCompensationOptions } from 'util/forms';
import { idFromObject } from 'util/idFromObj';
import { z } from 'zod';
import { useActivateParticipantAccount } from '../api/participant';

interface ActivateParticipantAccountFormProps {
  participantId: string;
  onSuccessfulSubmit?: () => void;
}

export function ActivateParticipantAccountForm({
  onSuccessfulSubmit,
  participantId,
}: ActivateParticipantAccountFormProps) {
  const { data: participant } = useSuspenseQuery({
    ...PARTICIPANT_QUERIES.participant(participantId),
  });
  const mutation = useActivateParticipantAccount(participantId);

  const form = useForm<ActivateParticipantFormInput>({
    mode: 'onSubmit',
    resolver: zodResolver(ActivateParticipantFormSchema),
    defaultValues: {
      ...FORM_DEFAULT_VALUES,
      ...(participant
        ? {
            firstName: participant.firstName,
            lastName: participant.lastName,
            email: participant.email,
            originalResume: participant.userProfile?.originalResume ?? '',
            linkedin: participant.userProfile?.linkedin ?? '',
            dateAvailable: participant.userProfile?.dateAvailable ?? '',
            phone: participant.phone ?? '',
            totalCompensation: participant.userProfile?.totalCompensation ?? '',
          }
        : {}),
      ...(participant?.exo
        ? {
            exoId: {
              id: participant.exo.id,
              name: `${participant.exo.firstName} ${participant.exo.lastName}`,
            },
          }
        : {}),
      ...(participant?.userProfile?.interviewer
        ? {
            interviewerId: {
              id: participant.userProfile.interviewer.id,
              name: `${participant.userProfile.interviewer.firstName} ${participant?.userProfile.interviewer.lastName}`,
            },
          }
        : {}),
    },
    shouldUnregister: true,
  });
  const {
    control,
    handleSubmit,
    formState: { isValidating },
  } = form;

  function onSubmit(data: ActivateParticipantFormOutput) {
    const {
      originalResume,
      linkedin,
      interviewerId,
      dateAvailable,
      totalCompensation,
      ...rest
    } = data;

    mutation.mutate(
      {
        profile: {
          originalResume,
          linkedin,
          interviewerId,
          dateAvailable,
          totalCompensation,
        },
        ...rest,
      },
      {
        onSuccess: () => {
          onSuccessfulSubmit?.();
          toast.success('Email sent');
        },
        onError: () => {
          toast.error('Failed to send email');
        },
      }
    );
  }

  const isLoading = mutation.isPending || isValidating;

  return (
    <Form {...form}>
      <div className="stack-y-6">
        <form
          id="activate-participant-account-form"
          onSubmit={handleSubmit((data) => {
            onSubmit(data as unknown as ActivateParticipantFormOutput);
          })}
          className="grid grid-cols-[repeat(auto-fill,minmax(280px,1fr))] gap-6"
        >
          <DevTool control={control} placement="top-left" />
          <FormField
            control={control}
            name="firstName"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>First Name</FormLabel>
                  <FormControl>
                    <Input {...field} type="text" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
          <FormField
            control={control}
            name="lastName"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>Last Name</FormLabel>
                  <FormControl>
                    <Input {...field} type="text" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
          <FormField
            control={control}
            name="email"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input {...field} type="text" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
          <FormField
            control={control}
            name="linkedin"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>LinkedIn URL</FormLabel>
                  <FormControl>
                    <Input {...field} type="text" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
          <FormField
            control={control}
            name="originalResume"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>Original Resume URL</FormLabel>
                  <FormControl>
                    <Input {...field} type="text" maxLength={255} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />

          <FormField
            control={control}
            name="totalCompensation"
            render={({ field: { value, onChange, ...rest } }) => {
              return (
                <FormItem>
                  <FormLabel>Total Compensation</FormLabel>
                  <FormControl>
                    <Select onValueChange={onChange} defaultValue={value}>
                      <FormControl>
                        <SelectTrigger {...rest}>
                          <SelectValue placeholder="Select Total Comepnsation" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {Object.values(totalCompensationOptions)?.map(
                          ({ label, value }) => {
                            return (
                              <SelectItem key={value} value={value}>
                                {label}
                              </SelectItem>
                            );
                          }
                        )}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />

          <FormField
            control={control}
            name="dateAvailable"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>Start Date</FormLabel>
                  <FormControl>
                    <Input type="date" {...field} placeholder="mm/dd/yyyy" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />

          <FormField
            control={control}
            name="phone"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>Phone #</FormLabel>
                  <FormControl>
                    <Input {...field} type="tel" maxLength={255} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />

          <FormField
            control={control}
            name="exoId"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>Participant Manager</FormLabel>
                  <FormControl>
                    <AsyncComboboxField
                      {...field}
                      displayName="Participant Manager"
                      infiniteQueryOptions={
                        STAFF_QUERY_OPTIONS.infiniteStaffList
                      }
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />

          <FormField
            control={control}
            name="interviewerId"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>Interviewer</FormLabel>
                  <FormControl>
                    <AsyncComboboxField
                      {...field}
                      displayName="Interviewer"
                      infiniteQueryOptions={
                        STAFF_QUERY_OPTIONS.infiniteStaffList
                      }
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />

          <FormField
            control={control}
            name="admitDecision"
            render={({ field: { value, onChange, ...rest } }) => {
              return (
                <FormItem>
                  <FormLabel>Admit Decision</FormLabel>
                  <Select onValueChange={onChange} defaultValue={value}>
                    <FormControl>
                      <SelectTrigger {...rest}>
                        <SelectValue placeholder="Select Admit Decision" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {Object.values(USER_ADMIT_DECISIONS)?.map((item) => {
                        return (
                          <SelectItem key={item} value={item}>
                            {item}
                          </SelectItem>
                        );
                      })}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              );
            }}
          />

          <FormItem>
            <FormLabel>Location(s)</FormLabel>

            <div className="stack-x-1/inline">
              {participant?.userProfile?.locations?.map((location) => (
                <Badge key={location.id}>{location.name}</Badge>
              ))}
            </div>

            <FormDescription>
              Edit the participant to change locations
            </FormDescription>
          </FormItem>

          <FormField
            control={control}
            name="reviewed"
            render={({ field: { value, onChange, ...rest } }) => {
              return (
                <FormItem className="col-span-full rounded-md border border-ds-stroke-tertiary p-4">
                  <div className="stack-x-2">
                    <FormControl>
                      <Checkbox
                        checked={value}
                        onCheckedChange={onChange}
                        {...rest}
                      />
                    </FormControl>
                    <div className="stack-y-1">
                      <FormLabel>Reviewed</FormLabel>
                      <FormDescription className="max-w-prose">
                        I have reviewed that the information above is correct.
                      </FormDescription>
                    </div>
                  </div>
                  <FormMessage className="pl-6" />
                </FormItem>
              );
            }}
          />
        </form>
        <div>
          <div className="justify-end stack-x-4">
            <SheetClose asChild>
              <Button variant="secondary">Cancel</Button>
            </SheetClose>
            <Button
              form="activate-participant-account-form"
              disabled={isLoading}
              isLoading={isLoading}
              type="submit"
            >
              Activate
            </Button>
          </div>
        </div>
      </div>
    </Form>
  );
}

const ActivateParticipantFormSchema = z
  .object({
    firstName: z.string().min(1, {
      message: 'Please enter a first name',
    }),
    lastName: z.string().min(1, {
      message: 'Please enter a last name',
    }),
    email: z
      .string()
      .email()
      .refine(
        (val) => !val.endsWith('example.org') && !val.endsWith('example.com'),
        {
          message: 'Email address cannot be an example or placeholder.',
        }
      ),
    exoId: z
      .object(
        {
          id: z.coerce.number(),
          name: z.string(),
        },
        {
          errorMap: () => ({ message: 'Please select a participant manager' }),
        }
      )
      .transform(idFromObject),
    interviewerId: z
      .object(
        {
          id: z.coerce.number(),
          name: z.string(),
        },
        {
          errorMap: () => ({ message: 'Please select a interviewer' }),
        }
      )
      .transform(idFromObject)
      .optional(),
    originalResume: z.string().url().max(255),
    admitDecision: z.string({
      errorMap: () => ({ message: 'Please select an admit decision' }),
    }),
    dateAvailable: z.string(),
    totalCompensation: z.string({
      errorMap: () => ({ message: 'Please select a total compensation range' }),
    }),
    phone: z
      .string()
      .regex(phonePattern, 'Phone number format must match (123) 456-7890'),
    linkedin: z.string().url(),
    reviewed: z.literal(true, {
      errorMap: () => ({
        message: 'Please confirm that the information is correct',
      }),
    }),
  })
  .superRefine((data, ctx) => {
    if (
      data.admitDecision === USER_ADMIT_DECISIONS['Fast Track'] &&
      !data.interviewerId
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Interviewer is required for the Fast Track decision.',
        path: ['interviewerId'],
      });
    }
  });

type ActivateParticipantFormInput = z.input<
  typeof ActivateParticipantFormSchema
>;
type ActivateParticipantFormOutput = z.output<
  typeof ActivateParticipantFormSchema
>;

const FORM_DEFAULT_VALUES = {
  email: '',
  firstName: '',
  lastName: '',
  originalResume: '',
  linkedin: '',
} satisfies Partial<ActivateParticipantFormInput>;
