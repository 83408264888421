import { rem, size } from 'polished';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import styled from 'styled-components';
import screen from 'superior-mq';
import { bp, hover } from '../styles/helpers';
import Combo from './forms/Combo';
import { ReactComponent as Arrow } from './svg/arrow.svg';
import UnstyledButton from './UnstyledButton';

const Wrap = styled.div`
  display: flex;
  align-items: center;
  font-size: ${rem(18)};
  margin-bottom: 20px;

  ${screen.below(
    bp.portrait,
    `
    justify-content: flex-start;
    margin-left: 10px;
  `
  )}

  ${screen.below(
    bp.mobileMid,
    `
    flex-direction: column;
    align-items: flex-start;
  `
  )}
`;

const Links = styled.ul`
  padding-left: 0;
  margin: 5px 0;
  list-style-type: none;
  text-align: right;

  ${screen.below(
    bp.portrait,
    `
    text-align: left;
  `
  )}

  ${screen.below(
    bp.mobileMid,
    `
    margin-top: 30px;
  `
  )}
`;

const LinkWrap = styled.li`
  display: inline-block;

  &:last-child {
    margin-right: 0;
  }

  button {
    display: flex;
    align-items: center;
    padding: 4px 12px;
    position: relative;
    cursor: pointer;
    color: ${(props) =>
      props.isArrow ? 'var(--primary-green)' : 'var(--dark-text)'};
    font-weight: 600;
    line-height: 32px;

    &[aria-current='page']::before {
      ${size(32)}
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      border: 2px solid var(--orange);
      border-radius: 50%;
      transform: translate(-50%, -50%);
    }

    ${(props) =>
      hover(`
      color: ${props.isArrow ? 'var(--dark-green)' : 'var(--primary-green)'};
    `)}
  }

  ${screen.below(
    bp.mobileMid,
    `
    &:first-child {
      margin-left: 0;
    }
  `
  )}
`;

const PageArrow = styled(Arrow)`
  vertical-align: text-bottom;
  width: 9px;
`;

const RightArrow = styled(PageArrow)`
  transform: scaleX(-1);
`;

const RowCountSelect = styled(Combo)`
  margin: 0 auto 0 0;
  display: flex;
  align-items: center;

  label {
    display: inline;
    margin-right: 20px;
  }

  input {
    width: 47px;

    ${screen.below(
      bp.mobileMid,
      `
      font-size: ${rem(16)};
    `
    )}
  }
`;

const paginationRowsPerPageOptions = [5, 10, 20, 50, 100];

const PageLink = ({ pageNum, isCurrentPage, onChangePage, ...props }) => (
  <LinkWrap isCurrent={isCurrentPage} {...props}>
    <UnstyledButton
      onClick={() => onChangePage(pageNum)}
      aria-label={`Page ${pageNum}`}
      aria-current={isCurrentPage ? 'page' : null}
    >
      <span>{pageNum}</span>
    </UnstyledButton>
  </LinkWrap>
);

/**
 * Pretty standard pagination component. Params are all standard/self
 * explainaitory. Worth noting is that if onChangeRowsPerPage is falsy, The drop
 * down to change it is not rendered. *
 */
const Pagination = ({
  rowCount = 0,
  rowsPerPage = 10,
  currentPage,
  onChangePage,
  onChangeRowsPerPage,
  actionButton,
  ...props
}) => {
  const totalPages = Math.ceil(rowCount / rowsPerPage);
  const showPerPage = !!onChangeRowsPerPage;

  const handleRowsPerPage = useCallback(
    (val) => {
      onChangeRowsPerPage(Number(val.value), currentPage);
    },
    [currentPage, onChangeRowsPerPage]
  );

  const pages = [];

  let nextExtent = totalPages <= 3 ? totalPages : 3;
  let backExtent = 1;
  let max = nextExtent;

  if (totalPages > 3 && currentPage >= 2) {
    nextExtent = 1;
    backExtent = currentPage - 1;

    if (totalPages - currentPage < 3) {
      nextExtent = totalPages - currentPage;
      backExtent = currentPage - Math.abs(nextExtent - 3);
    }

    max = currentPage + nextExtent;
  }

  for (let i = backExtent; i <= max; i++) {
    if (i === 1 || i === totalPages) continue;
    pages.push(
      <PageLink
        key={`pagination-page-${i}`}
        pageNum={i}
        isCurrentPage={currentPage === i}
        onChangePage={onChangePage}
      />
    );
  }

  const selectOptions = paginationRowsPerPageOptions.map((num) => ({
    label: num.toString(),
    value: num,
  }));

  const getSelectValue = (val) => selectOptions.find((el) => el.value === val);

  if (rowCount < 6 && !actionButton) return null;
  if (rowCount < 6 && actionButton) {
    return (
      <Wrap
        {...props}
        style={{ justifyContent: 'flex-start', ...props?.style }}
      >
        {actionButton}
      </Wrap>
    );
  }

  return (
    <Wrap
      {...props}
      style={{
        justifyContent: actionButton ? 'space-between' : 'flex-end',
        ...props?.style,
      }}
    >
      {!showPerPage && actionButton}
      {showPerPage && (
        <RowCountSelect
          name="perpage-select"
          label="Per page"
          options={selectOptions}
          value={getSelectValue(rowsPerPage)}
          onChange={handleRowsPerPage}
          small
          placeMenuAbove
        />
      )}

      {
        // Only show pagination if there are 2 or more pages
        totalPages > 1 ? (
          <Links>
            {currentPage > 1 && (
              <LinkWrap isArrow>
                <UnstyledButton
                  aria-label="Previous Page"
                  onClick={() => onChangePage(currentPage - 1)}
                >
                  <RightArrow />
                </UnstyledButton>
              </LinkWrap>
            )}

            <PageLink
              pageNum={1}
              isCurrentPage={currentPage === 1}
              onChangePage={onChangePage}
              style={{ marginRight: '4px' }}
            />

            {currentPage > 3 ? <LinkWrap>...</LinkWrap> : null}

            {pages}

            {currentPage < totalPages - 2 ? <LinkWrap>...</LinkWrap> : null}

            {totalPages > 1 ? (
              <PageLink
                pageNum={totalPages}
                isCurrentPage={currentPage === totalPages}
                onChangePage={onChangePage}
              />
            ) : null}

            {currentPage < totalPages && (
              <LinkWrap isArrow>
                <UnstyledButton
                  onClick={() => onChangePage(currentPage + 1)}
                  aria-label="Next Page"
                >
                  <PageArrow />
                </UnstyledButton>
              </LinkWrap>
            )}
          </Links>
        ) : null
      }
    </Wrap>
  );
};

Pagination.propTypes = {
  rowsPerPage: PropTypes.number.isRequired,
  rowCount: PropTypes.number,
  onChangePage: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  currentPage: PropTypes.number.isRequired,
};

export default Pagination;
