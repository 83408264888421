import * as React from 'react';

export const Logomark = React.forwardRef<
  SVGSVGElement,
  React.ComponentPropsWithoutRef<'svg'>
>((props, ref) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 45"
      ref={ref}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.58 7.362a22.644 22.644 0 0 1 4.646 2.99c2.288-2.805 5.958-4.727 9.785-4.727h1.117c6.688 0 12.287 5.46 12.287 12.197v18.19c0 1.858-1.495 3.363-3.34 3.363H33.124c-2.457 0-4.449-2.006-4.449-4.481v-8.987c0-5.61-4.515-10.157-10.085-10.157l-.468.01C11.997 15.76 7.5 20.876 7.5 27.044v6.842c0 1.789 1.44 3.239 3.216 3.239h10.188V31.5h-5.662c-1.06 0-2.157-1.173-2.157-2.242v-2.245c0-2.922 2.346-5.638 5.24-5.638 2.63 0 4.819 1.884 4.819 4.54l-.006 10.248c0 4.88 3.929 8.837 8.775 8.837H54.36c3.115 0 5.64-2.543 5.64-5.68V17.903C60 8.016 51.945 0 42.128 0H41.01C35.106 0 29.84 2.9 26.58 7.362Z"
        fill="#00A5A3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.862 29.259v-2.313c0-1.608-.195-3.17-.56-4.664-1.652-6.776-6.824-12.143-13.433-13.99a18.824 18.824 0 0 0-5.076-.695C7.966 7.597 0 15.681 0 25.652v11.305C0 41.4 3.549 45 7.493 45h17.765c-1.865-1.035-3.691-3.39-3.983-5.667H10.048c-2.465 0-4.463-2.027-4.463-4.529v-7.836c0-7.568 6.047-13.704 13.505-13.704.905 0 1.786.101 2.635.291 5.463 1.222 9.552 6.164 9.552 12.075v8.104c0 1.84 1.47 3.332 3.283 3.332h14.55c1.872 0 3.39-1.54 3.39-3.44V18.33c0-5.589-4.21-10.179-9.588-10.685.002.015-.002-.016 0 0a14.722 14.722 0 0 0-2.048 0c.002-.015-.001.017 0 0-2.953.279-5.765 1.87-7.536 4.11a23.04 23.04 0 0 1 3.744 5.156c.57-1.945 2.25-3.404 4.296-3.619l.02-.002a4.903 4.903 0 0 1 .742-.02l.02.002c.08.004.16.01.238.018l.02.002c2.532.266 4.507 2.438 4.507 5.077v10.795c0 1.234-.986 2.235-2.203 2.235h-5.74c-1.166 0-2.11-.958-2.11-2.14Z"
        fill="#FA8053"
      />
    </svg>
  );
});
Logomark.displayName = 'Logomark';
