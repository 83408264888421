/** Custom cell renderer for items in the data table */
import { DataCell } from 'components/table';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ReactComponent as NotVisible } from '../svg/eye-hidden.svg';
import { ReactComponent as Visible } from '../svg/eye-visible.svg';

const IconWrap = styled.span`
  color: var(--link-color, var(--primary-green));
`;

const VisibleCell = ({ name, value }) => {
  return (
    <DataCell name={name}>
      <IconWrap
        style={{
          '--link-color': value ? 'var(--orange)' : 'var(--primary-green)',
        }}
      >
        {value ? <Visible /> : <NotVisible />}
      </IconWrap>
    </DataCell>
  );
};

VisibleCell.propTypes = {
  name: PropTypes.string,
  value: PropTypes.bool,
};

export default VisibleCell;
