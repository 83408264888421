import * as PopoverPrimitive from '@radix-ui/react-popover';
import { Button } from 'components/ds/Button';
import { Filter } from 'components/filters-new/types/filters';
import * as React from 'react';

/** FilterList components */
interface FilterListProps {
  children: React.ReactNode;
}
export function FilterList({ children }: FilterListProps) {
  return (
    <ul className="m-0 max-h-[300px] overflow-y-auto overflow-x-hidden p-1">
      {children}
    </ul>
  );
}

interface FilterListItemProps {
  id: string;
  text: string;
  children: React.ReactNode;
}
export function FilterListItem({ id, text, children }: FilterListItemProps) {
  return (
    <li className="block text-left">
      <label
        htmlFor={id}
        className="group flex cursor-default items-center rounded-md p-2 outline-none transition-colors stack-x-2 hover:bg-ds-bg-weaker disabled:cursor-not-allowed disabled:opacity-50"
      >
        {children}
        <span className="select-none font-primary text-sm text-ds-text-secondary transition-colors group-hover:text-ds-text-primary peer-data-[state=checked]:text-ds-text-primary">
          {text}
        </span>
      </label>
    </li>
  );
}

export function FilterListEmpty({ isFetching }: { isFetching: boolean }) {
  return (
    <li className="block text-left">
      <span className="grid place-items-center py-6 text-center text-sm text-ds-text-primary">
        {isFetching ? 'Loading...' : 'No results'}
      </span>
    </li>
  );
}
/** FilterActions components */
export function FilterActions({ children }: { children: React.ReactNode }) {
  return (
    <div className="border-x-0 border-b-0 border-t border-solid border-gray-200 p-2 stack-x-2">
      {children}
    </div>
  );
}

interface FilterActionCancelProps {
  onCancel: () => void;
}
export function FilterActionCancel({ onCancel }: FilterActionCancelProps) {
  return (
    <PopoverPrimitive.Close asChild>
      <Button
        onClick={() => {
          onCancel();
        }}
        variant="secondary"
        className="flex-1 shadow-none"
      >
        Cancel
      </Button>
    </PopoverPrimitive.Close>
  );
}

interface FilterActionApplyProps {
  onApply: () => void;
}
export function FilterActionApply({ onApply }: FilterActionApplyProps) {
  return (
    <PopoverPrimitive.Close asChild>
      <Button
        onClick={() => {
          onApply();
        }}
        className="flex-1 shadow-none"
      >
        Apply
      </Button>
    </PopoverPrimitive.Close>
  );
}

interface FilterClearButtonProps {
  onClear: () => void;
}
export function FilterClearButton({ onClear }: FilterClearButtonProps) {
  return (
    <div className="border-t border-ds-stroke-tertiary p-2 ">
      <PopoverPrimitive.Close asChild>
        <Button
          variant="outline"
          onClick={() => {
            onClear();
          }}
          className="w-full shadow-none"
        >
          Clear filters
        </Button>
      </PopoverPrimitive.Close>
    </div>
  );
}

interface FilterSelectionPreviewProps {
  filters?: Filter[];
}
export function FilterSelectionPreview({
  filters,
}: FilterSelectionPreviewProps) {
  if (!filters || filters.length === 0) return null;

  if (filters.length <= 2) {
    return (
      <span className="stack-x-1/inline">
        {filters.map((filter) => (
          <span
            key={filter.id}
            className="max-w-[100px] truncate rounded-md bg-ds-primary-dark px-1.5 py-0.5 text-xs font-normal text-ds-neutral-0 transition-colors group-hover/trigger:opacity-90"
          >
            {filter.name}
          </span>
        ))}
      </span>
    );
  }

  return (
    <span className="rounded-md bg-ds-primary-dark px-1.5 py-0.5 text-xs font-normal text-ds-neutral-0">
      {filters.length} selected
    </span>
  );
}
