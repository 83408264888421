import { Icon, IconUse } from 'components/ds/icons/Icon';
import { IconLoader } from 'components/icons/IconLoader/IconLoader';
import * as React from 'react';
import { mergeRefs } from 'react-merge-refs';
import { twMerge } from 'tailwind-merge';

interface FilterSearchInputProps
  extends Omit<React.ComponentPropsWithoutRef<'input'>, 'value'> {
  onClearInput?: () => void;
  loading?: boolean;
  value?: string;
}
export const FilterSearchInput = React.forwardRef<
  HTMLInputElement,
  FilterSearchInputProps
>(({ className, onClearInput, value, loading, ...rest }, ref) => {
  const localRef = React.useRef<HTMLInputElement>(null);
  const [isClearButtonVisible, setIsClearButtonVisible] = React.useState(false);
  const [previousValue, setPreviousValue] = React.useState(value);

  if (previousValue !== value) {
    setPreviousValue(value);
    // whenever the value changes, update the clear button visibility state
    setIsClearButtonVisible(Boolean(value));
  }

  return (
    <div className="flex items-center border-b border-ds-stroke-tertiary">
      <span
        aria-hidden
        className="-mr-3 flex w-auto shrink-0 items-center px-3 text-ds-icon-secondary"
      >
        {!loading ? (
          <Icon className="h-4 w-4">
            <IconUse id="search-line" />
          </Icon>
        ) : (
          <IconLoader animate className="h-4 w-4 text-secondary-green" />
        )}
      </span>
      <input
        {...rest}
        ref={mergeRefs([ref, localRef])}
        value={value}
        type="search"
        className={twMerge(
          'peer flex h-10 w-full border-none bg-transparent px-3 py-1 font-primary text-sm text-ds-text-primary outline-none transition-colors placeholder:text-ds-text-placeholder focus:outline-none disabled:cursor-not-allowed',
          className
        )}
      />
      {isClearButtonVisible && (
        <button
          type="button"
          aria-label="Clear search input"
          className={
            'flex items-center self-stretch border-none bg-transparent px-2 text-ds-icon-secondary transition-colors hover:text-ds-icon-primary'
          }
          onClick={() => {
            onClearInput?.();
            setIsClearButtonVisible(false);
            localRef?.current?.focus();
          }}
        >
          <Icon aria-hidden className="h-4 w-4">
            <IconUse id="close-circle-line" />
          </Icon>
        </button>
      )}
    </div>
  );
});

FilterSearchInput.displayName = 'FilterSearchInput';
