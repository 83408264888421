import PropTypes from 'prop-types';
import styled from 'styled-components';
import makeLimitedList from 'util/makeLimitedList';
import Tag from './Tag';
import UnstyledList from './UnstyledList';

const RemainderItem = styled(UnstyledList.Item)`
  color: var(--orange);
`;

const TagList = ({
  tags = [],
  className,
  limit,
  compact,
  separator = ' |',
}) => {
  if (tags.length < 1) return null;
  const { list, remainder } = makeLimitedList(tags, limit);

  return (
    <UnstyledList className={className}>
      {list.map((tag, i) => (
        <UnstyledList.Item key={tag}>
          {compact ? (
            <>
              {tag}
              {i < list.length - 1 || remainder ? (
                <span
                  style={{
                    ...(!/,/.test(separator)
                      ? { color: 'var(--border-gray)' }
                      : {}),
                  }}
                >
                  {separator}&nbsp;
                </span>
              ) : null}
            </>
          ) : (
            <Tag text={tag} small grayBackground />
          )}
        </UnstyledList.Item>
      ))}
      {remainder ? <RemainderItem>+ {remainder}</RemainderItem> : null}
    </UnstyledList>
  );
};

TagList.propTypes = {
  tags: PropTypes.array,
  className: PropTypes.string,
  limit: PropTypes.number,
  compact: PropTypes.bool,
  separator: PropTypes.string,
};

export default TagList;
