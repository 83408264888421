import { parseFiltersFromUrl } from 'components/filters-new/helpers/parseFiltersFromUrl';
import { mapFilterGroupStateToRecord } from 'components/filters-new/helpers/transformFilters';

export function getFilterParams({
  searchParams,
  key,
  initialFilters = {},
}: {
  searchParams: URLSearchParams;
  key: string;
  initialFilters?: Record<string, { id: string; name: string }[]>;
}) {
  const hasKey = searchParams.has(key);
  const parsedFilters = hasKey ? parseFiltersFromUrl(searchParams, key) : {};
  const filters = {
    ...(hasKey
      ? mapFilterGroupStateToRecord(parsedFilters)
      : mapFilterGroupStateToRecord(initialFilters)),
  };

  return filters;
}
