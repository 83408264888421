import { InfiniteData, UseInfiniteQueryResult } from '@tanstack/react-query';
import { ListLoadMore } from 'components/ListLoadMore';
import { Checkbox } from 'components/ds/Checkbox';
import { Popover, PopoverContent } from 'components/ds/Popover';
import { Filter } from 'components/filters-new/types/filters';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import {
  useFilterSearch,
  useFilters,
  useSelectedFilters,
} from './hooks/filters';
import {
  FilterActionApply,
  FilterActionCancel,
  FilterActions,
  FilterClearButton,
  FilterList,
  FilterListEmpty,
  FilterListItem,
  FilterSelectionPreview,
} from './ui/Filter';
import { FilterPopoverTrigger } from './ui/FilterPopover';
import { FilterSearchInput } from './ui/FilterSearchInput';

// TODO: Hunter - consider adding error states
interface AsyncFilterProps {
  groupId: string;
  label: string;
  useInfiniteFilter: (args: {
    [key: string]: unknown;
  }) => UseInfiniteQueryResult<
    InfiniteData<{ items: Filter[] }, unknown>,
    Error
  >;
  dataTransform?: (
    data: InfiniteData<any, unknown>
  ) => InfiniteData<{ items: { id: number; name: string }[] }, unknown>;
}

export function AsyncFilter({
  groupId,
  label,
  useInfiniteFilter,
  dataTransform,
}: AsyncFilterProps) {
  const { ref, inView } = useInView();
  const {
    selectedFilters,
    handleCheckedChange,
    emptySelectedFilters,
    resetSelectedFilters,
    applySelectedFilters,
  } = useSelectedFilters(groupId);
  const {
    searchFilterParams,
    searchTerm,
    setSearchTerm,
    resetSearchTerm,
    debouncedSearchTerm,
  } = useFilterSearch();
  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } =
    useInfiniteFilter(searchFilterParams);
  const transformedData = React.useMemo(() => {
    return dataTransform && data ? dataTransform(data) : data;
  }, [data, dataTransform]);

  const { initialFiltersFromStore, actions, filters } = useFilters(
    transformedData,
    debouncedSearchTerm,
    groupId
  );

  // Fetch more filters when the user scrolls to the bottom of the list
  React.useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage]);

  return (
    <Popover>
      <FilterPopoverTrigger
        disabled={isLoading}
        status={initialFiltersFromStore.length > 0 ? 'active' : 'inactive'}
        onClear={() => {
          actions.clearFilterGroup({ groupId });
          emptySelectedFilters();
        }}
      >
        <span>{label}</span>
        {initialFiltersFromStore.length > 0 && (
          <FilterSelectionPreview filters={initialFiltersFromStore} />
        )}
      </FilterPopoverTrigger>
      <PopoverContent>
        <FilterSearchInput
          type="search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onClearInput={() => resetSearchTerm()}
          placeholder={`Search ${label.toLowerCase()}...`}
          loading={isLoading}
        />
        <FilterList>
          {filters.length > 0 ? (
            <>
              {filters.map((filter) => {
                const id = `filter-${filter.id}`;

                return (
                  <FilterListItem key={filter.id} id={id} text={filter.name}>
                    <Checkbox
                      className="shadow-none"
                      id={id}
                      checked={selectedFilters.some((f) => f.id === filter.id)}
                      onCheckedChange={(checked) => {
                        handleCheckedChange(
                          {
                            id: filter.id,
                            name: filter.name,
                          },
                          Boolean(checked)
                        );
                      }}
                    />
                  </FilterListItem>
                );
              })}
            </>
          ) : (
            <FilterListEmpty isFetching={isLoading} />
          )}
          {hasNextPage && (
            <ListLoadMore loading={isFetchingNextPage} ref={ref} />
          )}
        </FilterList>
        <FilterActions>
          <FilterActionCancel
            onCancel={() => {
              resetSelectedFilters();
              resetSearchTerm();
            }}
          />
          <FilterActionApply
            onApply={() => {
              applySelectedFilters();
              resetSearchTerm();
            }}
          />
        </FilterActions>
        {selectedFilters.length > 0 && (
          <FilterClearButton
            onClear={() => {
              actions.clearFilterGroup({ groupId });
              emptySelectedFilters();
              resetSearchTerm();
            }}
          />
        )}
      </PopoverContent>
    </Popover>
  );
}
