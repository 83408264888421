import { parseFiltersFromUrl } from 'components/filters-new/helpers/parseFiltersFromUrl';
import {
  Filter,
  FilterGroupState,
  GroupId,
} from 'components/filters-new/types/filters';
import { useComponentSearchParams } from 'context/ComponentSearchParams';
import { omit } from 'lodash';
import { useObservableCallback, useSubscription } from 'observable-hooks';
import * as React from 'react';
import type { Observable } from 'rxjs';
import { parseSearchParams, stringify } from 'util/searchParams.helpers';

type TFilterGroupContext =
  | {
      filters: FilterGroupState;
      stringifiedFilters: string;
      actions: {
        setFilters: (args: { groupId: GroupId; filters: Filter[] }) => void;
        clearFilterGroup: (args: { groupId: GroupId }) => void;
        clearFilters: () => void;
      };
      events: {
        filterChanged$: Observable<unknown>;
        clearChildren$: Observable<void>;
      };
    }
  | undefined;

const FilterGroupContext = React.createContext<TFilterGroupContext>(undefined);

export function useFilterGroup({
  onFilterChanged,
}: {
  onFilterChanged?: () => void;
} = {}) {
  const context = React.useContext(FilterGroupContext);
  if (context === undefined) {
    throw new Error('useFilterGroup must be used within a FilterGroup');
  }

  useSubscription(context.events.filterChanged$, () => {
    onFilterChanged?.();
  });

  return context;
}

interface FilterGroupProviderProps {
  children: React.ReactNode;
}
export function FilterGroupProvider({ children }: FilterGroupProviderProps) {
  const { params, paramKey } = useComponentSearchParams();
  const [searchParams, setSearchParams] = params;
  const [clearChildFilters, clearChildren$] = useObservableCallback();
  const [changeFilter, filterChanged$] = useObservableCallback();

  const filters = React.useMemo(() => {
    return parseFiltersFromUrl(searchParams, paramKey);
  }, [searchParams, paramKey]);

  /**
   * We want to re-stringify an memoize the filters _that have been parsed and
   * validated_ from the URL and expose it to the context. This is likely to be
   * used in a memoized `queryParam` constant that is passed to a `react-query`
   * hook.
   */
  const stringifiedFilters = React.useMemo(() => {
    return stringify(filters);
  }, [filters]);

  const actions = React.useMemo(
    () => ({
      setFilters: (args: { groupId: GroupId; filters: Filter[] }) => {
        setSearchParams(
          (prev) => {
            const parsed = parseSearchParams(prev, paramKey);

            const newState = {
              ...parsed,
              [args.groupId]: args.filters,
            };

            prev.set(paramKey, stringify(newState));

            return prev;
          },
          { preventScrollReset: true }
        );

        changeFilter();
      },
      clearFilterGroup: (args: { groupId: GroupId }) => {
        setSearchParams(
          (prev) => {
            const parsed = parseSearchParams(prev, paramKey);

            const newState = {
              ...parsed,
              [args.groupId]: [],
            };

            prev.set(paramKey, stringify(newState));

            return prev;
          },
          { preventScrollReset: true }
        );

        changeFilter();
      },
      clearFilters: () => {
        setSearchParams(
          (prev) => {
            const currentSearchParams = parseSearchParams(prev, paramKey);

            const filterKeys = Object.keys(filters);

            const filteredSearchParams = omit(currentSearchParams, filterKeys);

            prev.set(paramKey, stringify(filteredSearchParams));

            return prev;
          },
          { preventScrollReset: true }
        );

        changeFilter();
        clearChildFilters();
      },
    }),
    [clearChildFilters, changeFilter, paramKey, setSearchParams, filters]
  );
  const value = React.useMemo(
    () => ({
      filters,
      stringifiedFilters,
      actions,
      events: {
        filterChanged$,
        clearChildren$,
      },
    }),
    [filters, stringifiedFilters, actions, filterChanged$, clearChildren$]
  );

  return (
    <FilterGroupContext.Provider value={value}>
      {children}
    </FilterGroupContext.Provider>
  );
}
