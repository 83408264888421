export const statusColors = {
  // Auto.
  'Qualified - Offer': 'greenWhiteText',
  'Qualified - Multi-threaded': 'greenWhiteText',
  'Qualified - Close to Finish': 'greenWhiteText',
  'Need to Multi-thread': 'darkRedWhiteText',
  'Company-Pinged': 'yellowWhiteText',
  New: 'blueWhiteText',
  // Manual.
  Evergreen: 'greenDarkText',
  Hold: 'lightOrangeDarkText',
  Backfill: 'greenDarkText',
  'No Backfill': 'redWhiteText',
  'Waiting to Hear': 'yellowDarkText',
  'Hired - Partner': 'greenDarkText',
  'Hired - Non-Partner': 'greenDarkText',
  'Red Flag': 'lightRedDarkText',
  Unsubscribed: 'redDarkText',
  Exclude: 'redDarkText',
  'Top 5': 'purpleDarkText',
  'CS Sprint': 'tealDarkText',
  'Tier 1': 'tier1DarkText',
  'Tier 2': 'tier2DarkText',
  'Tier 3': 'tier3DarkText',
  Ready: 'greenWhiteText',
  'Not Ready': 'lightRedDarkText',
};

export const interviewStatusColors = {
  New: 'greenWhiteText',
  'Heavily Recruited': 'heavilyRecruited',
  Interviewing: 'interviewing',
  'Early in Search': 'earlyInSearch',
};
