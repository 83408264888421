import { rem } from 'polished';
import styled from 'styled-components';

const BasicTitle = styled.span`
  font-weight: 500;
  font-size: ${rem(12)};
  display: block;
  flex: 0 0 100%;

  ${(props) =>
    props.$orange &&
    `
    color: var(--orange);
  `}

  ${(props) =>
    props.$red &&
    `
    color: var(--error-red);
  `}

  ${(props) =>
    props.$green &&
    `
    color: var(--off-green);
  `}
`;

export default BasicTitle;
