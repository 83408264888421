import { infiniteQueryOptions } from '@tanstack/react-query';
import { Params } from 'api/types/params';
import api from 'dataService/api';
import { PaginatedResponse } from 'types/pagination.types';
import { createInfiniteQuery } from 'util/createInfiniteQuery';

const SKILLS_KEYS = {
  all: ['skills'] as const,
  filter: (params: Params) => [...SKILLS_KEYS.all, 'filter', params] as const,
  list: (params: Params) => [...SKILLS_KEYS.all, 'list', params] as const,
};

export function useInfiniteSkillsFilter(params: { [key: string]: unknown }) {
  return createInfiniteQuery({
    fetchFn: async (params, { signal }) => {
      const response = await api.get(`/skills`, {
        ...params,
        signal,
      });

      return response.data;
    },
    queryKeyFn: (params) => SKILLS_KEYS.filter(params),
  })({
    params,
  });
}

export const SKILLS_QUERY_OPTIONS = {
  infiniteSkillsList: (params: { [key: string]: unknown }) => {
    const { limit = 20 } = params ?? {};

    return infiniteQueryOptions({
      queryFn: async ({ pageParam = 1, signal }) => {
        const response = await api.get<
          PaginatedResponse<{
            id: number;
            name: string;
          }>
        >(`/skills`, {
          params: {
            ...params,
            limit,
            skip: (pageParam - 1) * (limit as number),
          },
          signal,
        });

        return response.data;
      },
      queryKey: SKILLS_KEYS.list({
        params: {
          ...params,
          limit,
        },
      }),
      initialPageParam: 1,
      getNextPageParam: (lastPage, _, lastPageParam) => {
        if (lastPage.items.length < (limit as number)) {
          return undefined;
        }

        return lastPageParam + 1;
      },
    });
  },
};
