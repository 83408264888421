import qs from 'qs';

/**
 * Parses the search parameters and returns the parsed values for the specified
 * key.
 *
 * @param searchParams - The URLSearchParams object containing the search
 *   parameters.
 * @param key - The key for which to retrieve the parsed values.
 * @returns The parsed values for the specified key.
 */
export function parseSearchParams(searchParams: URLSearchParams, key: string) {
  return parse(searchParams.get(key) ?? '');
}

export function parse(value: string) {
  return qs.parse(value, { arrayLimit: 100, allowDots: true });
}

/**
 * Converts a JavaScript object into a query string representation using the qs
 * library.
 *
 * @param value - The object to be converted into a query string.
 * @returns The query string representation of the object.
 */
export function stringify(value: Record<string, any>) {
  return qs.stringify(value, { allowDots: true });
}

/**
 * Transforms a filter string into an object suitable for API consumption.
 *
 * This function takes a filter string, parses it into an object, and then
 * transforms the object into a format that can be consumed by the API. If the
 * value of a filter is an array, it maps each filter to its id. If the filter
 * is a string, it is left as is.
 *
 * @example
 *   const filters = 'category=books&authors[]=1&authors[]=2';
 *   const result = prepareFilterStringForAPI(filters);
 *   // result will be: { category: 'books', authors: ['1', '2'] }
 *
 * @param {string} filters - The filter string to be prepared for the API. This
 *   string is typically a query string that represents various filters.
 * @returns {Record<string, any>} An object that represents the filters in a
 *   format that can be consumed by the API.
 */
export function prepareFilterStringForAPI(
  filters: string
): Record<string, any> {
  return Object.entries(parse(filters) ?? {}).reduce(
    (acc, [key, value]) => {
      if (Array.isArray(value)) {
        acc[key] = value.map((f) => {
          // If the filter is an object, return the id property.
          // The id property is used to identify the filter for the API.
          // An example of a filter object is { id: 'filter-id', name: 'Filter Name' }
          // We want to collect the filter ids in an array.
          return typeof f === 'string' ? f : f.id;
        });
      } else {
        acc[key] = value;
      }
      return acc;
    },
    {} as Record<string, any>
  );
}
