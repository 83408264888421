import ParticipantTooltip from 'components/ParticipantTooltip';
import BasicTitle from 'components/UI/BasicTitle';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import InlineLink from '../InlineLink';
import DataCell from '../table/DataCell';
import TableName from '../TableName';

const NameCellWrap = styled.div`
  position: relative;

  ${(props) =>
    props.hasHealthScore &&
    `
    padding-left: 24px;
  `}
`;

const TooltipWrap = ({ row, canShowTooltip, children }) =>
  canShowTooltip ? (
    <ParticipantTooltip user={row}>{children}</ParticipantTooltip>
  ) : (
    children
  );

const NameCell = ({
  row,
  canShowTooltip,
  showExtraLinks,
  forceNewTab = true,
}) => {
  if (!row) return null;

  return (
    <DataCell name="Name">
      <NameCellWrap>
        <div>
          {row.admitDecision && row.admitDecision !== 'Participant' ? (
            <BasicTitle $green>{row.admitDecision}</BasicTitle>
          ) : null}
          <TooltipWrap row={row} canShowTooltip={canShowTooltip}>
            <TableName
              as={InlineLink}
              href={`/participants/${row.id}`}
              forceNewTab={forceNewTab}
            >
              {row.firstName} {row.lastName}
            </TableName>
          </TooltipWrap>
        </div>

        {showExtraLinks && row.profile?.linkedin ? (
          <div>
            <TableName.Secondary as={InlineLink} href={row.profile.linkedin}>
              LinkedIn
            </TableName.Secondary>
          </div>
        ) : null}

        {showExtraLinks && row.profile?.finalResume ? (
          <div>
            <TableName.Secondary
              as={InlineLink}
              href={row.profile?.finalResume}
            >
              Resume
            </TableName.Secondary>
          </div>
        ) : null}
      </NameCellWrap>
    </DataCell>
  );
};

NameCell.propTypes = {
  row: PropTypes.object,
};

export default NameCell;
