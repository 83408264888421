import { QueryClient } from '@tanstack/react-query';

// Initialize the query client once, and pass it to the router for use in actions and loaders.
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 3, // 3 minutes
    },
  },
});
