import { createGlobalStyle } from 'styled-components';
import { useIsTabbing } from 'use-is-tabbing';

const TabStyles = createGlobalStyle`
  a,
  button,
  *[role="button"] {
    &:focus-visible {
      box-shadow: 0 0 2px 2px var(--focus-color, var(--orange));
    }
  }
`;

const TabFocusOutlineStyles = () => {
  const showTabOutline = useIsTabbing();

  return showTabOutline ? <TabStyles /> : null;
};

export default TabFocusOutlineStyles;
