import { IconType } from 'components/ds/icons/Icon';

/**
 * A lookup object that maps module names to their corresponding raw "Remix
 * Icon" icon name.
 */
export const MODULE_ICON = {
  home: 'dashboard-line',
  interviews: 'ri-filter-line',
  pipelinePlus: 'box-3-line',
  participants: 'group-line',
  partners: 'shake-hands-line',
  roles: 'briefcase-line',
  matches: 'user-star-line',
  shortlist: 'file-list-3-line',
  hired: 'ri-shield-star-line',
  resources: 'book-2-line',
  automation: 'robot-3-line',
  assignments: 'pencil-ruler-line',
  surveys: 'survey-line',
  events: 'calendar-event-line',
  tags: 'price-tag-3-line',
  team: 'team-line',
  gameplans: 'ri-mind-map',
} as const satisfies Record<string, IconType>;
