import { position, size } from 'polished';
import styled from 'styled-components';
import UnstyledButton from './UnstyledButton';

const PlayButton = styled(UnstyledButton)`
  ${size('43px')};
  position: relative;

  &::before,
  &::after {
    ${position('absolute', '50%', 0, 0, '50%')};
    transform: translate(-50%, -50%);
    content: '';
    background: var(--white);
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.2);
  }

  &::before {
    ${size('24px')};
    border-radius: 50%;
  }

  &::after {
    ${size('10px')};
    clip-path: polygon(100% 50%, 15% 0, 15% 100%);
    background: var(--orange);
  }
`;

export default PlayButton;
