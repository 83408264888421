import { rem } from 'polished';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import RichTextEditor from 'react-rte';
import styled from 'styled-components';
import HelperText from './HelperText';
import Label from './Label';

const EditorWrap = styled.div`
  grid-column: span 2;

  button {
    background: var(--off-white) !important;
    border: 1px solid var(--border-gray);
    border-radius: 4px !important;
    border-width: 1px !important;
    margin-right: 3px !important;
  }

  .rich-editor {
    border: 0;
    font-family: inherit !important;

    & > div:first-child {
      border: 0;
      margin: 0;
    }
  }

  .DraftEditor-root {
    border: var(--border);
    border-radius: var(--border-radius);
    border: 1px solid var(--border-gray);
    background: var(--off-white);
    height: 100%;
    min-height: 210px;
    overflow: scroll;
  }

  .DraftEditor-editorContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    overflow: visible;
  }

  .public-DraftEditorPlaceholder-root {
    color: var(--placeholder-gray) !important;
  }

  .public-DraftEditorPlaceholder-root,
  .public-DraftEditor-content {
    overflow: visible !important;
    padding: 13px 12px;
  }
`;

const Count = styled.div`
  text-align: right;
  font-size: ${rem(14)};
`;

const toolbarConfig = {
  display: [
    'INLINE_STYLE_BUTTONS',
    'BLOCK_TYPE_BUTTONS',
    'LINK_BUTTONS',
    'BLOCK_TYPE_DROPDOWN',
  ],
  INLINE_STYLE_BUTTONS: [
    { label: 'Bold', style: 'BOLD' },
    { label: 'Italic', style: 'ITALIC' },
    { label: 'Underline', style: 'UNDERLINE' },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: 'Normal', style: 'unstyled' },
    { label: 'Heading Large', style: 'header-one' },
    { label: 'Heading Medium', style: 'header-two' },
    { label: 'Heading Small', style: 'header-three' },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: 'UL', style: 'unordered-list-item' },
    { label: 'OL', style: 'ordered-list-item' },
  ],
};

const TextEditor = React.forwardRef(
  (
    {
      name,
      value,
      onChange,
      label,
      hideLabel,
      className,
      placeholder,
      hideToolbar,
      required = false,
      maxLength,
      allowUpdates = false,
    },
    ref
  ) => {
    const id = `texteditor-${name}`;
    const valueRef = useRef(null);
    const [remaining, setRemaining] = useState(0);
    const [editorValue, setEditorValue] = useState(
      RichTextEditor.createValueFromString(value?.text || value, 'html')
    );

    useEffect(() => {
      if (!allowUpdates) return;
      if (
        valueRef.current != null &&
        (valueRef.current === value || valueRef.current === value?.text)
      )
        return;

      if (maxLength) {
        setRemaining(
          maxLength -
            editorValue.getEditorState().getCurrentContent().getPlainText()
              .length
        );
      }

      setEditorValue(
        editorValue.setContentFromString(
          value?.text ? value.text : value,
          'html'
        )
      );
      valueRef.current = value?.text ? value.text : value;
    }, [value, editorValue, maxLength, allowUpdates]);

    const onEditorChange = (editorContent) => {
      const oldEditorValue = editorValue;
      setEditorValue(editorContent);
      const oldContentState = oldEditorValue
        ? oldEditorValue.getEditorState().getCurrentContent()
        : null;
      const newContentState = editorContent
        .getEditorState()
        .getCurrentContent();
      if (oldContentState !== newContentState) {
        const remainingChars =
          (maxLength || 0) - newContentState.getPlainText().length;

        if (maxLength) {
          setRemaining(remainingChars);
        }

        const stringValue = editorContent.toString('html');
        valueRef.current = stringValue;
        if (onChange && stringValue !== value) {
          if (maxLength) {
            onChange({ text: stringValue, remaining: remainingChars });
          } else {
            onChange(stringValue);
          }
        }
      }
    };

    return (
      <EditorWrap className={className}>
        {label && (
          <Label htmlFor={id} hideLabel={hideLabel}>
            {label}
            {required ? '*' : null}
          </Label>
        )}
        <RichTextEditor
          id={id}
          ref={ref}
          value={editorValue}
          placeholder={placeholder}
          className="rich-editor"
          toolbarConfig={hideToolbar ? { display: [] } : toolbarConfig}
          onChange={onEditorChange}
        />

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {remaining < 0 ? (
            <HelperText>
              <HelperText.Error>
                The max number of characters allowed is {maxLength}.
              </HelperText.Error>
            </HelperText>
          ) : (
            <div />
          )}

          {value && maxLength ? (
            <Count
              style={{ color: remaining < 0 ? 'var(--error-red)' : 'inherit' }}
            >
              {remaining}
            </Count>
          ) : null}
        </div>
      </EditorWrap>
    );
  }
);

TextEditor.displayName = 'TextEditor';

TextEditor.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  label: PropTypes.string,
  hideLabel: PropTypes.bool,
  className: PropTypes.string,
  miniMode: PropTypes.bool,
};

export default TextEditor;
