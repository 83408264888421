import {
  InfiniteData,
  useInfiniteQuery,
  useSuspenseInfiniteQuery,
} from '@tanstack/react-query';
import { Params } from 'api/types/params';

interface InfiniteQueryOptions<
  TQueryFnData,
  TKey,
  TData = TQueryFnData,
  TSelectData = TData,
> {
  queryKeyFn: (params: Params) => TKey;
  fetchFn: (
    params: { [key: string]: unknown },
    signal: { signal?: AbortSignal }
  ) => Promise<TQueryFnData>;
  enabled?: boolean;
  select?: (data: InfiniteData<TQueryFnData, number>) => TSelectData;
}
/**
 * Creates an infinite query hook for fetching paginated data.
 *
 * @example
 *   // Usage example
 *
 *   const useCreatedInfiniteQuery = createInfiniteQuery({
 *   fetchFn: (params, signal) => getData(params, signal)
 *   queryKeyFn: (params) => ['data', 'list', params]
 *   });
 *
 *   const { data } = useCreatedInfiniteQuery({ params: { limit: 10 } });
 *
 * @template TQueryFnData - The type of the data returned by the fetch function.
 * @template TKey - The type of the query key.
 * @param fetchFn - The function used to fetch the data.
 * @param queryKeyFn - The initial query key function.
 * @param enabled - Whether the query should be enabled. Default is true.
 * @returns A function that can be used to execute the infinite query.
 */
export function createInfiniteQuery<
  TQueryFnData extends { items: unknown[] },
  TKey extends ReadonlyArray<unknown>,
  TData = InfiniteData<TQueryFnData, number>,
  TSelectData = TData,
>({
  fetchFn: fetchFunction,
  queryKeyFn,
  enabled = true,
  select,
}: InfiniteQueryOptions<TQueryFnData, TKey, TData, TSelectData>) {
  return ({ params }: Params = {}) => {
    const { limit = 20 } = params ?? {};

    return useInfiniteQuery({
      enabled,
      initialPageParam: 1,
      queryKey: queryKeyFn({
        params: {
          ...params,
          limit,
        },
      }),
      queryFn: ({ pageParam = 1, signal }) => {
        const skip = (pageParam - 1) * (limit as number);

        return fetchFunction(
          {
            params: {
              ...params,
              limit,
              skip,
            },
          },
          { signal }
        );
      },
      select,
      getNextPageParam: (lastPage, _, lastPageParam) => {
        if (lastPage.items.length < (limit as number)) {
          return undefined;
        }

        return lastPageParam + 1;
      },
    });
  };
}

export function createSuspenseInfiniteQuery<
  TData extends { items: unknown[] },
  TKey extends ReadonlyArray<unknown>,
>({ fetchFn: fetchFunction, queryKeyFn }: InfiniteQueryOptions<TData, TKey>) {
  return ({ params }: Params = {}) => {
    const { limit = 20 } = params ?? {};

    return useSuspenseInfiniteQuery({
      initialPageParam: 1,
      queryKey: queryKeyFn({
        params: {
          ...params,
          limit,
        },
      }),
      queryFn: ({ pageParam = 1, signal }) => {
        const skip = (pageParam - 1) * (limit as number);

        return fetchFunction(
          {
            params: {
              ...params,
              limit,
              skip,
            },
          },
          { signal }
        );
      },
      getNextPageParam: (lastPage, _, lastPageParam) => {
        if (lastPage.items.length < (limit as number)) {
          return undefined;
        }

        return lastPageParam + 1;
      },
    });
  };
}
