import Button from 'components/Button';
import ButtonGroup from 'components/ButtonGroup';
import { toast } from 'components/ds/Toast/Toast';
import Combo from 'components/forms/Combo';
import Input from 'components/forms/Input';
import Grid from 'components/Grid';
import { H4 } from 'components/H';
import Modal from 'components/Modal';
import dataService from 'dataService';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage } from 'util/error.enum';
import { formatFormValues, tierOptions } from 'util/forms';

const defaultValues = {
  tier: null,
  status: {},
  context: '',
};

const statusOptions = [
  { value: 1, label: 'High Priority' },
  { value: 2, label: 'Evergreen' },
  { value: 3, label: 'Hold' },
  { value: 4, label: 'Backfill' },
  { value: 5, label: 'No Backfill' },
  { value: 6, label: 'Waiting to Hear' },
];

const AddStatusModal = ({ modalOpen, modalData, onRequestClose }) => {
  const {
    handleSubmit,
    control,
    register,
    formState: { errors, isDirty },
    reset,
    watch,
  } = useForm({
    mode: 'onTouched',
    shouldUnregister: true,
    defaultValues,
  });
  const status = watch('status');

  const onSubmit = (values) => {
    const formData = formatFormValues(values);

    dataService
      .save(`jobs/${modalData?.jobId}/statuses`, formData)
      .then((res) => {
        onRequestClose(true);

        toast.success(`Status created`);
      })
      .catch((res) => {
        onRequestClose(false);

        if (res.message === ErrorMessage.CONFLICT) {
          return toast.error(
            `The status "${values.status.label}" already exists.`,
            {
              description: `Please remove it before creating a new one.`,
            }
          );
        }

        toast.error(res.message);
      });
  };

  // Empty the form on modal close
  useEffect(() => {
    if (!modalOpen) {
      reset(defaultValues);
    }
  }, [modalOpen, modalData, reset]);

  return (
    <Modal
      isOpen={modalOpen === 'addStatus'}
      onRequestClose={() => {
        onRequestClose(false);
      }}
      large
    >
      <H4>Add Status</H4>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid formGrid cols={4}>
          <Grid.Item span={1}>
            <Controller
              name="status"
              control={control}
              rules={{
                required: 'Please select a status',
              }}
              render={({ field }) => {
                return (
                  <Combo
                    {...field}
                    options={statusOptions}
                    label="Status"
                    placeholder="Select Status"
                    small
                    errors={errors}
                    span={1}
                    required
                  />
                );
              }}
            />
          </Grid.Item>

          {status?.value === 1 ? (
            <Grid.Item span={1}>
              <Controller
                name="tier"
                control={control}
                rules={{
                  required: 'Please select a tier',
                }}
                render={({ field }) => {
                  return (
                    <Combo
                      {...field}
                      options={tierOptions}
                      label="Tier"
                      placeholder="Select Tier"
                      small
                      errors={errors}
                      span={1}
                      required
                    />
                  );
                }}
              />
            </Grid.Item>
          ) : null}

          <Grid.Item span={4}>
            <Input
              {...register('context', {
                required: 'Please add context to this status',
              })}
              type="text"
              label="Context"
              placeholder="Context"
              errors={errors}
            />
          </Grid.Item>

          <Grid.Item span={2} as={ButtonGroup}>
            <Button type="submit" disabled={!isDirty} $small>
              Add Status
            </Button>

            <Button
              type="button"
              onClick={() => {
                onRequestClose();
              }}
              $theme="secondary"
              $small
            >
              Cancel
            </Button>
          </Grid.Item>
        </Grid>
      </form>
    </Modal>
  );
};

AddStatusModal.propTypes = {
  modalOpen: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  modalData: PropTypes.object,
  onRequestClose: PropTypes.func,
};

export default AddStatusModal;
