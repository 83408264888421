import PropTypes from 'prop-types';
import ActionText, { ActionDivider } from '../ActionText';
import DataCell from './DataCell';

const ManageCell = ({
  row,
  toggleModal,
  name,
  editText = 'Edit',
  deleteText = 'Delete',
}) => (
  <DataCell name={name}>
    <ActionText
      onClick={() => {
        toggleModal('edit', row);
      }}
    >
      {editText}
    </ActionText>
    <ActionDivider> | </ActionDivider>
    <ActionText
      delete
      onClick={() => {
        toggleModal('confirmDelete', row);
      }}
    >
      {deleteText}
    </ActionText>
  </DataCell>
);

ManageCell.propTypes = {
  row: PropTypes.object.isRequired,
  toggleModal: PropTypes.func.isRequired,
  name: PropTypes.string,
};

export default ManageCell;
