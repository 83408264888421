import { useEffect, useReducer, useState } from 'react';
import useSWR from 'swr';
import api, { baseURL } from './api';

const writeMethods = {
  /**
   * Makes a patch request.
   *
   * @param {string} path -The API path to call.
   * @param {Object} obj -The data object you want to write.
   * @param {Object} [reqConfig] -The axios request configuration (optional)
   * @param {Boolean} [skipAuth] -option to skip the call to refresh the auth
   *   token
   * @returns {Object} -the response data
   */
  patch: async (path, obj, reqConfig, skipAuth) => {
    const resp = await api.patch(path, obj, reqConfig);
    return resp.data;
  },
  put: async (path, obj, reqConfig, skipAuth) => {
    const resp = await api.put(path, obj, reqConfig);
    return resp.data;
  },
  /**
   * Makes a post or patch request depending on existence of id property in the
   * passed object.
   *
   * @param {string} path -The API path to call.
   * @param {Object} obj -The data object you want to write.
   * @param {Object} [reqConfig] -The axios request configuration (optional)
   * @param {Boolean} [skipAuth] -option to skip the call to refresh the auth
   *   token
   * @param {Boolean} [skipID] -don't append the ID to the path for patch
   *   requests.
   * @returns {Promise<Object>} -the response data
   */
  save: async (path, obj, reqConfig, skipAuth, skipID) => {
    let method = api.post;
    let url = path;

    if (obj.id) {
      method = api.patch;
      url = skipID ? path : `${path}/${obj.id}`;
      delete obj.id;
    }

    if (obj instanceof FormData && obj.has('id')) {
      method = api.patch;
      url = skipID ? path : `${path}/${obj.get('id')}`;
      obj.delete('id');
    }

    const resp = await method(url, obj, reqConfig);
    return resp.data;
  },
  /**
   * Makes a delete request to the API.
   *
   * @param {string} path -The API path to call.
   * @param {Object} obj -The data object you want to delete (needs at least the
   *   id property).
   * @param {Boolean} [skipAuth] -option to skip the call to refresh the auth
   *   token.
   * @returns {Object} -the response data
   */
  delete: async (path, obj, skipAuth) => {
    const resp = await api.delete(`${path}/${obj?.id ? obj.id : ''}`);
    return resp.data;
  },
};

// Handles dispatch calls from the useWrite hook
const dataFetchReducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_INIT':
      return { ...state, isLoading: true };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.payload,
      };
    case 'FETCH_FAILURE':
      return {
        ...state,
        isLoading: false,
        isError: true,
        data: action.payload,
      };
    case 'UPDATE_DATA':
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.payload,
      };
    default:
      throw new Error();
  }
};

// Combined API data services
const dataService = {
  /**
   * Wrapper for the useSWR hook that adds token refreshing and option for
   * delayed fetching
   *
   * @param {string | null | [string, { params: any }]} key -The API path to
   *   call (also used as the cache key by SWR)
   * @param {object} [options] -The options to pass to useSWR.
   *   shouldRetryOnError is set to false by default.
   * @param {Boolean} [skipAuth] -Option to skip the call to refresh the auth
   *   token.
   * @returns {Object} GetReturn
   * @returns {Object} -GetReturn.data -The data or error returned
   * @returns {Boolean} -GetReturn.isLoading -True if the API has not yet
   *   responded
   * @returns {Boolean} -GetReturn.isError -True if there was an error returned
   * @returns {Function} -GetReturn.mutate -function to mutate the cached data
   */
  useGet: (key, options, skipAuth, registerAbortController = () => {}) => {
    const fetcher = async (args) => {
      const [path, config = {}] = Array.isArray(args) ? args : [args];
      const controller = new AbortController();

      // Register the abort controller so the request can be cancelled if needed
      registerAbortController(controller);
      const resp = await api.get(path, {
        ...config,
        signal: controller?.signal ?? undefined,
      });
      return resp.data;
    };
    const defaultOptions = {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      dedupingInterval: 3000,
    };
    const response = useSWR(key, fetcher, {
      ...defaultOptions,
      ...options,
    });

    return {
      ...response,
      isError: !!response.error,
    };
  },

  ...writeMethods,

  /**
   * General hook for making write calls to the API
   *
   * @param {Object} initialConfig -Configuration of the API call you want to
   *   make
   * @param {string} initialConfig.op -The write operation you want to exexute
   *   (see writeMethods)
   * @param {Array} initialConfig.args -The arguments to pass to the write
   *   method
   * @param {string} [initialConfig.setupOnly] -If True the API will not be
   *   called until setConfig is called and sets this to false
   * @param {Object} [initialData] -Initial value in case you want do show
   *   something before the API responds.
   * @returns {Object} WriteReturn
   * @returns {Object} WriteReturn.data -The data or error returned
   * @returns {Boolean} WriteReturn.isLoading -True if the API has not yet
   *   responded
   * @returns {Boolean} WriteReturn.isError -True if there was an error returned
   * @returns {Function} WriteReturn.setConfig -Client code calls this function
   *   to execute a request
   * @returns {Function} WriteReturn.updateData -allows changing the data
   *   manually after it is returned
   */
  useWrite: (initialConfig, initialData) => {
    const [config, setConfig] = useState(initialConfig);

    const [state, dispatch] = useReducer(dataFetchReducer, {
      isLoading: false,
      isError: false,
      data: initialData,
    });

    // Allow client to update the data in the state.
    const updateData = (data) => {
      dispatch({ type: 'UPDATE_DATA', payload: data });
    };

    useEffect(() => {
      let didCancel = false;
      if (!config) return;

      const fetchData = async () => {
        dispatch({ type: 'FETCH_INIT' });

        try {
          const args = config.args || [];
          const result = await writeMethods[config.op](...args);

          if (!didCancel) {
            dispatch({ type: 'FETCH_SUCCESS', payload: result });
          }
        } catch (error) {
          if (!didCancel) {
            dispatch({ type: 'FETCH_FAILURE', payload: error });
          }
        }
      };

      fetchData();

      return () => {
        didCancel = true;
      };
    }, [config]);

    return { ...state, setConfig, updateData };
  },
  getTableParams(page, limit, sort, filters) {
    const formattedFilters = Object.entries(filters ?? {}).reduce(
      (acc, [key, value]) => {
        if (Array.isArray(value)) {
          acc[key] = value.map((f) => (typeof f === 'string' ? f : f.value));
        } else {
          acc[key] = value;
        }
        return acc;
      },
      {}
    );

    return {
      params: {
        limit,
        skip: (parseInt(page, 10) - 1) * limit,
        ...(sort && {
          orderDir: sort.dir.toUpperCase(),
          orderBy: sort.col,
        }),
        ...formattedFilters,
      },
    };
  },

  getRelatedSkills: (personaIds) => {
    let paramStr = '';

    personaIds?.forEach((p, pi) => {
      paramStr += `persona[]=${p}${pi < personaIds.length - 1 ? '&' : ''}`;
    });

    return api
      .get(`/personas/skills?${paramStr}`)
      .then((response) => response.data);
  },
  getEndpoint: (endpoint) =>
    api.get(`${baseURL}/${endpoint}`).then((response) => response.data),

  checkResetToken: (token) =>
    api
      .get(`${baseURL}/auth/reset-tokens/${token}`)
      .then((response) => response.data),

  setupAccount: (creds) =>
    api.post(`${baseURL}/auth/setup`, creds).then((response) => response.data),

  getProfile: () =>
    api.get(`${baseURL}/auth/profile`).then((response) => response.data),

  simpleSearch: (endpoint) => api.get(`${baseURL}/${endpoint}`),

  saveImage: (data, endpoint = 'images') =>
    api
      .post(`${baseURL}/${endpoint}`, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((response) => response.data),
  patternMatch: (opts) => {
    const params = [];

    Object.keys(opts).forEach((key) => {
      params.push(`${key}=${opts[key]}`);
    });

    const paramString = params.join('&');

    return api.get(`${baseURL}/pattern-match?${paramString}`);
  },
};

export default dataService;
