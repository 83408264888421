import {
  InfiniteData,
  QueryClient,
  infiniteQueryOptions,
  queryOptions,
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { Params } from 'api/types/params';
import api from 'dataService/api';
import { PIPELINE_QUERIES } from 'modules/team/pipeline/api/pipeline';
import { INote } from 'types/note.types';
import { PaginatedResponse } from 'types/pagination.types';
import { createInfiniteDataTransform } from 'util/createInfiniteDataTransform';

export const JOBS_KEYS = {
  all: 'jobs' as const,
  list: (params: Params) => {
    return [JOBS_KEYS.all, 'list', params] as const;
  },
  detail: (id: string | number) => [JOBS_KEYS.all, String(id)] as const,
  brainstorms: (id: string | number) =>
    [...JOBS_KEYS.detail(id), 'brainstorms'] as const,
  brainstormsList: (id: string | number, params: Params) =>
    [...JOBS_KEYS.brainstorms(id), params] as const,
  process: (id: string | number) =>
    [...JOBS_KEYS.detail(id), 'process'] as const,
  processList: (id: string | number, params: Params) =>
    [...JOBS_KEYS.process(id), params] as const,
  statuses: (id: string | number) =>
    [...JOBS_KEYS.detail(id), 'statuses'] as const,
  statusesList: (id: string | number, params: Params) =>
    [...JOBS_KEYS.statuses(id), params] as const,
  statusesLog: (id: string | number) =>
    [...JOBS_KEYS.detail(id), 'statusesLog'] as const,
  statusesLogList: (id: string | number, params: Params) =>
    [...JOBS_KEYS.statusesLog(id), params] as const,
  potentialParticipants: (id: string | number) =>
    [...JOBS_KEYS.detail(id), 'potentialParticipants'] as const,
  potentialParticipantsList: (id: string | number, params: Params) =>
    [...JOBS_KEYS.potentialParticipants(id), params] as const,
};

export const JOBS_QUERY_OPTIONS = {
  detail: (id: string) => {
    return queryOptions({
      queryKey: JOBS_KEYS.detail(id),
      queryFn: async ({ signal }) => {
        const response = await api.get(`/jobs/${id}`, {
          signal,
        });

        return response.data;
      },
    });
  },
  brainstormsList: (id: string, params = {}) => {
    return queryOptions({
      queryKey: JOBS_KEYS.brainstormsList(id, params),
      queryFn: async ({ signal }) => {
        const response = await api.get(`/jobs/${id}/brainstorm`, {
          ...params,
          signal,
        });

        return response.data;
      },
    });
  },
  processList: (id: string, params = {}) => {
    return queryOptions({
      queryKey: JOBS_KEYS.processList(id, params),
      queryFn: async ({ signal }) => {
        const response = await api.get(`/jobs/${id}/process`, {
          ...params,
          signal,
        });

        return response.data;
      },
    });
  },
  statusesList: (id: string, params = {}) => {
    return queryOptions({
      queryKey: JOBS_KEYS.statusesList(id, params),
      queryFn: async ({ signal }) => {
        const response = await api.get(`/jobs/${id}/statuses`, {
          signal,
          ...params,
        });

        return response.data;
      },
    });
  },
  statusesLogList: (id: string, params = {}) => {
    return queryOptions({
      queryKey: JOBS_KEYS.statusesLogList(id, params),
      queryFn: async ({ signal }) => {
        const response = await api.get(`/jobs/${id}/statuses/log`, {
          signal,
          ...params,
        });

        return response.data;
      },
    });
  },
  potentialParticipantsList: (id: string, params = {}) => {
    return queryOptions({
      queryKey: JOBS_KEYS.potentialParticipantsList(id, params),
      queryFn: async ({ signal }) => {
        const response = await api.get(`/jobs/${id}/potential-participants`, {
          signal,
          ...params,
        });

        return response.data;
      },
    });
  },
  closingNotesList: (jobId: string) => {
    return queryOptions({
      queryKey: [...JOBS_KEYS.detail(jobId), 'closingNotes'] as const,
      queryFn: async ({ signal }) => {
        const response = await api.get<PaginatedResponse<INote>>(
          `/jobs/${jobId}/closing-notes`,
          {
            params: {
              skip: 0,
              limit: 100,
            },
            signal,
          }
        );

        return response.data;
      },
    });
  },
  infiniteJobsList: (params: { [key: string]: unknown }) => {
    const {
      limit = 20,
      orderBy = 'highPotential',
      orderDir = 'DESC',
    } = params ?? {};

    return infiniteQueryOptions({
      queryFn: async ({ pageParam = 1, signal }) => {
        const response = await api.get<
          PaginatedResponse<{
            id: number;
            title: string;
            aggregates?: { highPriority?: number };
          }>
        >('/jobs', {
          params: {
            ...params,
            limit,
            skip: (pageParam - 1) * (limit as number),
            orderBy,
            orderDir,
          },
          signal,
        });

        return response.data;
      },
      queryKey: JOBS_KEYS.list({
        params: {
          ...params,
          limit,
          orderBy,
          orderDir,
        },
      }),
      initialPageParam: 1,
      getNextPageParam: (lastPage, _, lastPageParam) => {
        if (lastPage.items.length < (limit as number)) {
          return undefined;
        }

        return lastPageParam + 1;
      },
      select: jobsListDataTransform,
    });
  },
};

export async function getJobs(params: Params, signal?: AbortSignal) {
  const response = await api.get(`/jobs`, {
    ...params,
    signal,
  });

  return response.data;
}

export async function prefetchJobDetail(id: string, queryClient: QueryClient) {
  return queryClient.ensureQueryData(JOBS_QUERY_OPTIONS.detail(id));
}

export function useSuspenseJobDetail(id: string) {
  return useSuspenseQuery(JOBS_QUERY_OPTIONS.detail(id));
}

export function useJobMutate() {
  const queryClient = useQueryClient();
  async function updateJob({
    id,
    newJobInfo,
  }: {
    id: string;
    newJobInfo: any;
  }) {
    const response = await api.patch(`/jobs/${id}`, newJobInfo);

    return response.data;
  }

  return useMutation({
    mutationFn: updateJob,
    onSuccess: (_, { id }) => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: JOBS_KEYS.detail(id) }),
        queryClient.invalidateQueries({ queryKey: PIPELINE_QUERIES.all() }),
      ]);
    },
  });
}

function jobsListDataTransform(
  data: InfiniteData<
    {
      items: {
        id: number;
        title: string;
        aggregates?: { highPriority?: number };
      }[];
    },
    number
  >
) {
  return createInfiniteDataTransform(data, (job) => {
    const isHighPriority = job.aggregates?.highPriority === 1;
    return {
      ...job,
      name: job.title,
      ui: (
        <div className="flex w-full justify-between gap-2">
          <div className="flex-1">{job.title}</div>
          {isHighPriority && (
            <img alt="" src="/images/flame.svg" className="h-4 w-4 shrink-0" />
          )}
        </div>
      ),
    };
  });
}
