import * as React from 'react';
import { tv, VariantProps } from 'tailwind-variants';

interface BadgeProps
  extends React.ComponentPropsWithoutRef<'span'>,
    VariantProps<typeof badgeVariants> {
  icon?: React.ReactElement;
}
export const Badge = React.forwardRef<HTMLSpanElement, BadgeProps>(
  (
    {
      interactive,
      colorScheme,
      variant,
      shape,
      size,
      icon,
      children,
      className,
      ...rest
    },
    ref
  ) => {
    const iconElement = icon
      ? React.cloneElement(icon, {
          className: badgeIconVariants({ size }),
        })
      : null;

    return (
      <span
        ref={ref}
        {...rest}
        className={badgeVariants({
          interactive,
          colorScheme,
          variant,
          shape,
          size,
          className,
        })}
      >
        {iconElement}
        {children}
      </span>
    );
  }
);
Badge.displayName = 'Badge';

export const badgeVariants = tv({
  base: 'no-underline flex shrink-0 items-center tabular-nums font-medium transition-colors focus:outline-none focus:ring-2 focus:ring-ds-stroke-tertiary focus:ring-offset-2 bg-[rgb(var(--bg-color))] text-[rgb(var(--text-color))] [--hover-opacity:0.8] [--hover-bg-color:rgba(var(--bg-color)_/_var(--hover-opacity))]',
  variants: {
    interactive: {
      true: 'hover:bg-[var(--hover-bg-color)] focus-within:bg-[var(--hover-bg-color)] hover:text-[rgb(var(--text-color))]',
    },
    colorScheme: {
      neutral: '',
      dark: '',
      teal: '',
      orange: '',
      blue: '',
      green: '',
      yellow: '',
      red: '',
      purple: '',
      pink: '',

      // Semantic
      primary: '',
      secondary: '',
      positive: '',
      negative: '',
      warning: '',
      info: '',
    },
    variant: {
      subtle: '',
      solid: '',
    },
    shape: {
      rectangle: 'rounded-md',
      pill: 'rounded-full',
    },
    size: {
      sm: 'gap-0.5 text-[0.6875rem] px-2 h-5',
      normal: 'gap-1 text-xs px-2.5 h-6',
      lg: 'gap-1.5 text-sm px-3 h-8',
    },
  },
  compoundVariants: [
    {
      colorScheme: 'neutral',
      variant: 'subtle',
      className:
        '[--bg-color:var(--neutral-200)] [--text-color:var(--neutral-900)]',
    },
    {
      colorScheme: 'neutral',
      variant: 'solid',
      className:
        '[--bg-color:var(--neutral-400)] [--text-color:var(--neutral-100)]',
    },
    {
      colorScheme: 'teal',
      variant: 'subtle',
      className:
        '[--bg-color:var(--teal-lighter)] [--text-color:var(--teal-darker)]',
    },
    {
      colorScheme: 'teal',
      variant: 'solid',
      className:
        '[--bg-color:var(--teal-base)] [--text-color:var(--neutral-100)]',
    },
    {
      colorScheme: 'orange',
      variant: 'subtle',
      className:
        '[--bg-color:var(--orange-lighter)] [--text-color:var(--orange-darker)]',
    },
    {
      colorScheme: 'orange',
      variant: 'solid',
      className:
        '[--bg-color:var(--orange-base)] [--text-color:var(--neutral-100)]',
    },
    {
      colorScheme: 'blue',
      variant: 'subtle',
      className:
        '[--bg-color:var(--blue-lighter)] [--text-color:var(--blue-darker)]',
    },
    {
      colorScheme: 'blue',
      variant: 'solid',
      className:
        '[--bg-color:var(--blue-base)] [--text-color:var(--neutral-100)]',
    },
    {
      colorScheme: 'green',
      variant: 'subtle',
      className:
        '[--bg-color:var(--green-lighter)] [--text-color:var(--green-darker)]',
    },
    {
      colorScheme: 'green',
      variant: 'solid',
      className:
        '[--bg-color:var(--green-base)] [--text-color:var(--neutral-100)]',
    },
    {
      colorScheme: 'yellow',
      variant: 'subtle',
      className:
        '[--bg-color:var(--yellow-lighter)] [--text-color:var(--yellow-darker)]',
    },
    {
      colorScheme: 'yellow',
      variant: 'solid',
      className:
        '[--bg-color:var(--yellow-base)] [--text-color:var(--neutral-900)]',
    },
    {
      colorScheme: 'red',
      variant: 'subtle',
      className:
        '[--bg-color:var(--red-lighter)] [--text-color:var(--red-darker)]',
    },
    {
      colorScheme: 'red',
      variant: 'solid',
      className:
        '[--bg-color:var(--red-base)] [--text-color:var(--neutral-100)]',
    },
    {
      colorScheme: 'purple',
      variant: 'subtle',
      className:
        '[--bg-color:var(--purple-lighter)] [--text-color:var(--purple-darker)]',
    },
    {
      colorScheme: 'purple',
      variant: 'solid',
      className:
        '[--bg-color:var(--purple-base)] [--text-color:var(--neutral-100)]',
    },
    {
      colorScheme: 'pink',
      variant: 'subtle',
      className:
        '[--bg-color:var(--pink-lighter)] [--text-color:var(--pink-darker)]',
    },
    {
      colorScheme: 'pink',
      variant: 'solid',
      className:
        '[--bg-color:var(--pink-base)] [--text-color:var(--neutral-100)]',
    },

    // Semantic
    {
      colorScheme: 'dark',
      variant: 'subtle',
      className:
        '[--bg-color:var(--neutral-300)] [--text-color:var(--neutral-900)]',
    },
    {
      colorScheme: 'dark',
      variant: 'solid',
      className:
        '[--bg-color:var(--neutral-800)] [--text-color:var(--neutral-100)]',
    },
    {
      colorScheme: 'primary',
      variant: 'subtle',
      className:
        '[--bg-color:var(--primary-lighter)] [--text-color:var(--primary-darker)]',
    },
    {
      colorScheme: 'primary',
      variant: 'solid',
      className:
        '[--bg-color:var(--primary-base)] [--text-color:var(--neutral-100)]',
    },
    {
      colorScheme: 'secondary',
      variant: 'subtle',
      className:
        '[--bg-color:var(--secondary-lighter)] [--text-color:var(--secondary-darker)]',
    },
    {
      colorScheme: 'secondary',
      variant: 'solid',
      className:
        '[--bg-color:var(--secondary-base)] [--text-color:var(--neutral-100)]',
    },
    {
      colorScheme: 'positive',
      variant: 'subtle',
      className:
        '[--bg-color:var(--green-lighter)] [--text-color:var(--green-darker)]',
    },
    {
      colorScheme: 'positive',
      variant: 'solid',
      className:
        '[--bg-color:var(--green-base)] [--text-color:var(--neutral-100)]',
    },
    {
      colorScheme: 'negative',
      variant: 'subtle',
      className:
        '[--bg-color:var(--red-lighter)] [--text-color:var(--red-darker)]',
    },
    {
      colorScheme: 'negative',
      variant: 'solid',
      className:
        '[--bg-color:var(--red-base)] [--text-color:var(--neutral-100)]',
    },
    {
      colorScheme: 'warning',
      variant: 'subtle',
      className:
        '[--bg-color:var(--yellow-lighter)] [--text-color:var(--yellow-darker)]',
    },
    {
      colorScheme: 'warning',
      variant: 'solid',
      className:
        '[--bg-color:var(--yellow-base)] [--text-color:var(--neutral-900)]',
    },
    {
      colorScheme: 'info',
      variant: 'subtle',
      className:
        '[--bg-color:var(--blue-lighter)] [--text-color:var(--blue-darker)]',
    },
    {
      colorScheme: 'info',
      variant: 'solid',
      className:
        '[--bg-color:var(--blue-base)] [--text-color:var(--neutral-100)]',
    },
  ],
  defaultVariants: {
    colorScheme: 'neutral',
    variant: 'subtle',
    shape: 'rectangle',
    size: 'normal',
  },
});

export const badgeIconVariants = tv({
  base: 'color-current grid place-items-center',
  variants: {
    size: {
      sm: 'w-[0.6875rem] h-[0.6875rem]',
      normal: 'w-[0.875rem] h-[0.875rem]',
      lg: 'w-4 h-4',
    },
  },
  defaultVariants: {
    size: 'normal',
  },
});
