import styled from 'styled-components';

const PageTitle = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  grid-gap: 20px;
`;

PageTitle.Actions = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 10px;
`;

export default PageTitle;
