import {
  InfiniteData,
  infiniteQueryOptions,
  queryOptions,
} from '@tanstack/react-query';
import { Params } from 'api/types/params';
import { UserProfileResponse } from 'api/user/user';
import api from 'dataService/api';
import { PaginatedResponse } from 'types/pagination.types';
import { addFullNameToDataItems } from 'util/addFullNameToDataItems';
import { createInfiniteDataTransform } from 'util/createInfiniteDataTransform';

export const PARTICIPANT_KEYS = {
  all: ['participant'] as const,
  list: (params: Params) => [...PARTICIPANT_KEYS.all, 'list', params] as const,
  interviewSnapshot: (params: Params) =>
    [...PARTICIPANT_KEYS.all, 'interview-snapshot', params] as const,
  highTouch: (params: Params) =>
    [...PARTICIPANT_KEYS.all, 'high-touch', params] as const,
  userStatuses: (params: Params) =>
    [...PARTICIPANT_KEYS.all, 'user-statuses', params] as const,
};

export const PARTICIPANT_QUERIES = {
  interviewSnapshot: (params = {}) => {
    return queryOptions({
      queryKey: PARTICIPANT_KEYS.interviewSnapshot(params),
      queryFn: async ({ signal }) => {
        const response = await api.get('/participants/interview-snapshot', {
          ...params,
          signal,
        });

        return response.data;
      },
    });
  },
  participant: (id?: string) => {
    return queryOptions({
      queryKey: [...PARTICIPANT_KEYS.all, id],
      queryFn: async ({ signal }) => {
        if (!id) return null;

        const response = await api.get<UserProfileResponse>(
          `/participants/${id}`,
          { signal }
        );

        return response.data;
      },
    });
  },
  search: (params: Params) => {
    return queryOptions({
      queryKey: [...PARTICIPANT_KEYS.all, 'search', params] as const,
      queryFn: async ({ signal }) => {
        const response = await api.get<ParticipantSearchResponse>(
          '/search/participants',
          {
            ...params,
            signal,
          }
        );

        return response.data;
      },
      staleTime: 1000 * 60 * 1, // 1 minute
    });
  },
  infiniteUserStatusesList: (params: { [key: string]: unknown }) => {
    const { limit = 20 } = params ?? {};

    return infiniteQueryOptions({
      queryFn: async ({ pageParam = 1, signal }) => {
        const response = await api.get(`/user-statuses`, {
          params: {
            ...params,
            limit,
            skip: (pageParam - 1) * (limit as number),
          },
          signal,
        });

        return response.data;
      },
      queryKey: PARTICIPANT_KEYS.userStatuses({
        params: {
          ...params,
          limit,
        },
      }),
      initialPageParam: 1,
      getNextPageParam: (lastPage, _, lastPageParam) => {
        if (lastPage.items.length < (limit as number)) {
          return undefined;
        }

        return lastPageParam + 1;
      },
      select: (
        data: InfiniteData<
          {
            items: {
              id: number;
              type: string;
            }[];
          },
          number
        >
      ) => {
        return createInfiniteDataTransform(data, (item) => {
          return {
            ...item,
            name: item.type,
          };
        });
      },
    });
  },
  infiniteParticipantsList: (params: { [key: string]: unknown }) => {
    const {
      limit = 20,
      all = 'true',
      orderDir = 'ASC',
      orderBy = 'name',
    } = params ?? {};
    return infiniteQueryOptions({
      queryFn: async ({ pageParam = 1, signal }) => {
        const response = await api.get(`/participants`, {
          params: {
            ...params,
            limit,
            all,
            orderBy,
            orderDir,
            skip: (pageParam - 1) * (limit as number),
          },
          signal,
        });

        return response.data;
      },
      queryKey: PARTICIPANT_KEYS.list({
        params: {
          ...params,
          limit,
          all,
          orderBy,
          orderDir,
        },
      }),
      initialPageParam: 1,
      getNextPageParam: (lastPage, _, lastPageParam) => {
        if (lastPage.items.length < (limit as number)) {
          return undefined;
        }

        return lastPageParam + 1;
      },
      select: addFullNameToDataItems,
    });
  },
};

export interface ParticipantSearchItem {
  userId: number;
  firstName: string;
  lastName: string;
  startDate?: number;
  statuses?: Array<string>;
  comp?: string;
  _snippetResult?: Record<
    MatchTypes,
    {
      value: string;
      matchLevel: 'none' | 'full' | 'partial';
    }
  >;
  linkedin?: string;
  finalResume?: string;
  headshot?: {
    location: string;
  };
  participantManager?: {
    id: number;
    firstName: string;
    lastName: string;
    headshot?: {
      location: string;
    };
  };
}
type ParticipantSearchResponse = PaginatedResponse<ParticipantSearchItem>;
export type HighlightMatch = NonNullable<
  ParticipantSearchItem['_snippetResult']
>[MatchTypes];

export const PARTICIPANT_SEARCH_SCOPES_OPTIONS = {
  Name: 'participant.name',
  'One Liner': 'participant.oneLiner',
  Email: 'participant.email',
} as const;
export const OTHER_SCOPES_OPTIONS = {
  Resume: 'resume.content',
  Application: 'application.content',
  Reference: 'reference.content',
  'Action Item': 'actionItem.content',
} as const;

export const ALL_SCOPE_OPTIONS = {
  ...PARTICIPANT_SEARCH_SCOPES_OPTIONS,
  ...OTHER_SCOPES_OPTIONS,
} as const;

export type MatchTypes =
  (typeof ALL_SCOPE_OPTIONS)[keyof typeof ALL_SCOPE_OPTIONS];
