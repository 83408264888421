/** Custom cell renderer for items in the data table */
import { rem } from 'polished';
import styled from 'styled-components';
import screen from 'superior-mq';
import { bp } from '../../styles/helpers';

const ColumnName = styled.div`
  font-weight: 600;
  padding: 20px 8px 20px 0;
  flex: 1 0 auto;

  ${screen.above(
    bp.portrait,
    `
    display: none;
  `
  )}

  ${screen.below(
    bp.mobileMid,
    `
    font-size: ${rem(12)};
  `
  )}
`;

const ColumnValue = styled.div`
  ${screen.below(
    bp.portrait,
    `
    text-align: right;
  `
  )}
`;

interface DataCellProps extends React.ComponentPropsWithoutRef<'div'> {
  name: string;
  value?: string | number;
  children?: React.ReactNode;
}
const DataCell = ({ name, value, children, ...rest }: DataCellProps) => {
  return (
    <>
      <ColumnName>{name}</ColumnName>
      <ColumnValue {...rest}>{children || value}</ColumnValue>
    </>
  );
};

DataCell.ColumnName = ColumnName;

export default DataCell;
