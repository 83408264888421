import { useSuspenseQuery } from '@tanstack/react-query';
import { PARTICIPANT_QUERIES } from 'api/participants';
import { UserAvatar } from 'components/ds/Avatar';
import { DropdownMenuItem } from 'components/ds/DropdownMenu';
import {
  LayoutSheet,
  Sheet,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from 'components/ds/Sheet';
import { Skeleton } from 'components/ds/Skeleton';
import { LoadingContainer } from 'components/ds/Spinner';
import { Icon, IconUse } from 'components/ds/icons/Icon';
import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useParams } from 'react-router-dom';
import { ActivateParticipantAccountForm } from './ActivateParticipantAccountForm';

export function ActivateParticipantAccountSheet() {
  const [open, setOpen] = React.useState(false);
  const { participantId } = useParams();

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>
        <DropdownMenuItem
          onSelect={(event) => {
            // Prevent the dropdown from closing when the delete button is clicked.
            event.preventDefault();
          }}
        >
          <Icon className="h-4 w-4">
            <IconUse id="user-add-line" />
          </Icon>
          <span className="inline-block truncate px-1.5">Activate Account</span>
        </DropdownMenuItem>
      </SheetTrigger>
      <LayoutSheet size="lg" className="gap-0">
        <SheetHeader className="border-none">
          <div className="items-center stack-x-4">
            {participantId && (
              <ErrorBoundary
                fallback={<Skeleton className="h-10 w-10 rounded-full" />}
              >
                <React.Suspense
                  fallback={<Skeleton className="h-10 w-10 rounded-full" />}
                >
                  <ActivateParticipantAccountSheetHeader
                    participantId={participantId}
                  />
                </React.Suspense>
              </ErrorBoundary>
            )}

            <SheetTitle>Activate Account</SheetTitle>
          </div>
        </SheetHeader>
        <div className="relative z-10 p-6">
          <React.Suspense
            fallback={
              <LoadingContainer className="bg-transparent" level="component" />
            }
          >
            {participantId != null && (
              <ActivateParticipantAccountForm
                participantId={participantId}
                onSuccessfulSubmit={() => {
                  setOpen(false);
                }}
              />
            )}
          </React.Suspense>
        </div>
      </LayoutSheet>
    </Sheet>
  );
}

function ActivateParticipantAccountSheetHeader({
  participantId,
}: {
  participantId: string;
}) {
  const { data: participant } = useSuspenseQuery({
    ...PARTICIPANT_QUERIES.participant(participantId),
  });

  return (
    <UserAvatar
      size="lg"
      color="secondary"
      headshot={participant?.headshot?.location ?? null}
      alt={`${participant?.firstName} ${participant?.lastName}`}
      fallback={[participant?.firstName ?? '', participant?.lastName ?? '']}
    />
  );
}
