import { Auth } from 'aws-amplify';
import { LoaderFunctionArgs, redirect } from 'react-router-dom';

export async function authenticationGuardLoader(args: LoaderFunctionArgs) {
  try {
    // Check if the user is logged in. Will throw an error if the user is not.
    await Auth.currentAuthenticatedUser();

    return null;
  } catch (_e) {
    // Redirect unauthenticated users to the login page.
    // Store the original URL so we can redirect back to it after login.
    const { params } = args;
    const originUrl = params['*'] ? `/${params['*']}` : '';
    return redirect(`/login?redirectTo=${originUrl}`);
  }
}
