import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { USER_OPTIONS } from 'api/user/user';
import { USER_TYPES } from 'api/user/user.constants';
import { Auth } from 'aws-amplify';
import { Button } from 'components/ds/Button';
import { Spinner } from 'components/ds/Spinner';
import { toast } from 'components/ds/Toast/Toast';
import Input from 'components/forms/Input';
import { headingOne } from 'components/H';
import { IconRefresh } from 'components/icons/IconRefresh';
import { QRCodeSVG } from 'qrcode.react';
import { Helmet } from 'react-helmet';
import { Controller, useForm } from 'react-hook-form';
import { Link, redirect, useNavigate } from 'react-router-dom';

async function getQRInfo() {
  const user = await Auth.currentAuthenticatedUser();
  const secretCode = await Auth.setupTOTP(user);
  const url = `otpauth://totp/AWSCognito:${
    user.username
  }?secret=${secretCode}&issuer=${encodeURI('AWSCognito')}`;

  return {
    url,
    secretCode,
  };
}

async function enableTwoFactorAuth(challengeAnswer: string) {
  try {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.verifyTotpToken(user, challengeAnswer);

    // Set the user's preferred MFA to TOTP
    await Auth.setPreferredMFA(user, 'TOTP');
  } catch (err) {
    throw err;
  }
}

export async function twoFactorAuthLoader(queryClient: QueryClient) {
  const userProfile = await queryClient.ensureQueryData(USER_OPTIONS.user());
  const user = await Auth.currentAuthenticatedUser();

  if (
    user.preferredMFA !== 'SOFTWARE_TOKEN_MFA' &&
    userProfile.type === USER_TYPES.breakline
  ) {
    return null;
  }

  // If the user has already enabled 2FA, redirect them to the account page
  return redirect('/account');
}

export function TwoFactorAuth() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    data: qrInfo,
    isPending,
    isError,
    refetch,
  } = useQuery({
    queryKey: ['qr-info'],
    queryFn: getQRInfo,
    staleTime: 0,
  });
  const enableTwoFactormutation = useMutation({
    mutationFn: enableTwoFactorAuth,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: USER_OPTIONS.currentUser().queryKey,
      });
      navigate('/account', { replace: true });
      toast.success('Two factor authentication enabled');
    },
    onError: (err) => {
      if ('code' in err && err.code === 'EnableSoftwareTokenMFAException') {
        toast.error('Invalid OTP code.', {
          description:
            'Please try again or refresh the page to get a new QR code.',
        });
        return;
      }

      toast.error('Something went wrong.', {
        description:
          'Please try again or refresh the page to get a new QR code.',
      });
    },
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      otp: '',
    },
  });

  const { url, secretCode } = qrInfo ?? {};

  return (
    <div className="content-container">
      <Helmet title="Two Factor Authentication (2FA)" />
      <h1 className={headingOne({ size: 'smaller' })}>
        Enable Two Factor Authentication (2FA)
      </h1>
      <Link to="/account">Back to Account Settings</Link>
      <form
        onSubmit={handleSubmit((data) => {
          const { otp } = data;

          enableTwoFactormutation.mutate(otp);
        })}
        className="py-8 stack-y-2 md:py-10"
      >
        <div className="stack-y-8">
          <p className="m-0 max-w-prose font-primary text-base">
            Use an authenticator app such as Google Authenticator, 1Password or
            any other OTP (One-time password) client to verify your identity.
          </p>

          <div className="max-w-prose rounded-md border border-solid border-border-primary bg-[#F7F7F6] px-4 py-5 stack-y-3">
            <ol className="stack-y-4">
              <li>
                <div className="stack-y-2">
                  <h3 className="m-0 max-w-prose font-primary text-base font-bold text-black">
                    Install an authenticator app on your phone or computer
                  </h3>
                  <div>
                    <p className="m-0 max-w-prose font-primary text-base text-slate-600">
                      We recommend using OTP clients such as Google
                      Authenticator or 1Password.
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="stack-y-2">
                  <h3 className="m-0 max-w-prose font-primary text-base font-bold text-black">
                    Configure your two factor client
                  </h3>
                  <div className="stack-y-6">
                    <div className="stack-y-2">
                      <p className="m-0 max-w-prose font-primary text-base text-slate-600">
                        Using your authenticator app, scan the QR code below.
                      </p>

                      <div className="grid h-[128px] w-[128px] place-items-center bg-white">
                        {isPending && <Spinner size="sm" />}
                        {url && <QRCodeSVG value={url} />}
                        {isError && !url && (
                          <div className="items-center stack-y-1">
                            <p className="m-0 max-w-prose text-center font-primary text-xs text-red-600">
                              Error loading <br />
                              QR code
                            </p>
                            <div>
                              <button
                                className="grid cursor-pointer place-items-center rounded-md border-none bg-primary-green p-1 text-white"
                                onClick={() => refetch()}
                              >
                                <IconRefresh className="h-4 w-4" />
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="stack-y-2">
                      <p className="m-0 max-w-prose font-primary text-base text-slate-600">
                        Alternatively, you can manually enter the code below.
                      </p>
                      <pre className="m-0 inline-block w-min rounded-md bg-white px-4 py-2">
                        <code className="text-base text-primary-green">
                          {secretCode}
                        </code>
                      </pre>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="stack-y-2">
                  <h3 className="m-0 max-w-prose font-primary text-base font-bold text-black">
                    Verify the configuration of your two factor client
                  </h3>
                  <p className="m-0 max-w-prose font-primary text-base text-slate-600">
                    Let's confirm that your two factor client is configured
                    correctly. Enter the OTP code from your authenticator app in
                    the field below.
                  </p>
                  <Controller
                    name="otp"
                    control={control}
                    rules={{
                      required: 'Please enter your OTP code',
                      minLength: {
                        value: 6,
                        message: 'Please enter a valid OTP code',
                      },
                    }}
                    render={({ field }) => {
                      return (
                        <Input
                          {...field}
                          // @ts-expect-error - Component type are incorrect
                          type="text"
                          placeholder="123456"
                          errors={errors}
                        />
                      );
                    }}
                  />
                </div>
              </li>
            </ol>
          </div>
          <div>
            <Button
              type="submit"
              disabled={enableTwoFactormutation.isPending}
              isLoading={enableTwoFactormutation.isPending}
            >
              Enable 2FA
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}
