import { useMutation, useQueryClient } from '@tanstack/react-query';
import { PARTICIPANT_QUERIES } from 'api/participants';
import api from 'dataService/api';
import { useSWRConfig } from 'swr';

function checkParticipantKey(args: string | [string, unknown]) {
  return typeof args === 'string' && args.startsWith('participants/');
}

export function useActivateParticipantAccount(id: string | number) {
  const queryClient = useQueryClient();
  const { mutate } = useSWRConfig();

  return useMutation<unknown, Error, Record<string, unknown>>({
    mutationFn: async (data) => {
      const response = await api.post(`users/${id}/activate`, {
        ...data,
        // We always want to send a formal welcome email when activating a user account
        formalWelcome: true,
      });

      return response.data;
    },
    onSuccess: () => {
      return Promise.all([
        mutate(checkParticipantKey),
        queryClient.invalidateQueries({
          queryKey: PARTICIPANT_QUERIES.participant(id.toString()).queryKey,
        }),
      ]);
    },
  });
}

export function useReactivateParticipantAccount(id: string | number) {
  const { mutate } = useSWRConfig();

  return useMutation<unknown, Error, Record<string, unknown>>({
    mutationFn: async (data) => {
      const response = await api.post(`users/${id}/activate`, {
        ...data,
        // We don't want to send a formal welcome email when reactivating a user account
        formalWelcome: false,
      });

      return response.data;
    },
    onSuccess: () => {
      mutate(checkParticipantKey);
    },
  });
}

export function useDeactivateAccount(id: number) {
  const { mutate } = useSWRConfig();

  return useMutation({
    mutationFn: async () => {
      await api.post(`/users/${id}/deactivate`, {});
    },
    onSuccess: () => {
      mutate(checkParticipantKey);
    },
  });
}
