import * as PopoverPrimitive from '@radix-ui/react-popover';
import { Icon, IconUse } from 'components/ds/icons/Icon';
import * as React from 'react';
import { twMerge } from 'tailwind-merge';
import { tv, VariantProps } from 'tailwind-variants';

interface FilterPopoverTriggerProps
  extends React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Trigger>,
    VariantProps<typeof trigger> {
  onClear?: () => void;
}
export const FilterPopoverTrigger = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Trigger>,
  FilterPopoverTriggerProps
>(({ className, onClear, status, children, ...rest }, ref) => {
  const { container } = trigger({ status });

  return (
    <div className="relative inline-block max-w-full">
      <PopoverPrimitive.Trigger asChild>
        <button {...rest} ref={ref} className={container({ className })}>
          <Icon
            className={twMerge(
              'h-4 w-4 place-items-center',
              status === 'active' ? 'hidden' : 'grid'
            )}
            aria-hidden
          >
            <IconUse id="add-circle-line" />
          </Icon>
          <span className="items-center stack-x-2">{children}</span>
        </button>
      </PopoverPrimitive.Trigger>
      <button
        aria-label="Clear filters"
        onClick={() => {
          onClear?.();
        }}
        className={twMerge(
          'absolute right-2 grid h-5 w-5 place-items-center rounded-md border-none bg-transparent p-0 text-ds-text-primary outline-none transition-colors inset-y-center hover:bg-ds-primary-dark hover:text-ds-neutral-0 focus-visible:bg-ds-primary-dark focus-visible:text-ds-neutral-0 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-transparent',
          status === 'active' ? 'grid' : 'hidden'
        )}
      >
        <Icon aria-hidden className="h-4 w-4">
          <IconUse id="close-line" />
        </Icon>
      </button>
    </div>
  );
});
FilterPopoverTrigger.displayName = 'FilterPopoverTrigger';

const trigger = tv({
  slots: {
    container:
      'focus-visible:outline-none focus-visible:border-ds-stroke-secondary focus-visible:ring-2 focus-visible:ring-ds-stroke-tertiary text-sm border rounded-md h-[32px] px-3 py-1.5 transition-colors stack-x-1 items-center disabled:cursor-not-allowed disabled:opacity-60 whitespace-nowrap',
  },
  variants: {
    status: {
      active: {
        container:
          'bg-ds-primary-lighter text-ds-text-primary border-transparent pr-[calc((theme(spacing.2)_*_2)_+_theme(spacing.5))] font-medium',
      },
      inactive: {
        container: 'bg-ds-bg-weaker border-dashed border-ds-stroke-secondary',
      },
    },
  },
  defaultVariants: {
    status: 'inactive',
  },
});
