import { cover, size } from 'polished';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const RatioWrap = styled.div`
  position: relative;

  &::before {
    content: '';
    display: block;
    padding-bottom: var(--aspect-ratio);
    width: 100%;
  }

  ${(props) =>
    props.childrenFillSpace &&
    css`
      > * {
        ${cover()}
        ${size('100%')}
      }
    `}
`;

const AspectRatio = ({
  width = 16,
  height = 9,
  style,
  childrenFillSpace = true,
  ...props
}) => {
  const ratio = `${(height / width) * 100}%`;

  return (
    <RatioWrap
      childrenFillSpace={childrenFillSpace}
      style={{
        '--aspect-ratio': ratio,
        ...style,
      }}
      {...props}
    />
  );
};

AspectRatio.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  childrenFillSpace: PropTypes.bool,
  style: PropTypes.object,
};

export default AspectRatio;
