import { tabsStyles } from 'components/ds/Tabs';
import { NavLink, NavLinkProps } from 'react-router-dom';

export function TabNavigation({
  className,
  ...rest
}: React.ComponentPropsWithoutRef<'nav'>) {
  const { list } = tabsStyles();

  return <nav className={list({ className })} {...rest} />;
}

interface TabNavigationItemProps extends Omit<NavLinkProps, 'className'> {
  className?: string;
}
export function TabNavigationItem({
  className,
  ...rest
}: TabNavigationItemProps) {
  const { trigger } = tabsStyles();

  return <NavLink className={trigger()} {...rest} />;
}
