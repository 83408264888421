import { getOptionFromValue, interviewStatusOptions } from 'util/forms';

export const interviewStages = [
  { name: 'Referred', value: '0' },
  { name: 'Phone Screen', value: '1' },
  { name: 'Mid Process', value: '2,12' },
  { name: 'Assignment', value: '13' },
  { name: 'Onsite', value: '14' },
  { name: 'Final Round', value: '15' },
  { name: 'Offer Extended', value: '16' },
];

// get status of interview
export const getStatus = (interview) => {
  if (interview.rounds !== undefined && interview.rounds.length > 0) {
    const sortedRounds = interview.rounds.sort((a, b) =>
      a.id < b.id ? 1 : -1
    );
    const round =
      sortedRounds[0].type?.name === interview.latestStage
        ? sortedRounds[0]
        : null;

    if (round?.status) {
      return getOptionFromValue(round.status, interviewStatusOptions)?.label;
    }

    return 'N/A';
  } else if (interview.active) {
    return 'Active';
  }
  return 'N/A';
};
