import { format, isFuture, parseISO } from 'date-fns';
import { jsPDF } from 'jspdf';

interface Participant {
  id: string;
  firstName: string;
  lastName: string;
  headshot?: {
    location?: string;
  };
  profile?: {
    linkedin?: string;
    finalResume?: string;
    oneLiner: string;
    skills: {
      name: string;
    }[];
    tracks: {
      name: string;
    }[];
    dateAvailable: string;
  };
}

type Participants = ({ value: Participant } | { participant: Participant })[];

export async function generatePdf(
  documentTitle: string,
  participants: Participants
) {
  const BREAKLINE_BLUE = '#339999';
  const BLACK = '#000';
  const DARK_TEXT = '#404040';
  const GRAY_BORDER = '#BBB';
  const LIGHT_GRAY = '#DDE9E9';
  const DOC_MARGIN = 40;
  const DOC_WIDTH = 379;
  const TITLE_Y = 53;
  const BIO_HEIGHT = 100;
  const PARTICIPANT_GROUP_HEIGHT = 20 + 10 + 20 + (BIO_HEIGHT + 20); // name + tag + bio + divider
  const PARTIPANTS_PER_PAGE = 3;
  const participantGroups = chunk(participants, PARTIPANTS_PER_PAGE);
  const AVATAR_WIDTH = 25;

  const ELEMENT_COORDINATES = {
    title: {
      x: DOC_MARGIN,
      y: TITLE_Y,
    },
    disclaimer: {
      x: DOC_MARGIN + DOC_WIDTH,
      y: () => doc.internal.pageSize.getHeight() - 20,
    },
    logo: {
      x: 334,
      y: 20,
    },
    participant(num: number) {
      return {
        avatar: {
          x: DOC_MARGIN,
          y: () => participantY(num) + 20,
        },
        name: {
          x: () => this.participant(num).avatar.x + AVATAR_WIDTH + 5, // 20px to the right of avatar (width) + 5px margin
          y: () => participantY(num) + 30,
        },
        tag: {
          x: () => this.participant(num).avatar.x + AVATAR_WIDTH + 5,
          y: () => this.participant(num).name.y() + 10, // 10px below participant name
        },
        links: {
          x: DOC_MARGIN + DOC_WIDTH,
          y: () => participantY(num) + 30,
        },
        availablitity: {
          x: DOC_MARGIN + DOC_WIDTH,
          y: () => this.participant(num).links.y() + 12, // 12px below participant links
        },
        bio: {
          x: DOC_MARGIN,
          y: () => this.participant(num).tag.y() + 20, // 30px below participant tag
        },
        divider: {
          x: DOC_MARGIN,
          y: () => this.participant(num).bio.y() + BIO_HEIGHT + 20, // 66px below participant bio + 20px margin
        },
      };
    },
  } as const;

  // <PROGRAM>
  const doc = new jsPDF({
    format: 'letter',
    unit: 'px',
  });

  setTitle(documentTitle);

  // Set logo for first page
  await setLogo();
  setDisclaimer();

  for (const group of participantGroups.entries()) {
    const [index, groupValue] = group;
    if (index > 0) {
      doc.addPage();
      // Set logo for all pages except first
      await setLogo();
      setDisclaimer();
    }

    for (const participant of groupValue.entries()) {
      try {
        const [index, participantValue] = participant;
        const isLastParticipant = index === groupValue.length - 1;
        let p;
        if ('participant' in participantValue) {
          p = participantValue.participant;
        }
        if ('value' in participantValue) {
          p = participantValue.value;
        }
        if (!p) {
          throw new Error('Participant not found');
        }
        await setParticipant(index + 1, p, !isLastParticipant);
      } catch (error) {
        if (error instanceof Error) {
          // apm.captureError(error);
        }
      }
    }
  }

  doc.save(documentTitle + '.pdf');

  // </PROGRAM>

  function participantY(num: number) {
    return TITLE_Y + (num - 1) * PARTICIPANT_GROUP_HEIGHT;
  }

  function setTitle(title: string) {
    doc.setTextColor(BLACK);
    doc.setFont('helvetica', 'bold');
    doc.setFontSize(14);
    doc.text(title, ELEMENT_COORDINATES.title.x, ELEMENT_COORDINATES.title.y);
  }

  async function setLogo() {
    try {
      const logo = await getBase64('/images/breakline-logo.png');
      doc.addImage(
        logo,
        'PNG',
        ELEMENT_COORDINATES.logo.x,
        ELEMENT_COORDINATES.logo.y,
        85,
        13
      );
    } catch (error) {
      if (error instanceof Error) {
        // apm.captureError(error);
      }
    }
  }

  function setDisclaimer() {
    doc.setTextColor(DARK_TEXT);
    doc.setFont('helvetica', 'italic');
    doc.setFontSize(8);
    doc.text(
      `If you don't have a BreakLine Account, please reach out to your BreakLine Partner Manager!`,
      ELEMENT_COORDINATES.disclaimer.x,
      ELEMENT_COORDINATES.disclaimer.y(),
      {
        align: 'right',
      }
    );
  }

  async function setParticipant(
    num: number,
    participant: Participant,
    renderDivider = true
  ) {
    // headshot
    if (participant?.headshot?.location) {
      try {
        const headshot = await createAvatarCanvas(
          await getBase64(participant.headshot.location)
        );
        doc.addImage(
          headshot,
          'PNG',
          ELEMENT_COORDINATES.participant(num).avatar.x,
          ELEMENT_COORDINATES.participant(num).avatar.y(),
          AVATAR_WIDTH,
          AVATAR_WIDTH
        );
      } catch (error) {
        if (error instanceof Error) {
          // apm.captureError(error);
        }
      }
    }

    // name
    doc.setTextColor(BLACK);
    doc.setFont('helvetica', 'bold');
    doc.setFontSize(10);
    doc.text(
      participant.firstName + ' ' + participant.lastName,
      ELEMENT_COORDINATES.participant(num).name.x(),
      ELEMENT_COORDINATES.participant(num).name.y()
    );

    // tag
    doc.setTextColor(DARK_TEXT);
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(10);
    doc.text(
      toPipeSeparatedList(
        participant.profile?.tracks.map((track) => track.name) ?? []
      ),
      ELEMENT_COORDINATES.participant(num).tag.x(),
      ELEMENT_COORDINATES.participant(num).tag.y()
    );

    // links
    const LINK_LABELS = {
      linkedin: 'LinkedIn',
      finalResume: 'Resume',
      id: 'Profile',
    } as const;

    const linksWithLabels = [
      {
        url: participant.profile?.linkedin ?? '',
        label: LINK_LABELS.linkedin,
        labelWidth: doc.getTextWidth(LINK_LABELS.linkedin),
      },
      {
        url: participant.profile?.finalResume ?? '',
        label: LINK_LABELS.finalResume,
        labelWidth: doc.getTextWidth(LINK_LABELS.finalResume),
      },
      {
        url: participant.id
          ? `https://app.breakline.org/participants/${participant.id}`
          : '',
        label: LINK_LABELS.id,
        labelWidth: doc.getTextWidth(LINK_LABELS.id),
      },
    ].filter((link) => link.url !== '');

    // Loop through relevant links and rendering them
    linksWithLabels.forEach((link, i) => {
      doc.setTextColor(BREAKLINE_BLUE);
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(10);
      const totalPreviousLabelWidth = linksWithLabels.reduce(
        (acc, curr, index) => {
          if (index < i) {
            // add up all previous label widths
            return acc + curr.labelWidth;
          }
          return acc;
        },
        0
      );

      // calculate x value for current link + a 10px margin between links
      const linkXValue =
        ELEMENT_COORDINATES.participant(num).links.x -
        totalPreviousLabelWidth -
        i * 10;

      // add the link to the pdf
      doc.textWithLink(
        link.label,
        linkXValue,
        ELEMENT_COORDINATES.participant(num).links.y(),
        {
          url: link.url,
          align: 'right',
        }
      );

      // add line divider between links
      if (i > 0) {
        doc.setDrawColor(LIGHT_GRAY);
        doc.setLineWidth(0.5);
        doc.line(
          // x value of link - 5px to the left of link label
          linkXValue + 5,

          // y value of link - height of link label
          ELEMENT_COORDINATES.participant(num).links.y() -
            doc.getTextDimensions(link.label).h,

          // x value of link - 5px to the left of link label
          linkXValue + 5,

          // y value of link - height of link label + 10px height of divider
          ELEMENT_COORDINATES.participant(num).links.y() -
            doc.getTextDimensions(link.label).h +
            10
        );
      }
    });

    // add availability

    doc.setTextColor(DARK_TEXT);
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(10);
    doc.text(
      getAvailabilityText(participant.profile?.dateAvailable ?? ''),
      ELEMENT_COORDINATES.participant(num).availablitity.x,
      ELEMENT_COORDINATES.participant(num).availablitity.y(),
      {
        align: 'right',
      }
    );

    const oneLinerParagraph = participant.profile?.oneLiner
      ? `${participant.firstName} is ${participant.profile.oneLiner}\n\n`
      : '';
    const skillsText =
      participant?.profile && participant.profile.skills.length > 0
        ? `${participant.firstName}'s skills include: ${toCommaSeparatedList(
            participant.profile.skills.map((skill) => skill.name).slice(0, 20)
          )}`
        : '';

    // bio
    doc.setTextColor(DARK_TEXT);
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(10);
    doc.text(
      oneLinerParagraph + skillsText,
      ELEMENT_COORDINATES.participant(num).bio.x,
      ELEMENT_COORDINATES.participant(num).bio.y(),
      {
        maxWidth: DOC_WIDTH,
      }
    );

    if (renderDivider) {
      // divider
      doc.setDrawColor(GRAY_BORDER);
      doc.setLineWidth(0.5);
      doc.line(
        ELEMENT_COORDINATES.participant(num).divider.x,
        ELEMENT_COORDINATES.participant(num).divider.y(),
        ELEMENT_COORDINATES.participant(num).divider.x + DOC_WIDTH,
        ELEMENT_COORDINATES.participant(num).divider.y()
      );
    }
  }
}

async function getBase64(path: string) {
  const response = await fetch(path, {
    headers: { 'Cache-Control': 'no-cache' },
  });
  const blob = await response.blob();
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise<string>((resolve, reject) => {
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.onerror = () => {
      reject(`Error occurred reading file: ${path}`);
    };
  });
}

async function createAvatarCanvas(base64: string) {
  try {
    const img = new Image();
    img.src = base64;
    await img.decode();
    const canvas = document.createElement('canvas');
    canvas.width = 100;
    canvas.height = 100;
    const ctx = canvas.getContext('2d')!;
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.save();
    ctx.beginPath();
    ctx.arc(50, 50, 50, 0, Math.PI * 2, false);
    ctx.clip();
    ctx.drawImage(img, 0, 0, 100, 100);
    ctx.restore();
    return canvas;
  } catch (error) {
    throw new Error('Error loading participant headshot.');
  }
}

function chunk<T>(arr: T[], size: number) {
  const chunked_arr = [];
  let index = 0;
  while (index < arr.length) {
    chunked_arr.push(arr.slice(index, size + index));
    index += size;
  }
  return chunked_arr;
}

function toCommaSeparatedList(arr: string[]) {
  if (arr.length === 1) {
    return arr[0];
  }

  if (arr.length === 2) {
    return arr.join(' and ');
  }

  const last = arr[arr.length - 1];
  const rest = arr.slice(0, -1);
  return `${rest.join(', ')}, and ${last}`;
}

function toPipeSeparatedList(arr: string[]) {
  return arr.join(' | ');
}

function getAvailabilityText(dateAvailable: string) {
  const fmt = 'MMMM d, yyyy';
  const date = parseISO(dateAvailable);
  const tag = 'Available';

  if (!dateAvailable) return `N/A`;

  if (isFuture(date)) return `${tag} ${format(date, fmt)}`;

  return 'Available Now';
}
