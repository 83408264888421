import * as React from 'react';

import Input from './Input';

const DateInput = React.forwardRef((props, ref) => {
  return (
    <Input
      {...props}
      type={props.useTime ? 'datetime-local' : 'date'}
      ref={ref}
    />
  );
});

DateInput.displayName = 'DateInput';

export default DateInput;
