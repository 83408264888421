import { type DialogProps } from '@radix-ui/react-dialog';
import * as CommandPrimitive from 'cmdk';
import { Dialog, DialogContent } from 'components/ds/Dialog';
import { Icon, IconUse } from 'components/ds/icons/Icon';
import { IconLoader } from 'components/icons/IconLoader/IconLoader';
import * as React from 'react';
import { twMerge } from 'tailwind-merge';

export const Command = CommandPrimitive.Command;

interface CommandDialogProps extends DialogProps {}
export const CommandDialog = ({ children, ...rest }: CommandDialogProps) => {
  return (
    <Dialog {...rest}>
      <DialogContent className="top-[15%] -translate-y-0 overflow-clip p-0 data-[state=closed]:!slide-out-to-top-[13%] data-[state=open]:!slide-in-from-top-[13%]">
        {children}
      </DialogContent>
    </Dialog>
  );
};

interface CommandInputProps
  extends React.ComponentPropsWithoutRef<typeof CommandPrimitive.CommandInput> {
  loading?: boolean;
}
export const CommandInput = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.CommandInput>,
  CommandInputProps
>(({ className, loading = false, ...rest }, ref) => (
  <div className="flex items-center border-b px-3" cmdk-input-wrapper="">
    <div className="mr-2 h-4 w-4 shrink-0 opacity-50">
      {loading ? (
        <IconLoader animate className="h-4 w-4" />
      ) : (
        <Icon className="h-4 w-4">
          <IconUse id="search-line" />
        </Icon>
      )}
    </div>
    <CommandPrimitive.CommandInput
      ref={ref}
      className={twMerge(
        'flex h-12 w-full rounded-md bg-transparent text-sm outline-none placeholder:text-ds-text-tertiary disabled:cursor-not-allowed disabled:opacity-50',
        className
      )}
      {...rest}
    />
  </div>
));
CommandInput.displayName = CommandPrimitive.CommandInput.displayName;

export const CommandList = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.CommandList>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.CommandList>
>(({ className, ...rest }, ref) => (
  <CommandPrimitive.CommandList
    ref={ref}
    className={twMerge(
      'max-h-[300px] overflow-y-auto overflow-x-hidden',
      className
    )}
    {...rest}
  />
));
CommandList.displayName = CommandPrimitive.CommandList.displayName;

export const CommandEmpty = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.CommandEmpty>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.CommandEmpty>
>(({ className, ...rest }, ref) => (
  <CommandPrimitive.CommandEmpty
    ref={ref}
    className={twMerge(
      'py-6 text-center text-sm text-ds-text-primary',
      className
    )}
    {...rest}
  />
));
CommandEmpty.displayName = CommandPrimitive.CommandEmpty.displayName;

export const CommandGroup = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.CommandGroup>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.CommandGroup>
>(({ className, ...rest }, ref) => (
  <CommandPrimitive.CommandGroup
    ref={ref}
    className={twMerge(
      'overflow-hidden px-2 py-1 text-ds-text-primary',
      className
    )}
    {...rest}
  />
));
CommandGroup.displayName = CommandPrimitive.CommandGroup.displayName;

export const CommandGroupHeading = React.forwardRef<
  React.ElementRef<'span'>,
  React.ComponentPropsWithoutRef<'span'>
>(({ className, ...rest }, ref) => (
  <span
    ref={ref}
    className={twMerge(
      'block px-2 py-1.5 text-xs font-medium text-ds-text-tertiary',
      className
    )}
    {...rest}
  />
));
CommandGroupHeading.displayName = 'CommandGroupHeading';

export const CommandSeparator = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.CommandSeparator>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.CommandSeparator>
>(({ className, ...rest }, ref) => (
  <CommandPrimitive.CommandSeparator
    ref={ref}
    className={twMerge('-mx-1 h-px bg-ds-stroke-tertiary', className)}
    {...rest}
  />
));
CommandSeparator.displayName = CommandPrimitive.CommandSeparator.displayName;

export const CommandItem = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.CommandItem>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.CommandItem>
>(({ className, ...rest }, ref) => (
  <CommandPrimitive.CommandItem
    ref={ref}
    className={twMerge(
      'relative flex cursor-default select-none items-center rounded-md px-2 py-3 text-sm outline-none aria-selected:bg-ds-bg-weaker data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
      className
    )}
    {...rest}
  />
));
CommandItem.displayName = CommandPrimitive.CommandItem.displayName;
