import Tooltip from 'components/Tooltip';
import React from 'react';
import styled from 'styled-components';

const StyledTag = styled.div`
  --tag-color: var(--gray-text);
  --tag-bg: rgba(64, 64, 64, 0.2);

  display: inline-block;
  padding: 1px 7px;
  background: var(--tag-bg);
  border-radius: 8px;
  color: var(--tag-color);
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;
  margin-right: 3px;
  vertical-align: middle;
`;

interface TagProps extends React.HTMLAttributes<HTMLDivElement> {
  context?: string;
  children?: React.ReactNode;
}
const Tag = ({ context, children, ...rest }: TagProps) => {
  return context ? (
    <Tooltip content={context}>
      <StyledTag {...rest}>{children}</StyledTag>
    </Tooltip>
  ) : (
    <StyledTag {...rest}>{children}</StyledTag>
  );
};

const themes = {
  greenDarkText: {
    color: 'var(--dark-gray)',
    bg: '#89db83',
  },
  greenWhiteText: {
    color: 'var(--white)',
    bg: '#22c13c',
  },
  redWhiteText: {
    color: 'var(--dark-gray)',
    bg: '#fd93ae',
  },
  tier1DarkText: {
    color: 'var(--dark-gray)',
    bg: '#cbf6bf',
  },
  tier2DarkText: {
    color: 'var(--dark-gray)',
    bg: '#bbf4e6',
  },
  tier3DarkText: {
    color: 'var(--dark-gray)',
    bg: '#caedfb',
  },
  lightRedDarkText: {
    color: 'var(--dark-gray)',
    bg: '#fed7e1',
  },
  purpleDarkText: {
    color: 'var(--dark-gray)',
    bg: '#c4a7fb',
  },
  lightTealDarkText: {
    color: 'var(--dark-gray)',
    bg: '#bbf4e6',
  },
  tealDarkText: {
    color: 'var(--dark-gray)',
    bg: '#68d8bc',
  },
  redDarkText: {
    color: 'var(--dark-gray)',
    bg: '#fd93ae',
  },
  darkRedWhiteText: {
    color: 'var(--white)',
    bg: '#f52956',
  },
  darkPinkWhiteText: {
    color: 'var(--white)',
    bg: '#fe94ae',
  },
  magentaWhiteText: {
    color: 'var(--white)',
    bg: '#fd17b6',
  },
  lightPinkWhiteText: {
    color: 'var(--white)',
    bg: '#ffd7e1',
  },
  darkOrangeDarkText: {
    color: 'var(--dark-gray)',
    bg: '#fe6531',
  },
  lightOrangeDarkText: {
    color: 'var(--dark-gray)',
    bg: '#fea07a',
  },
  yellowWhiteText: {
    color: 'var(--white)',
    bg: '#fbab2b',
  },
  yellowDarkText: {
    color: 'var(--dark-gray)',
    bg: '#ffd16d',
  },
  blueDarkText: {
    color: 'var(--dark-gray)',
    bg: '#1db7fb',
  },
  blueWhiteText: {
    color: 'var(--white)',
    bg: '#1eb6fb',
  },
  success: {
    color: 'var(--success-green)',
    bg: 'rgba(7, 149, 64, .2)',
  },
  'success-light': {
    color: 'var(--white)',
    bg: '#88db83',
  },
  error: {
    color: 'var(--error-red)',
    bg: 'rgba(173, 53, 20, .2)',
  },
  'error-light': {
    color: 'var(--white)',
    bg: '#fe94ae',
  },
  warning: {
    color: 'var(--yellow-orange)',
    bg: 'rgba(255, 175, 51, .2)',
  },
  'warning-light': {
    color: 'var(--white)',
    bg: 'rgba(255, 175, 51, .6)',
  },
  highWarning: {
    color: 'var(--brand-orange)',
    bg: 'rgba(239, 124, 82, .2)',
  },
  'highWarning-light': {
    color: 'var(--white)',
    bg: 'rgba(239, 124, 82, .6)',
  },
  general: {
    color: 'var(--dark-text)',
    bg: 'rgba(64, 64, 64, .2)',
  },
  'general-light': {
    color: 'var(--white)',
    bg: 'rgba(64, 64, 64, .6)',
  },
  heavilyRecruited: {
    color: 'var(--white)',
    bg: '#1EB6FB',
  },
  interviewing: {
    color: 'var(--white)',
    bg: '#0568F0',
  },
  earlyInSearch: {
    color: 'var(--white)',
    bg: '#10D8E3',
  },
  apex: {
    color: 'var(--white)',
    bg: '#FE8539',
  },
  maven: {
    color: 'var(--white)',
    bg: '#EC724E',
  },
  veteran: {
    color: 'var(--white)',
    bg: '#FDC556',
  },
  transcend: {
    color: 'var(--white)',
    bg: '#FEA63E',
  },
  userNotActivated: {
    color: 'var(--white)',
    bg: 'var(--error-red)',
  },
} as const;

type OptionsRecord = Record<string, keyof typeof themes>;

/**
 * This type represents the case when `value` and/or `options` are defined for a
 * given `TagController`.
 */
type TagValueAndOptions<TOptions extends OptionsRecord> = {
  options: TOptions;
  value: keyof TOptions;
};

/**
 * ## What's with all the generics?
 *
 * This uses a discriminated union to ensure that when either `options` or
 * `value` is defined it will strongly type check the other. If `options` or
 * `values` are _not_ defined then it fallback to their default values.
 *
 * @example
 *   <TagController
 *     options={{ option1: 'tier1DarkText', option2: 'tier2DarkText' }}
 *     value="option1" // This is strongly typed and must be a key of the options object
 *   />;
 */
type TagControllerProps<TOptions extends OptionsRecord> = (
  | TagValueAndOptions<TOptions>
  | {
      options?: undefined;
      value?: never;
    }
) & {
  valueModifier?: string;
  context?: string;
  children?: React.ReactNode;
};
export const TagController = <TOptions extends OptionsRecord>({
  value,
  options,
  valueModifier = '',
  context = '',
  children,
}: TagControllerProps<TOptions>) => {
  let theme: Readonly<{ color: string; bg: string }> = themes.general;

  if (options && value && options[value]) {
    theme = themes[options[value]];
  }

  return (
    <Tag
      context={context}
      style={
        {
          '--tag-color': theme?.color,
          '--tag-bg': theme?.bg,
        } as React.CSSProperties
      }
    >
      {value != null && typeof value !== 'symbol' && value}
      {valueModifier ? `- ${valueModifier}` : null}
      {children}
    </Tag>
  );
};
