import * as AlertDialogPrimitive from '@radix-ui/react-alert-dialog';
import { buttonStyles } from 'components/ds/Button';
import * as React from 'react';
import { heading } from 'styles/heading';
import { text } from 'styles/text';
import { twMerge } from 'tailwind-merge';
import { VariantProps } from 'tailwind-variants';

export const AlertDialog = AlertDialogPrimitive.Root;

export const AlertDialogTrigger = AlertDialogPrimitive.Trigger;

const AlertDialogPortal = AlertDialogPrimitive.Portal;

export const AlertDialogOverlay = React.forwardRef<
  React.ElementRef<typeof AlertDialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Overlay>
>(({ className, ...rest }, ref) => {
  return (
    <AlertDialogPrimitive.Overlay
      className={twMerge(
        'fixed inset-0 z-50 bg-ds-neutral-900/80  data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
        className
      )}
      {...rest}
      ref={ref}
    />
  );
});
AlertDialogOverlay.displayName = AlertDialogPrimitive.Overlay.displayName;

export const AlertDialogContent = React.forwardRef<
  React.ElementRef<typeof AlertDialogPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Content>
>(({ className, ...rest }, ref) => {
  return (
    <AlertDialogPortal>
      <AlertDialogOverlay>
        <AlertDialogPrimitive.Content
          ref={ref}
          className={twMerge(
            'fixed z-50 grid w-full max-w-lg gap-6 border border-ds-stroke-tertiary bg-ds-bg-foundation p-6 shadow-lg duration-200 inset-center data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg',
            className
          )}
          {...rest}
        />
      </AlertDialogOverlay>
    </AlertDialogPortal>
  );
});
AlertDialogContent.displayName = AlertDialogPrimitive.Content.displayName;

export function AlertDialogHeader({
  className,
  ...rest
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={twMerge('text-center stack-y-2 sm:text-left', className)}
      {...rest}
    />
  );
}
AlertDialogHeader.displayName = 'AlertDialogHeader';

export function AlertDialogFooter({
  className,
  ...rest
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={twMerge(
        'flex flex-col-reverse gap-2 sm:flex-row sm:justify-end sm:stack-x-2',
        className
      )}
      {...rest}
    />
  );
}
AlertDialogFooter.displayName = 'AlertDialogFooter';

export const AlertDialogTitle = React.forwardRef<
  React.ElementRef<typeof AlertDialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Title>
>(({ className, ...rest }, ref) => {
  return (
    <AlertDialogPrimitive.Title
      ref={ref}
      className={heading({ variant: '20', className })}
      {...rest}
    />
  );
});
AlertDialogTitle.displayName = AlertDialogPrimitive.Title.displayName;

export const AlertDialogDescription = React.forwardRef<
  React.ElementRef<typeof AlertDialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Description>
>(({ className, ...rest }, ref) => {
  return (
    <AlertDialogPrimitive.Description
      ref={ref}
      className={text({
        variant: '14',
        className: twMerge('text-ds-text-secondary', className),
      })}
      {...rest}
    />
  );
});
AlertDialogDescription.displayName =
  AlertDialogPrimitive.Description.displayName;

export const AlertDialogAction = React.forwardRef<
  React.ElementRef<typeof AlertDialogPrimitive.Action>,
  React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Action> &
    VariantProps<typeof buttonStyles>
>(({ className, variant, size, svgOnly, align, isLoading, ...rest }, ref) => {
  const { button } = buttonStyles({
    variant,
    size,
    svgOnly,
    align,
    isLoading,
  });

  return (
    <AlertDialogPrimitive.Action
      ref={ref}
      className={button({ variant: variant ?? 'destructive', className })}
      {...rest}
    />
  );
});
AlertDialogAction.displayName = AlertDialogPrimitive.Action.displayName;

export const AlertDialogCancel = React.forwardRef<
  React.ElementRef<typeof AlertDialogPrimitive.Cancel>,
  React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Cancel> &
    VariantProps<typeof buttonStyles>
>(({ className, variant, size, svgOnly, align, isLoading, ...rest }, ref) => {
  const { button } = buttonStyles({
    variant,
    size,
    svgOnly,
    align,
    isLoading,
  });

  return (
    <AlertDialogPrimitive.Cancel
      ref={ref}
      className={button({ variant: variant ?? 'outline', className })}
      {...rest}
    />
  );
});
AlertDialogCancel.displayName = AlertDialogPrimitive.Cancel.displayName;
