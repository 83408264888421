import { Checkbox } from 'components/ds/Checkbox';
import { Popover, PopoverContent } from 'components/ds/Popover';
import { Filter } from 'components/filters-new/types/filters';
import * as React from 'react';
import { useFilterGroup } from './context/FilterGroupContext';
import { useFilterSearch, useSelectedFilters } from './hooks/filters';
import {
  FilterActionApply,
  FilterActionCancel,
  FilterActions,
  FilterClearButton,
  FilterList,
  FilterListEmpty,
  FilterListItem,
  FilterSelectionPreview,
} from './ui/Filter';
import { FilterPopoverTrigger } from './ui/FilterPopover';
import { FilterSearchInput } from './ui/FilterSearchInput';

interface DependentFilterProps {
  groupId: string;
  label: string;
  filters: Filter[];
  isLoading?: boolean;
}
export function StaticFilter({
  groupId,
  label,
  filters,
  isLoading = false,
}: DependentFilterProps) {
  const {
    selectedFilters,
    handleCheckedChange,
    emptySelectedFilters,
    resetSelectedFilters,
    applySelectedFilters,
  } = useSelectedFilters(groupId);
  const { searchTerm, setSearchTerm, resetSearchTerm } = useFilterSearch();
  const { actions, filters: collection } = useFilterGroup();
  const initialFiltersFromStore = React.useMemo(() => {
    return collection[groupId] ?? [];
  }, [collection, groupId]);

  const filteredFilters = React.useMemo(() => {
    function filterInitial(f: Filter) {
      return !initialFiltersFromStore.some((sf) => sf.id === f.id);
    }
    const filteredFilters = filters.filter(filterInitial);

    return [...initialFiltersFromStore, ...filteredFilters].filter(
      (f) => f.name?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [filters, initialFiltersFromStore, searchTerm]);

  return (
    <Popover>
      <FilterPopoverTrigger
        disabled={isLoading}
        status={initialFiltersFromStore.length > 0 ? 'active' : 'inactive'}
        onClear={() => {
          actions.clearFilterGroup({ groupId });
          emptySelectedFilters();
        }}
      >
        <span>{label}</span>
        {initialFiltersFromStore.length > 0 && (
          <FilterSelectionPreview filters={initialFiltersFromStore} />
        )}
      </FilterPopoverTrigger>
      <PopoverContent>
        <FilterSearchInput
          type="search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onClearInput={() => resetSearchTerm()}
          placeholder={`Search ${label.toLowerCase()}...`}
          loading={isLoading}
        />
        <FilterList>
          {filteredFilters.length > 0 ? (
            <>
              {filteredFilters.map((filter) => {
                const id = `filter-${filter.id}`;

                return (
                  <FilterListItem key={filter.id} id={id} text={filter.name}>
                    <Checkbox
                      id={id}
                      className="shadow-none"
                      checked={selectedFilters.some((f) => f.id === filter.id)}
                      onCheckedChange={(checked) => {
                        handleCheckedChange(
                          {
                            id: filter.id,
                            name: filter.name,
                          },
                          Boolean(checked)
                        );
                      }}
                    />
                  </FilterListItem>
                );
              })}
            </>
          ) : (
            <FilterListEmpty isFetching={isLoading} />
          )}
        </FilterList>
        <FilterActions>
          <FilterActionCancel
            onCancel={() => {
              resetSelectedFilters();
              resetSearchTerm();
            }}
          />
          <FilterActionApply
            onApply={() => {
              applySelectedFilters();
              resetSearchTerm();
            }}
          />
        </FilterActions>
        {selectedFilters.length > 0 && (
          <FilterClearButton
            onClear={() => {
              actions.clearFilterGroup({ groupId });
              emptySelectedFilters();
              resetSearchTerm();
            }}
          />
        )}
      </PopoverContent>
    </Popover>
  );
}
