import type { QueryClient } from '@tanstack/react-query';
import { TopLevelRouteErrorHandler } from 'components/Error';
import { PublicAppLayout } from 'components/layout/PublicAppLayout';
import { Outlet, RouteObject } from 'react-router-dom';
import { lazyRetry } from 'util/lazyRetry';
import { loginLoader } from 'views/public/Login';
import { twoFactorAuthLoader } from 'views/public/TwoFactorAuth';

const PublicAppRoot = () => {
  return (
    <PublicAppLayout>
      <Outlet />
    </PublicAppLayout>
  );
};

export function PublicRoutes(queryClient: QueryClient): RouteObject[] {
  return [
    {
      path: '*',
      Component: PublicAppRoot,
      errorElement: <TopLevelRouteErrorHandler />,
      children: [
        // RESET PASSWORD
        {
          path: 'reset-password',
          errorElement: <TopLevelRouteErrorHandler />,
          lazy: async () => {
            const { ResetPasswordRequest } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "ResetPasswordRequest" */
                  'views/public/reset-password/ResetPasswordRequest'
                ),
              'ResetPasswordRequest'
            );
            return {
              Component: ResetPasswordRequest,
            };
          },
        },
        {
          path: 'reset-password/:resetToken',
          errorElement: <TopLevelRouteErrorHandler />,
          lazy: async () => {
            const { ResetPassword } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "ResetPassword" */
                  'views/public/reset-password/ResetPassword'
                ),
              'ResetPassword'
            );
            return {
              Component: ResetPassword,
            };
          },
        },
        // SETUP
        {
          path: 'setup/:resetToken',
          errorElement: <TopLevelRouteErrorHandler />,
          lazy: async () => {
            const { Setup } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "Setup" */
                  'views/public/Setup'
                ),
              'Setup'
            );
            return {
              Component: Setup,
            };
          },
        },

        // LOGIN
        {
          path: 'login',
          loader: loginLoader,
          errorElement: <TopLevelRouteErrorHandler />,
          lazy: async () => {
            const { Login } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "Login" */
                  'views/public/Login'
                ),
              'Login'
            );
            return {
              Component: Login,
            };
          },
        },

        // 2FA
        {
          path: '2fa',
          loader: twoFactorAuthLoader,
          errorElement: <TopLevelRouteErrorHandler />,
          lazy: async () => {
            const { TwoFactorAuth } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "TwoFactorAuth" */
                  'views/public/TwoFactorAuth'
                ),
              'TwoFactorAuth'
            );
            return {
              Component: TwoFactorAuth,
            };
          },
        },
      ],
    },
  ];
}
