export const gamePlanStatusOptions = [
  { label: 'Export Shortlist', value: 'Export' },
  { label: 'Not a Fit - Participant', value: 'Not a Fit - Participant' },
  { label: 'Not a Fit - Partner', value: 'Not a Fit - Partner' },
  { label: 'Role Closed', value: 'Role Closed' },
  { label: 'Role on Hold', value: 'Role on Hold' },
  { label: 'Keep Warm', value: 'Keep Warm' },
  { label: 'Confirm Partner Interest', value: 'Confirm Partner Interest' },
  {
    label: 'Confirm BreakLiner Interest',
    value: 'Confirm BreakLiner Interest',
  },
  { label: 'Company Pinged', value: 'Company Pinged' },
  { label: 'BreakLiner Pinged', value: 'BreakLiner Pinged' },
  { label: 'Second Look', value: 'Second Look' },
  { label: 'Partner Interest Confirmed', value: 'Partner Interest Confirmed' },
  {
    label: 'BreakLiner Interest Confirmed',
    value: 'BreakLiner Interest Confirmed',
  },
  { label: 'Interview Initiated', value: 'Interview Initiated' },
  { label: 'Mark as Visible to Participant', value: 'Visible', visible: true },
  { label: 'Mark as Hidden to Participant', value: 'Visible', visible: false },
  { label: 'Remove', value: 'Remove' },
];

export const participantBrainstormStatusOptions = [
  { label: 'Not a Fit - Participant', value: 'Not a Fit - Participant' },
  { label: 'Not a Fit - Partner', value: 'Not a Fit - Partner' },
  { label: 'Role Closed', value: 'Role Closed' },
  { label: 'Role on Hold', value: 'Role on Hold' },
  { label: 'Keep Warm', value: 'Keep Warm' },
  { label: 'Company Pinged', value: 'Company Pinged' },
  { label: 'Confirm Partner Interest', value: 'Confirm Partner Interest' },
  {
    label: 'Confirm BreakLiner Interest',
    value: 'Confirm BreakLiner Interest',
  },
  { label: 'BreakLiner Pinged', value: 'BreakLiner Pinged' },
  { label: 'Second Look', value: 'Second Look' },
  { label: 'Partner Interest Confirmed', value: 'Partner Interest Confirmed' },
  {
    label: 'BreakLiner Interest Confirmed',
    value: 'BreakLiner Interest Confirmed',
  },
  { label: 'Interview Initiated', value: 'Interview Initiated' },
  { label: 'Mark as Visible to Participant', value: 'Visible', visible: true },
  { label: 'Mark as Hidden to Participant', value: 'Visible', visible: false },
  { label: 'Remove', value: 'Remove' },
];

export const partnerIsOnHold = (status) => {
  if (status === 'on-hold') return true;
  if (status === 'retired') return true;
  return false;
};
