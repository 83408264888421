import { DevTool } from '@hookform/devtools';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { PARTICIPANT_QUERIES } from 'api/participants';
import { STAFF_QUERY_OPTIONS } from 'api/staff';
import { AsyncComboboxField } from 'components/ComboboxField/AsyncComboboxField';
import { Button } from 'components/ds/Button';
import { Checkbox } from 'components/ds/Checkbox';

import { USER_ADMIT_DECISIONS } from 'api/user/user.constants';
import { Badge } from 'components/ds/Badge';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'components/ds/Form';
import { Input } from 'components/ds/Input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ds/Select';
import { SheetClose } from 'components/ds/Sheet';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { idFromObject } from 'util/idFromObj';
import { z } from 'zod';
import { useReactivateParticipantAccount } from '../api/participant';

interface ReactivateParticipantAccountFormProps {
  participantId: string;
  onSuccessfulSubmit?: () => void;
}

export function ReactivateParticipantAccountForm({
  onSuccessfulSubmit,
  participantId,
}: ReactivateParticipantAccountFormProps) {
  const queryClient = useQueryClient();
  const { data: participant } = useSuspenseQuery({
    ...PARTICIPANT_QUERIES.participant(participantId),
  });
  const reactivateParticipantAccountMutation =
    useReactivateParticipantAccount(participantId);

  const form = useForm<ReactivateParticipantFormInput>({
    mode: 'onSubmit',
    resolver: zodResolver(ReactivateParticipantFormSchema),
    defaultValues: {
      ...FORM_DEFAULT_VALUES,
      ...(participant
        ? {
            firstName: participant.firstName,
            lastName: participant.lastName,
            email: participant.email,
            admitDecision: participant.admitDecision,
            originalResume: participant.userProfile?.originalResume ?? '',
            linkedin: participant.userProfile?.linkedin ?? '',
          }
        : {}),
      ...(participant?.exo
        ? {
            exoId: {
              id: participant.exo.id,
              name: `${participant.exo.firstName} ${participant.exo.lastName}`,
            },
          }
        : {}),
    },
    shouldUnregister: true,
  });
  const {
    control,
    handleSubmit,
    formState: { isValidating },
  } = form;

  function onSubmit(data: ReactivateParticipantFormOutput) {
    const { originalResume, linkedin, ...rest } = data;

    reactivateParticipantAccountMutation.mutate(
      {
        profile: { originalResume, linkedin },
        ...rest,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: PARTICIPANT_QUERIES.participant(participantId).queryKey,
          });
          onSuccessfulSubmit?.();
          toast.success('Email sent');
        },
        onError: () => {
          toast.error('Failed to send email');
        },
      }
    );
  }

  const isLoading =
    reactivateParticipantAccountMutation.isPending || isValidating;

  return (
    <Form {...form}>
      <div className="stack-y-6">
        <form
          id="reactivate-participant-account-form"
          onSubmit={handleSubmit((data) => {
            onSubmit(data as unknown as ReactivateParticipantFormOutput);
          })}
          className="grid grid-cols-[repeat(auto-fill,minmax(280px,1fr))] gap-6"
        >
          <DevTool control={control} placement="top-left" />
          <FormField
            control={control}
            name="firstName"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>First Name</FormLabel>
                  <FormControl>
                    <Input {...field} type="text" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
          <FormField
            control={control}
            name="lastName"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>Last Name</FormLabel>
                  <FormControl>
                    <Input {...field} type="text" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
          <FormField
            control={control}
            name="email"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input {...field} type="text" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
          <FormField
            control={control}
            name="linkedin"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>LinkedIn URL</FormLabel>
                  <FormControl>
                    <Input {...field} type="text" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
          <FormField
            control={control}
            name="originalResume"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>Original Resume URL</FormLabel>
                  <FormControl>
                    <Input {...field} type="text" maxLength={255} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
          <FormField
            control={control}
            name="exoId"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>Participant Manager</FormLabel>
                  <FormControl>
                    <AsyncComboboxField
                      {...field}
                      displayName="Participant Manager"
                      infiniteQueryOptions={
                        STAFF_QUERY_OPTIONS.infiniteStaffList
                      }
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />

          <FormField
            control={control}
            name="admitDecision"
            render={({ field: { value, onChange, ...rest } }) => {
              return (
                <FormItem>
                  <FormLabel>Admit Decision</FormLabel>
                  <Select onValueChange={onChange} defaultValue={value}>
                    <FormControl>
                      <SelectTrigger {...rest}>
                        <SelectValue placeholder="Select Admit Decision" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {Object.values(USER_ADMIT_DECISIONS)?.map((item) => {
                        return (
                          <SelectItem key={item} value={item}>
                            {item}
                          </SelectItem>
                        );
                      })}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              );
            }}
          />

          <FormItem className="col-span-full">
            <FormLabel>Location(s)</FormLabel>

            <div className="stack-x-1/inline">
              {participant?.userProfile?.locations?.map((location) => (
                <Badge key={location.id}>{location.name}</Badge>
              ))}
            </div>

            <FormDescription>
              Edit the participant to change locations
            </FormDescription>
          </FormItem>

          <FormField
            control={control}
            name="reviewed"
            render={({ field: { value, onChange, ...rest } }) => {
              return (
                <FormItem className="col-span-full rounded-md border border-ds-stroke-tertiary p-4">
                  <div className="stack-x-2">
                    <FormControl>
                      <Checkbox
                        checked={value}
                        onCheckedChange={onChange}
                        {...rest}
                      />
                    </FormControl>
                    <div className="stack-y-1">
                      <FormLabel>Reviewed</FormLabel>
                      <FormDescription className="max-w-prose">
                        I have reviewed that the information above is correct.
                      </FormDescription>
                    </div>
                  </div>
                  <FormMessage className="pl-6" />
                </FormItem>
              );
            }}
          />
        </form>
        <div>
          <div className="justify-end stack-x-4">
            <SheetClose asChild>
              <Button variant="secondary">Cancel</Button>
            </SheetClose>
            <Button
              form="reactivate-participant-account-form"
              disabled={isLoading}
              isLoading={isLoading}
              type="submit"
            >
              Reactivate
            </Button>
          </div>
        </div>
      </div>
    </Form>
  );
}

const ReactivateParticipantFormSchema = z.object({
  firstName: z.string().min(1, {
    message: 'Please enter a first name',
  }),
  lastName: z.string().min(1, {
    message: 'Please enter a last name',
  }),
  email: z.string().email(),
  exoId: z
    .object(
      {
        id: z.coerce.number(),
        name: z.string(),
      },
      {
        errorMap: () => ({ message: 'Please select a participant manager' }),
      }
    )
    .transform(idFromObject),
  originalResume: z.string().url().max(255),
  admitDecision: z.string({
    errorMap: () => ({ message: 'Please select an admit decision' }),
  }),
  linkedin: z.string().url(),
  reviewed: z.literal(true, {
    errorMap: () => ({
      message: 'Please confirm that the information is correct',
    }),
  }),
});

type ReactivateParticipantFormInput = z.input<
  typeof ReactivateParticipantFormSchema
>;
type ReactivateParticipantFormOutput = z.output<
  typeof ReactivateParticipantFormSchema
>;

const FORM_DEFAULT_VALUES = {
  email: '',
  firstName: '',
  lastName: '',
  originalResume: '',
  linkedin: '',
} satisfies Partial<ReactivateParticipantFormInput>;
