import * as React from 'react';
import styled from 'styled-components';

interface BaseProps {
  children: React.ReactNode;
}

interface ItemProps extends BaseProps {
  display?: string;
}

const UnstyledList = styled('ul')<BaseProps>`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const Item = styled('li')<ItemProps>`
  display: ${(props) => props.display || 'inline-block'};
`;

type UnstyledListType = typeof UnstyledList & {
  Item: typeof Item;
};

const StyledUnstyledList: UnstyledListType = Object.assign(UnstyledList, {
  Item,
});

export default StyledUnstyledList;
