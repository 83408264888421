import { UserProfileResponse, useUserProfile } from 'api/user/user';

import { isAdminUser } from 'api/user/user.helpers';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from 'components/ds/AlertDialog';
import { Button, ButtonIcon } from 'components/ds/Button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from 'components/ds/DropdownMenu';
import { Icon, IconUse } from 'components/ds/icons/Icon';
import { toast } from 'components/ds/Toast/Toast';
import {
  useActivateParticipantAccount,
  useDeactivateAccount,
  useReactivateParticipantAccount,
} from 'modules/team/participant/api/participant';
import { ActivateParticipantAccountSheet } from 'modules/team/participant/components/ActivateParticipantAccountSheet';
import { ReactivateParticipantAccountSheet } from 'modules/team/participant/components/ReactivateParticipantAccountSheet';
import {
  ParticipantResumeUploadForm,
  ResumeUploadAction,
  ResumeUploadDialogProvider,
} from 'modules/team/participant/components/UploadParticipantResume';
import { PARTICIPANT_PATHS } from 'modules/team/participant/constants/participant.constants';
import * as React from 'react';
import { Link } from 'react-router-dom';

interface ParticipantActionsProps {
  participant: UserProfileResponse;
  mutate: () => void;
}
export function ParticipantActions({
  participant,
  mutate,
}: ParticipantActionsProps) {
  const { id } = participant;
  const { data: user } = useUserProfile();
  const [container, setContainer] = React.useState<HTMLElement | null>(null);

  const isActiveParticipant = participant.active;
  const isActivationPending = participant.status === 'Pending';

  return (
    <div>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            className="ml-auto"
            variant="secondary"
            suffix={
              <ButtonIcon>
                <IconUse id="arrow-down-s-line" />
              </ButtonIcon>
            }
          >
            Manage Participant
          </Button>
        </DropdownMenuTrigger>

        <DropdownMenuContent
          container={container}
          align="end"
          className="min-w-[150px]"
        >
          <DropdownMenuGroup>
            <DropdownMenuLabel className="sr-only">
              List of participant actions
            </DropdownMenuLabel>
            <DropdownMenuItem asChild>
              <Link
                to={PARTICIPANT_PATHS.edit(id.toString())}
                className="no-underline"
              >
                <Icon className="h-4 w-4">
                  <IconUse id="edit-box-line" />
                </Icon>
                <span className="px-1.5">Edit Profile</span>
              </Link>
            </DropdownMenuItem>

            {!isActiveParticipant && !isActivationPending && (
              <ActivateParticipantAccountSheet />
            )}

            {!isActiveParticipant &&
              !isActivationPending &&
              user?.previouslyActivated && (
                <ReactivateParticipantAccountSheet />
              )}

            {!isActiveParticipant && isActivationPending && (
              <>
                <ResendActivationEmailAction participant={participant} />
                <ResendReactivationEmailAction participant={participant} />
                <CancelAccountActivationAction participant={participant} />
              </>
            )}

            <ResumeUploadDialogProvider>
              <ResumeUploadAction>
                <ParticipantResumeUploadForm
                  onSuccessfulUpload={() => {
                    mutate();
                  }}
                />
              </ResumeUploadAction>
            </ResumeUploadDialogProvider>

            {isActiveParticipant && (
              <DeactivateAccountAction participant={participant} />
            )}

            {isAdminUser(user?.roles) && isActiveParticipant && (
              <DropdownMenuItem asChild>
                <ImpersonateButton participant={participant} />
              </DropdownMenuItem>
            )}
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>

      <div ref={setContainer} />
    </div>
  );
}

function ResendReactivationEmailAction({
  participant,
}: {
  participant: UserProfileResponse;
}) {
  const resendReactivationMutation = useReactivateParticipantAccount(
    participant.id
  );

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <DropdownMenuItem
          onSelect={(event) => {
            // Prevent the dropdown from closing when the delete button is clicked.
            event.preventDefault();
          }}
        >
          <Icon className="h-4 w-4">
            <IconUse id="send-plane-line" />
          </Icon>
          <span className="px-1.5">Resend Reactivation Email</span>
        </DropdownMenuItem>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            Resend reactivation email to {participant.firstName}?
          </AlertDialogTitle>
          <AlertDialogDescription>
            This action will resend the reactivation email to{' '}
            {participant.firstName} {participant.lastName}.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction
            variant="default"
            onClick={() => {
              toast.promise(resendReactivationMutation.mutateAsync({}), {
                loading: 'Resending reactivation email...',
                success: 'Successfully resent reactivation email',
                error: 'Failed to resend reactivation email',
              });
            }}
          >
            <Icon className="h-4 w-4">
              <IconUse id="send-plane-fill" />
            </Icon>
            <span className="px-1.5">Send</span>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

function ResendActivationEmailAction({
  participant,
}: {
  participant: UserProfileResponse;
}) {
  const resendActivationMutation = useActivateParticipantAccount(
    participant.id
  );

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <DropdownMenuItem
          onSelect={(event) => {
            // Prevent the dropdown from closing when the delete button is clicked.
            event.preventDefault();
          }}
        >
          <Icon className="h-4 w-4">
            <IconUse id="send-plane-line" />
          </Icon>
          <span className="px-1.5">Resend Activation Email</span>
        </DropdownMenuItem>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            Resend activation email to {participant.firstName}?
          </AlertDialogTitle>
          <AlertDialogDescription>
            This action will resend the activation email to{' '}
            {participant.firstName} {participant.lastName}.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction
            variant="default"
            onClick={() => {
              toast.promise(resendActivationMutation.mutateAsync({}), {
                loading: 'Resending activation email...',
                success: 'Successfully resent activation email',
                error: 'Failed to resend activation email',
              });
            }}
          >
            <Icon className="h-4 w-4">
              <IconUse id="send-plane-fill" />
            </Icon>
            <span className="px-1.5">Send</span>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

function DeactivateAccountAction({
  participant,
}: {
  participant: UserProfileResponse;
}) {
  const deactivateAccountMutation = useDeactivateAccount(participant.id);

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <DropdownMenuItem
          onSelect={(event) => {
            // Prevent the dropdown from closing when the delete button is clicked.
            event.preventDefault();
          }}
          className="items-center stack-x-1 hover:bg-ds-red-lightest hover:text-ds-state-error focus:bg-ds-red-lightest focus:text-ds-state-error"
        >
          <Icon className="h-4 w-4">
            <IconUse id="user-unfollow-line" />
          </Icon>
          Deactivate
        </DropdownMenuItem>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            Are you sure you want to deactivate {participant.firstName}?
          </AlertDialogTitle>
          <AlertDialogDescription>
            This action will revoke {participant.firstName}'s access to the
            Platform.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction
            onClick={() => {
              toast.promise(deactivateAccountMutation.mutateAsync(), {
                loading: 'Deactivating account...',
                success: 'Successfully deactivated account',
                error: 'Failed to deactivate account',
              });
            }}
          >
            Deactivate
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

function CancelAccountActivationAction({
  participant,
}: {
  participant: UserProfileResponse;
}) {
  const cancelAccountActivationMutation = useDeactivateAccount(participant.id);

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <DropdownMenuItem
          onSelect={(event) => {
            // Prevent the dropdown from closing when the delete button is clicked.
            event.preventDefault();
          }}
          className="items-center stack-x-1"
        >
          <Icon className="h-4 w-4">
            <IconUse id="arrow-go-back-line" />
          </Icon>
          Cancel Activation
        </DropdownMenuItem>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            Are you sure you want to cancel activation for{' '}
            {participant.firstName}?
          </AlertDialogTitle>
          <AlertDialogDescription>
            This action will cancel any activation for {participant.firstName}.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction
            onClick={() => {
              toast.promise(cancelAccountActivationMutation.mutateAsync(), {
                loading: 'Cancelling account activation...',
                success: 'Successfully cancelled account activation',
                error: 'Failed to cancel account activation',
              });
            }}
          >
            Cancel Activation
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

const ImpersonateButton = React.forwardRef<
  HTMLButtonElement,
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    participant: UserProfileResponse;
  }
>(({ onClick, participant, ...rest }, ref) => {
  return (
    <button
      {...rest}
      ref={ref}
      onClick={(e) => {
        onClick?.(e);
        localStorage.setItem(
          'BL_MD',
          JSON.stringify({
            role: 'participant',
            firstName: participant.firstName,
            lastName: participant.lastName,
            id: participant.id,
            type: participant.type,
            username: participant.username,
          })
        );
        window.location.href = '/';
      }}
    >
      <span className="truncate">Impersonate</span>
    </button>
  );
});
ImpersonateButton.displayName = 'InpersonateButton';
