import { LoadingContainer } from 'components/ds/Spinner';
import ExternalLink from 'components/ExternalLink';
import {
  TabNavigation,
  TabNavigationItem,
} from 'components/layout-v2/TabNavigation';
import PageTitle from 'components/PageTitle';
import ResourceInfoBlock from 'components/UI/participant/ResourceInfoBlock';
import dataService from 'dataService';
import { ParticipantActions } from 'modules/team/participant/components/ParticipantActions';
import PropTypes from 'prop-types';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { bp } from 'styles/helpers';
import screen from 'superior-mq';

const ProfilePageTitle = styled(PageTitle)`
  display: grid;
  grid-column-gap: 40px;
  grid-template-columns: 75% 25%;
  margin-bottom: 32px;

  ${screen.below(
    bp.portrait,
    `
    grid-template-columns: 100%;
    `
  )}
`;

const ManageWrap = styled.div`
  ${screen.below(
    bp.portrait,
    `
    width: 100%;
    margin: 8px 0 0 56px;
  `
  )}
`;

const BreaklineParticipantView = ({ id }) => {
  const { data, isLoading, isError, mutate } = dataService.useGet(
    `participants/${id}`
  );

  if (!data && isLoading) return <LoadingContainer level="page" />;
  if (isError) {
    throw new Error('There was an error loading this participant.');
  }

  return (
    <>
      <Helmet title={`${data.firstName} ${data.lastName}`} />
      <ProfilePageTitle>
        <ResourceInfoBlock
          participant={data}
          primaryTitleSize="large"
          detailed
        />

        <ManageWrap>
          <ParticipantActions participant={data} mutate={mutate} />
        </ManageWrap>
      </ProfilePageTitle>

      {data.profile?.oneLiner ? (
        <p>
          {data.profile?.linkedin ? (
            <ExternalLink href={data.profile.linkedin}>
              {data.firstName}
            </ExternalLink>
          ) : (
            data.firstName
          )}{' '}
          is {data.profile?.oneLiner}
        </p>
      ) : null}

      <TabNavigation>
        <TabNavigationItem to={`/participants/${id}`} end>
          Profile
        </TabNavigationItem>
        <TabNavigationItem to={`/participants/${id}/opportunities`} end>
          Opportunities
        </TabNavigationItem>
        <TabNavigationItem to={`/participants/${id}/coaching`} end>
          Coaching
        </TabNavigationItem>
        <TabNavigationItem to={`/participants/${id}/statuses`} end>
          Statuses
        </TabNavigationItem>
      </TabNavigation>

      <React.Suspense fallback={<LoadingContainer level="component" />}>
        <Outlet context={{ userMutate: mutate }} />
      </React.Suspense>
    </>
  );
};

BreaklineParticipantView.propTypes = {
  id: PropTypes.string,
};

export default BreaklineParticipantView;
