import { useSuspenseQuery } from '@tanstack/react-query';
import { JOBS_QUERY_OPTIONS, prefetchJobDetail } from 'api/jobs';
import { LoadingContainer } from 'components/ds/Spinner';
import { FilterGroupProvider } from 'components/filters-new/context/FilterGroupContext';
import { getFilterParams } from 'components/filters-new/helpers/getFilterParams';
import AddParticipantsTable, {
  ADD_PARTICIPANT_TABLE_KEY,
  AddParticipants,
} from 'components/jobs/brainstorm/AddParticipants';
import GamePlanParticipantsTable, {
  GamePlanParticipants,
  PARTICIPANTS_TABLE_SEARCH_PARAMS_KEY,
} from 'components/jobs/brainstorm/GamePlanParticipants';
import { ComponentSearchParamsProvider } from 'context/ComponentSearchParams';
import { compact } from 'lodash';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { getTableParamsFromRequest } from 'util/tableHelpers';

export function jobParticipantGamePlansLoader(
  queryClient,
  { params, request }
) {
  try {
    const { jobId } = params ?? {};
    const searchParams = new URL(request.url).searchParams;

    if (!jobId) {
      throw new Error('Job ID is required to load game plans');
    }

    queryClient.ensureQueryData(
      JOBS_QUERY_OPTIONS.brainstormsList(
        jobId,
        getTableParamsFromRequest({
          request,
          key: PARTICIPANTS_TABLE_SEARCH_PARAMS_KEY,
        })
      )
    );

    async function initJobPotentialParticipantsPromise() {
      const job = await prefetchJobDetail(jobId, queryClient);
      const filters = getFilterParams({
        key: ADD_PARTICIPANT_TABLE_KEY,
        searchParams,
        initialFilters: transformFiltersFromJob(job),
      });

      const params = getTableParamsFromRequest({
        key: ADD_PARTICIPANT_TABLE_KEY,
        request,
        filters,
      });

      return queryClient.ensureQueryData(
        JOBS_QUERY_OPTIONS.potentialParticipantsList(jobId, params)
      );
    }
    initJobPotentialParticipantsPromise();

    return null;
  } catch (error) {
    return null;
  }
}

export function JobParticipantGamePlans() {
  return (
    <div className="stack-y-12">
      <GamePlanParticipants>
        <React.Suspense fallback={<LoadingContainer level="component" />}>
          <GamePlanParticipantsTable />
        </React.Suspense>
      </GamePlanParticipants>

      <AddParticipants>
        <React.Suspense fallback={<LoadingContainer level="component" />}>
          <AddParticipantsTableWrapper>
            <FilterGroupProvider>
              <AddParticipantsTable />
            </FilterGroupProvider>
          </AddParticipantsTableWrapper>
        </React.Suspense>
      </AddParticipants>
    </div>
  );
}

function AddParticipantsTableWrapper({ children }) {
  const { jobId } = useParams();
  const { data: initialFilters } = useSuspenseQuery({
    ...JOBS_QUERY_OPTIONS.detail(jobId),
    select: transformFiltersFromJob,
  });

  return (
    <ComponentSearchParamsProvider
      paramKey={ADD_PARTICIPANT_TABLE_KEY}
      initialSearchParams={initialFilters}
    >
      {children}
    </ComponentSearchParamsProvider>
  );
}

function transformFiltersFromJob(job) {
  if (!job)
    return {
      function: [],
      segment: [],
      skill: [],
    };

  return {
    function: compact(
      job.personas?.map((tag) => {
        const { persona } = tag ?? {};
        return {
          id: persona?.id,
          name: persona?.name,
        };
      })
    ),
    segment: compact(
      job.functionalAreas?.map((tag) => {
        return {
          id: tag?.id,
          name: tag?.name,
        };
      })
    ),
    skill: compact(
      job.requiredSkills?.map((tag) => {
        return {
          id: tag?.id,
          name: tag?.name,
        };
      })
    ),
  };
}
