export const USER_TYPES = {
  breakline: 'breakline',
  participant: 'participant',
} as const;

export const USER_ACCESS = {
  admin: 'admin',
  csm: 'csm',
  exo: 'exo',
  participant: 'participant',
  waitlist: 'waitlist',
} as const;

export const USER_ADMIT_DECISIONS = {
  Participant: 'Participant',
  Boomerang: 'Boomerang',
  'Talent Portfolio': 'Talent Portfolio',
  'Fast Track': 'Fast Track',
} as const;
