import create from 'zustand';

/**
 * Global store to manage notifications and tasks. The task count is used to
 * trigger a mutate on the individual pages.
 */
const useNotificationStore = create((set) => ({
  taskCount: 0,
  notificationCount: 0,
  incrementTaskCount: () =>
    set((state) => ({ taskCount: state.taskCount + 1 })),
  incrementNotificationCount: () =>
    set((state) => ({ notificationCount: state.notificationCount + 1 })),
}));

export default useNotificationStore;
