import { useSuspenseQuery } from '@tanstack/react-query';
import { USER_OPTIONS } from 'api/user/user';
import { isBreakLineUser } from 'api/user/user.helpers';
import { LD_FEATURE_FLAGS } from 'constants/ld.constants';
import * as ld from 'launchdarkly-react-client-sdk';
import * as React from 'react';

interface LaunchDarklyWrapperProps {
  children?: React.ReactNode;
}
export function LaunchDarklyWrapper({ children }: LaunchDarklyWrapperProps) {
  // Suspend until the user is loaded.
  useSuspenseQuery(USER_OPTIONS.user());

  return <LaunchDarklyProvider>{children}</LaunchDarklyProvider>;
}

interface LaunchDarklyProviderProps {
  children?: React.ReactNode;
}
function LaunchDarklyProvider({ children }: LaunchDarklyProviderProps) {
  const { data: user } = useSuspenseQuery(USER_OPTIONS.user());
  const { data: ldData } = useSuspenseQuery({
    queryKey: ['ld', user.id, user.type, user.email, user.ldHash],
    queryFn: async () => {
      const Provider = await ld.asyncWithLDProvider({
        clientSideID: LD_CLIENT_ID,
        context: {
          kind: 'user',
          key: `plat_${user.id}`,
          // Only send email for internal users
          email: isBreakLineUser(user.type) ? user.email : undefined,
          groups:
            user.segments?.map((segment: { name: string }) => segment.name) ??
            [],
        },
        options: {
          application: {
            id: 'breakline-react-app',
          },
          hash: user.ldHash,
        },
        reactOptions: {
          useCamelCaseFlagKeys: false,
        },
        flags: {
          ...LD_FEATURE_FLAGS,
        },
      });

      return { Provider };
    },
    staleTime: Infinity,
  });
  const { Provider } = ldData;

  return <Provider>{children}</Provider>;
}

const LD_CLIENT_ID = process.env.REACT_APP_LD_CLIENT_ID ?? '';
