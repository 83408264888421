import { tv } from 'tailwind-variants';

export const text = tv(
  {
    base: 'text-ds-text-primary font-normal m-0 max-w-prose',
    variants: {
      variant: {
        '24': 'text-2xl',
        '20': 'text-xl',
        '18': 'text-lg',
        '16': 'text-base',
        '14': 'text-sm',
        '12': 'text-xs',
      },
    },
    defaultVariants: {
      variant: '16',
    },
  },
  {
    responsiveVariants: true,
  }
);
