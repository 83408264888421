import * as React from 'react';

interface IconRefreshProps extends React.ComponentPropsWithoutRef<'svg'> {
  color?: string;
}
export const IconRefresh = React.forwardRef<SVGSVGElement, IconRefreshProps>(
  ({ color = 'currentColor', ...rest }, ref) => {
    return (
      <svg
        {...rest}
        fill="none"
        width="20"
        height="20"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        ref={ref}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 2a1 1 0 0 1 1 1v2.101a7.002 7.002 0 0 1 11.601 2.566 1 1 0 1 1-1.885.666A5.002 5.002 0 0 0 5.999 7H9a1 1 0 0 1 0 2H4a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1Zm.008 9.057a1 1 0 0 1 1.276.61A5.002 5.002 0 0 0 14.001 13H11a1 1 0 0 1 0-2h5a1 1 0 0 1 1 1v5a1 1 0 0 1-2 0v-2.101a7.002 7.002 0 0 1-11.601-2.566 1 1 0 0 1 .61-1.276h-.001Z"
          fill={color}
        />
      </svg>
    );
  }
);
IconRefresh.displayName = 'IconRefresh';
