import { QueryClient } from '@tanstack/react-query';
import { USER_OPTIONS } from 'api/user/user';
import { USER_ACCESS } from 'api/user/user.constants';
import { notFound } from 'util/notFound';

export async function userAccessGuardLoader(
  access: (keyof typeof USER_ACCESS)[],
  queryClient: QueryClient
) {
  const user = await queryClient.ensureQueryData({
    ...USER_OPTIONS.user(),
    retry: false,
  });
  const hasAccess = user?.roles?.some((role: keyof typeof USER_ACCESS) => {
    return access.includes(role);
  });

  if (!hasAccess) {
    notFound();
  }

  return null;
}
