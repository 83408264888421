import * as React from 'react';
import { twMerge } from 'tailwind-merge';
import { VariantProps, tv } from 'tailwind-variants';

interface SpinnerProps
  extends Omit<React.ComponentPropsWithoutRef<'div'>, 'color'>,
    SpinnerVariantProps {
  label?: string;
  classNames?: {
    base?: string;
    wrapper?: string;
    circle1?: string;
    circle2?: string;
    label?: string;
  };
}
export const Spinner = React.forwardRef<HTMLDivElement, SpinnerProps>(
  (
    {
      className,
      classNames,
      children,
      label: labelProp,
      color,
      size,
      labelColor,
      ...rest
    },
    ref
  ) => {
    const slots = spinnerStyles({
      color,
      size,
      labelColor,
    });
    const providedAriaLabel = rest['aria-label'];
    const label = labelProp || children;
    const ariaLabel = React.useMemo(() => {
      if (label && typeof label === 'string') {
        return label;
      }

      return !providedAriaLabel ? 'Loading...' : '';
    }, [label, providedAriaLabel]);

    return (
      <div
        ref={ref}
        aria-label={ariaLabel}
        className={slots.base({
          className,
        })}
        {...rest}
      >
        <svg
          className={slots.wrapper({ className: classNames?.wrapper })}
          viewBox="0 0 50 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <clipPath id="quarterCircle">
              <path d="M25,25 L50,25 A25,25 0 0,1 25,50 Z" />
            </clipPath>
          </defs>
          <circle
            className={slots.circle1({ className: classNames?.circle1 })}
            cx="25"
            cy="25"
            r="20"
            fill="none"
            strokeWidth="6"
            stroke="currentColor"
            strokeLinecap="round"
            style={{
              strokeDasharray: '125.66',
              strokeDashoffset: '94.25', // 75% of 125.66
            }}
          />
          <circle
            className={slots.circle2({ className: classNames?.circle2 })}
            cx="25"
            cy="25"
            r="20"
            fill="none"
            strokeWidth="6"
            stroke="currentColor"
            strokeLinecap="round"
            style={{
              strokeDasharray: '0.1 7.755', // 0.1 units dot, 7.755 units gap
              strokeDashoffset: '-3.9275', // Offset to center the dots
            }}
            clipPath="url(#quarterCircle)"
          />
        </svg>
        {label && (
          <span className={slots.label({ className: classNames?.label })}>
            {label}
          </span>
        )}
      </div>
    );
  }
);
Spinner.displayName = 'Spinner';

interface LoadingContainerProps
  extends Omit<React.ComponentPropsWithoutRef<'div'>, 'color'>,
    React.ComponentProps<typeof Spinner> {
  level?: 'full' | 'page' | 'component';
  children?: React.ReactNode;
}
export function LoadingContainer({
  children,
  className,
  level = 'page',
  ...rest
}: LoadingContainerProps) {
  return (
    <div
      className={twMerge(
        'flex items-center justify-center',
        SPINNER_LEVELS[level],
        className
      )}
    >
      {children ? children : <Spinner {...rest} />}
    </div>
  );
}
const SPINNER_LEVELS = {
  page: 'h-full fixed bg-ds-bg-foundation inset-0 left-[var(--sidebar-width)] z-50',
  full: 'h-full w-full bg-ds-bg-foundation fixed inset-0 z-50',
  component: 'flex items-center justify-center min-h-[200px]',
};

const spinnerStyles = tv({
  slots: {
    base: 'relative inline-flex flex-col gap-2 items-center justify-center',
    wrapper: 'relative flex',
    circle1:
      'absolute origin-center animate-spinner-ease-spin w-full h-full will-change-transform rounded-full ',
    circle2:
      'absolute w-full h-full origin-center animate-spinner-linear-spin will-change-transform rounded-full opacity-75',
    label: 'text-ds-text-primary font-normal',
  },
  variants: {
    size: {
      xs: {
        wrapper: 'w-4 h-4',
        label: 'text-xs',
      },
      sm: {
        wrapper: 'w-6 h-6',
        label: 'text-sm',
      },
      md: {
        wrapper: 'w-12 h-12',
        label: 'text-base',
      },
      lg: {
        wrapper: 'w-16 h-16',
        label: 'text-lg',
      },
      xl: {
        wrapper: 'w-20 h-20',
        label: 'text-xl',
      },
    },
    color: {
      current: {
        circle1: 'text-current',
        circle2: 'text-current',
      },
      primary: {
        circle1: 'text-ds-primary-base',
        circle2: 'text-ds-primary-base',
      },
      secondary: {
        circle1: 'text-ds-secondary-base',
        circle2: 'text-ds-secondary-base',
      },
    },
    labelColor: {
      foreground: {
        label: 'text-ds-text-primary',
      },
      primary: {
        label: 'text-ds-primary-base',
      },
      secondary: {
        label: 'text-ds-secondary-base',
      },
    },
  },
  defaultVariants: {
    size: 'md',
    color: 'primary',
    labelColor: 'foreground',
  },
});

type SpinnerVariantProps = VariantProps<typeof spinnerStyles>;
