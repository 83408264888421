import PlayButton from 'components/PlayButton';
import { position } from 'polished';
import styled from 'styled-components';
import { bp } from 'styles/helpers';
import screen from 'superior-mq';

export const VideoButton = styled(PlayButton)`
  ${position('absolute', '-12px', null, null, '-12px')};

  ${screen.below(
    bp.mobile,
    `
    top: -20px;
    left: -17px;
  `
  )}
`;
