import { InfiniteData } from '@tanstack/react-query';
import { useFilterGroup } from 'components/filters-new/context/FilterGroupContext';
import { Filter } from 'components/filters-new/types/filters';
import { useDebounce } from 'hooks/useDebounce';
import { useSubscription } from 'observable-hooks';
import * as React from 'react';

export function useSelectedFilters(groupId: string) {
  const { filters, actions, events } = useFilterGroup();
  const initialFiltersFromStore = React.useMemo(() => {
    return filters[groupId] ?? [];
  }, [filters, groupId]);
  const [selectedFilters, setSelectedFilters] = React.useState<Filter[]>(
    initialFiltersFromStore
  );

  useSubscription(events.clearChildren$, () => {
    setSelectedFilters([]);
  });

  function handleCheckedChange(filter: Filter, checked: boolean) {
    function addFilter() {
      setSelectedFilters((prev) => [...prev, filter]);
    }
    function removeFilter() {
      setSelectedFilters((prev) => prev.filter((f) => f.id !== filter.id));
    }

    if (checked) {
      addFilter();
    } else {
      removeFilter();
    }
  }

  function resetSelectedFilters() {
    setSelectedFilters(initialFiltersFromStore);
  }

  function emptySelectedFilters() {
    setSelectedFilters([]);
  }

  function applySelectedFilters() {
    function sortBySelected(a: Filter, b: Filter) {
      const aSelected = selectedFilters.some((f) => f.id === a.id);
      const bSelected = selectedFilters.some((f) => f.id === b.id);
      if (aSelected && !bSelected) {
        return -1;
      }
      if (!aSelected && bSelected) {
        return 1;
      }
      return 0;
    }
    // We only sort on apply to prevent the list from jumping around when the user selects a filter
    const sortedSelectedFilters = [...selectedFilters].sort(sortBySelected);

    // Slight delay to account for popover closing animation
    actions.setFilters({
      groupId,
      filters: sortedSelectedFilters,
    });
  }

  return {
    selectedFilters,
    handleCheckedChange,
    resetSelectedFilters,
    emptySelectedFilters,
    applySelectedFilters,
  };
}

export function useFilterSearch() {
  const [searchTerm, setSearchTerm] = React.useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const searchFilterParams = React.useMemo(() => {
    return {
      ...(debouncedSearchTerm ? { term: debouncedSearchTerm } : {}),
    };
  }, [debouncedSearchTerm]);

  function resetSearchTerm() {
    setSearchTerm('');
  }

  return {
    searchFilterParams,
    searchTerm,
    setSearchTerm,
    resetSearchTerm,
    debouncedSearchTerm,
  };
}

export function useFilters<
  TInfiniteData extends InfiniteData<{ items: Filter[] }>,
>(data: TInfiniteData | undefined, searchTerm: string, groupId: string) {
  const { filters: collection, actions } = useFilterGroup();
  const initialFiltersFromStore = React.useMemo(() => {
    return collection[groupId] ?? [];
  }, [collection, groupId]);

  const filters = React.useMemo(() => {
    function filterInitial(f: Filter) {
      return !initialFiltersFromStore.some((sf) => sf.id === f.id);
    }
    const flattenedFetchedFilters =
      data?.pages
        .flatMap((page) => {
          return page.items;
        })
        .map((f) => {
          return {
            id: String(f.id),
            name: f.name,
          };
        })
        .filter(filterInitial) ?? [];

    return [...initialFiltersFromStore, ...flattenedFetchedFilters].filter(
      (f) => f.name?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [initialFiltersFromStore, data, searchTerm]);

  return {
    filters,
    initialFiltersFromStore,
    actions,
  };
}
