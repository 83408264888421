import { QueryClient } from '@tanstack/react-query';
import { USER_OPTIONS } from 'api/user/user';
import { USER_TYPES } from 'api/user/user.constants';
import { notFound } from 'util/notFound';

export async function userTypeGuardLoader(
  type: (keyof typeof USER_TYPES)[],
  queryClient: QueryClient
) {
  const user = await queryClient.ensureQueryData({
    ...USER_OPTIONS.user(),
    retry: false,
  });

  if (!user || !type.includes(user.type)) {
    notFound();
  }

  return null;
}
