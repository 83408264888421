import useMediaQuery from 'hooks/useMediaQuery';
import * as React from 'react';

const SidebarNavContext = React.createContext<{
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
} | null>(null);

interface SidebarNavProviderProps {
  children?: React.ReactNode;
}
export function SidebarNavProvider({ children }: SidebarNavProviderProps) {
  const isDesktop = useMediaQuery('(min-width: 1281px)');
  const [isOpen, setIsOpen] = React.useState(true);

  return (
    <SidebarNavContext.Provider
      value={isDesktop ? { isOpen, setIsOpen } : null}
    >
      {children}
    </SidebarNavContext.Provider>
  );
}

export function useSidebarNavState() {
  const context = React.useContext(SidebarNavContext);

  return context;
}
