import { useUserProfile } from 'api/user/user';
import { isBreakLineUser } from 'api/user/user.helpers';
import Avatar from 'components/Avatar';
import ExternalLink from 'components/ExternalLink';
import { H2 } from 'components/H';
import InlineLink from 'components/InlineLink';
import { useModal } from 'components/Modal';
import { VideoButton } from 'components/participants/participant/VideoButton';
import Tooltip from 'components/Tooltip';
import BasicTitle from 'components/UI/BasicTitle';
import { TagController } from 'components/UI/Tag';
import VideoModal from 'components/VideoModal';
import { differenceInHours, parseISO } from 'date-fns';
import { rem } from 'polished';
import { useMemo } from 'react';
import { HashLink } from 'react-router-hash-link';
import styled from 'styled-components';
import { tv } from 'tailwind-variants';
import { interviewStatusColors, statusColors } from 'util/statusColors';
import { weeksSince } from 'util/timeHelpers';

const Wrap = styled.div`
  display: grid;
  grid-gap: 8px;
`;

const Flame = styled.img`
  display: block;
  width: 18px;
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const PrimaryTitle = styled.div`
  color: var(--black);
  text-decoration: none;
  display: inline-block;
  margin-right: 3px;
  margin-bottom: 0;

  h2,
  h5 {
    display: inline;
    vertical-align: middle;
  }

  h2 {
    font-size: ${rem(24)};
  }

  h5 {
    font-size: ${rem(16)};
    font-weight: 500;
  }

  a {
    color: currentColor;
    text-decoration: none;
  }
`;

const Pronunciation = styled.span`
  color: var(--gray-text);
  font-size: 0.875rem;
  margin-right: 3px;
`;

const InfoLink = styled.span`
  font-size: ${rem(15)};

  &:not(:last-child) {
    margin-right: 8px;

    &::after {
      content: '|';
      padding-left: 8px;
      color: var(--border-gray);
    }
  }
`;

const infoLink = tv({
  base: `text-[15px] mr-2 after:content-['|'] after:text-[#BBB] after:pl-2 last:after:hidden`,
});

const InfoLinks = styled.div`
  margin-top: -7px;

  &:not(:last-of-type) {
    margin-bottom: 8px;
  }
`;

const Statuses = styled.div`
  padding-bottom: 8px;
`;
const TopLine = styled.div`
  padding-bottom: 3px;
`;

const AvatarWrap = styled.div`
  position: relative;
`;

const jobStatusSort = (a, b) => {
  const order = {
    Hold: 1,
    'Waiting to Hear': 2,
    Backfill: 3,
    Evergreen: 4,
    'No Backfill': 5,
    default: 1000,
  };

  return (
    (order[a.status?.type] || order.default) -
    (order[b.status?.type] || order.default)
  );
};

const userStatusSort = (a, b) => {
  const order = {
    'Hired - Partner': 1,
    'Hired - Non-Partner': 2,
    'CS Sprint': 3,
    'Top 5': 4,
    'Red Flag': 5,
    'Green Flag': 6,
    Hold: 7,
    Unsubscribed: 8,
    Exclude: 9,
    Ready: 10,
    'Not Ready': 11,
    default: 1000,
  };

  return (
    (order[a.status?.type] || order.default) -
    (order[b.status?.type] || order.default)
  );
};

const ResourceInfoBlock = ({
  job = undefined,
  partner = undefined,
  partnerNameLinkInNewTab = true,
  participant = undefined,
  participantNameLinkInNewTab = true,
  hideAvatar = false,
  updateLabel = '',
  detailed = false,
  primaryURL = '',
  primaryTitleSize = 'small',
  showHighPriority = false,
  showLocationTags = false,
  showTrackTags = false,
  showInterviewingStatusTag = false,
  showInfoLinks = true,
  additionalInfoLinks = [],
  additionalTags = [],
}) => {
  const { modalOpen, modalData, toggleModal } = useModal();
  const { data: user } = useUserProfile();
  const image = participant
    ? participant.headshot?.location
    : partner?.logo?.location;
  const nameParts = participant
    ? [participant.firstName, participant.lastName]
    : [job?.title];
  let nameUrl = participant
    ? `/participants/${participant.id}`
    : `/jobs/${job?.id}`;
  const { automatedStatus, statuses } = participant || job || {};
  const isHighPriority = statuses?.find(
    (statusRelation) => statusRelation.status?.type === 'High Priority'
  );
  const locations = job?.locations;
  const tracks = participant?.profile?.tracks || job?.tracks;

  let isNew = false;

  const infoLinks = useMemo(() => {
    const links = [];

    if (participant?.profile?.finalResume) {
      links.push(
        <ExternalLink
          key="link-final-resume"
          href={participant.profile.finalResume}
          className={infoLink()}
        >
          Resume
        </ExternalLink>
      );
    }

    if (participant?.profile?.linkedin) {
      links.push(
        <ExternalLink
          key="link-final-linkedin"
          href={participant.profile.linkedin}
          className={infoLink()}
        >
          LinkedIn
        </ExternalLink>
      );
    }

    if (detailed && participant) {
      links.push(
        <InfoLink
          key="link-final-actionitems"
          as={HashLink}
          to={`/participants/${participant?.id}#action-items`}
        >
          Action Items
        </InfoLink>
      );
    }

    if (partner?.name) {
      links.push(
        partner.id ? (
          <InfoLink
            key="link-final-partnername"
            as={InlineLink}
            href={`/partners/${partner.id}`}
            forceNewTab={partnerNameLinkInNewTab}
          >
            {partner.name}
          </InfoLink>
        ) : (
          <InfoLink key="link-final-partnername">{partner.name}</InfoLink>
        )
      );
    }

    if (detailed && participant?.phone) {
      links.push(
        <InfoLinks key="link-final-phone">
          <InfoLink
            as={InlineLink}
            href={`tel:${participant.phone}`}
            forceNewTab
          >
            {participant.phone}
          </InfoLink>
        </InfoLinks>
      );
    }

    if (detailed && participant?.email) {
      links.push(
        <InfoLinks key="link-final-email">
          <InfoLink
            as={InlineLink}
            href={`mailto:${participant.email}`}
            forceNewTab
          >
            {participant.email}
          </InfoLink>
        </InfoLinks>
      );
    }

    return [...links, ...additionalInfoLinks];
  }, [
    participant,
    detailed,
    partner?.name,
    partner?.id,
    additionalInfoLinks,
    partnerNameLinkInNewTab,
  ]);

  try {
    isNew = participant
      ? differenceInHours(new Date(), parseISO(participant?.createdAt)) < 96
      : differenceInHours(new Date(), parseISO(job?.createdAt)) < 96;
  } catch (e) {
    // eslint-disable-next-line
    console.warn('Error calculating new status.');
  }

  const cols = [];

  if (showHighPriority) {
    cols.push('20px');
  }

  if (!hideAvatar) {
    cols.push('50px');
  }

  cols.push('auto');

  return (
    <>
      <Wrap
        isHighPriority={isHighPriority}
        style={{
          gridTemplateColumns: cols.join(' '),
        }}
      >
        {showHighPriority ? (
          <div style={{ marginTop: !hideAvatar ? 13 : 5 }}>
            {isHighPriority && isHighPriority?.context ? (
              <Tooltip content={isHighPriority?.context ?? false}>
                <Flame src="/images/flame.svg" />
              </Tooltip>
            ) : null}

            {isHighPriority && !isHighPriority?.context ? (
              <Flame src="/images/flame.svg" />
            ) : null}
          </div>
        ) : null}
        {!hideAvatar ? (
          <AvatarWrap>
            <Avatar
              src={image}
              size={50}
              fallback={nameParts}
              fallbackFontSize={13}
              alt=""
              style={{
                height: 50,
                width: 50,
                '--fallback-bg-color': 'var(--primary-green)',
                '--fallback-text-color': 'var(--white)',
              }}
            />
            {participant?.profile?.elevatorPitch ? (
              <VideoButton
                onClick={() =>
                  void toggleModal('video', participant.profile?.elevatorPitch)
                }
              />
            ) : null}
          </AvatarWrap>
        ) : null}
        <Details>
          {isBreakLineUser(user?.type) &&
          participant?.admitDecision &&
          participant?.admitDecision !== 'Participant' ? (
            <BasicTitle $green style={{ flex: 'auto' }}>
              {participant.admitDecision}
            </BasicTitle>
          ) : null}
          <TopLine>
            <PrimaryTitle>
              <H2 as={primaryTitleSize === 'large' ? 'h2' : 'h5'}>
                {nameUrl ? (
                  <InlineLink
                    href={primaryURL || nameUrl}
                    forceNewTab={participantNameLinkInNewTab}
                  >
                    {nameParts?.join(' ')}
                  </InlineLink>
                ) : (
                  nameParts?.join(' ')
                )}
              </H2>
            </PrimaryTitle>

            {detailed && participant?.profile?.pronunciation ? (
              <Pronunciation>
                &#x28;{participant.profile.pronunciation}&#x29;
              </Pronunciation>
            ) : null}
          </TopLine>
          <Statuses>
            {isBreakLineUser(user?.type) ? (
              <>
                {participant &&
                !participant?.active &&
                participant?.previouslyActivated ? (
                  <TagController
                    options={{ Deactivated: 'userNotActivated' }}
                    value={'Deactivated'}
                  />
                ) : null}
                {participant &&
                !participant?.active &&
                !participant?.previouslyActivated ? (
                  <TagController
                    options={{ 'Not Activated': 'userNotActivated' }}
                    value={'Not Activated'}
                  />
                ) : null}
                {automatedStatus?.name ? (
                  <TagController
                    options={statusColors}
                    key={`auto-status-${participant?.id}-${automatedStatus?.id}`}
                    value={automatedStatus.name}
                  />
                ) : null}

                {isNew ? (
                  <TagController options={statusColors} value="New" />
                ) : null}

                {statuses
                  ?.sort(participant ? userStatusSort : jobStatusSort)
                  ?.map((statusRelation) => {
                    const { type } = statusRelation?.status;
                    let valueModifier;

                    if (
                      type === 'CS Sprint' &&
                      participant.profile?.csSprintDate
                    ) {
                      const numberOfWeeks = weeksSince(
                        participant?.profile?.csSprintDate
                      );

                      valueModifier =
                        type === 'CS Sprint' &&
                        participant.profile?.csSprintDate
                          ? `${numberOfWeeks} week${
                              numberOfWeeks === 1 ? '' : 's'
                            }`
                          : '';
                    }

                    if (type !== 'High Priority') {
                      return (
                        <TagController
                          key={`manual-status-${participant?.id}-${statusRelation.status?.id}`}
                          options={statusColors}
                          value={statusRelation.status?.type}
                          valueModifier={valueModifier}
                          context={statusRelation.context}
                        />
                      );
                    }

                    return null;
                  })
                  ?.filter(Boolean)}

                {participant?.profile?.tier ||
                participant?.userProfile?.tier ||
                job?.tier ? (
                  <TagController
                    options={statusColors}
                    value={
                      participant?.profile?.tier ??
                      participant?.userProfile?.tier ??
                      job?.tier
                    }
                  />
                ) : null}
              </>
            ) : null}
            {showLocationTags && locations ? (
              <>
                {locations?.map((location) => (
                  <TagController
                    value={location.name}
                    key={`location-${location.id}`}
                  />
                ))}
              </>
            ) : null}
            {showTrackTags && tracks ? (
              <>
                {tracks?.map((track) => (
                  <TagController value={track.name} key={`track-${track.id}`} />
                ))}
              </>
            ) : null}
            {showInterviewingStatusTag && participant?.interviewingStatus ? (
              <>
                <TagController
                  value={participant?.interviewingStatus}
                  options={interviewStatusColors}
                />
              </>
            ) : null}
            {additionalTags?.length ? <>{additionalTags}</> : null}
          </Statuses>
          {showInfoLinks ? <InfoLinks>{infoLinks}</InfoLinks> : null}

          {updateLabel}
        </Details>
      </Wrap>
      <VideoModal
        modalOpen={modalOpen === 'video'}
        modalData={modalData}
        onRequestClose={toggleModal}
      />
    </>
  );
};

export default ResourceInfoBlock;
