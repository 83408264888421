import * as React from 'react';
type AuthContextType = {
  tempUser: any;
  setTempUser: React.Dispatch<React.SetStateAction<any>>;
};
const AuthContext = React.createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [tempUser, setTempUser] = React.useState<any>(null);
  return (
    <AuthContext.Provider value={{ tempUser, setTempUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
};
