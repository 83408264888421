import { usePreventScroll } from '@react-aria/overlays';
import { Icon, IconUse } from 'components/ds/icons/Icon';
import { NavMenu } from 'components/layout-v2/NavigationMenu';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { useLocation } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

export function MobileNav() {
  const { pathname } = useLocation();
  const triggerRef = React.useRef<HTMLButtonElement | null>(null);
  const firstFocusableRef = React.useRef<HTMLButtonElement | null>(null);

  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        setIsOpen(false);
        triggerRef.current?.focus();
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  React.useEffect(() => {
    if (isOpen) {
      /**
       * Manually apply focus to the visually hidden button which allows the
       * user to close the menu via the keyboard
       */
      firstFocusableRef.current?.focus();
    }
  }, [isOpen]);

  /** We want to close the mobile nav menu when the user navigates to a new page. */
  React.useEffect(() => {
    setIsOpen(false);
  }, [pathname]);

  usePreventScroll({ isDisabled: !isOpen });

  return (
    <>
      <button
        className="grid h-10 w-10 place-items-center md:hidden"
        onClick={() => {
          setIsOpen((prev) => !prev);
        }}
        ref={triggerRef}
      >
        <Icon className="h-4 w-4 text-ds-icon-primary" aria-hidden>
          {isOpen ? <IconUse id="close-line" /> : <IconUse id="menu-line" />}
        </Icon>
        <span className="sr-only">Toggle Navigation Menu</span>
      </button>
      <MobileNavMenuPortal>
        <nav
          data-state={isOpen ? 'open' : 'closed'}
          className={twMerge(
            'fixed left-0 flex h-full w-full bg-ds-bg-foundation data-[state=closed]:pointer-events-none data-[state=open]:pointer-events-auto data-[state=closed]:-translate-x-full data-[state=open]:translate-x-0'
          )}
        >
          {isOpen && (
            <>
              <button
                onClick={() => {
                  setIsOpen(false);
                  triggerRef.current?.focus();
                }}
                className="sr-only"
                ref={firstFocusableRef}
              >
                Close Navigation
              </button>
              <NavMenu
                isOpen
                onLinkSelection={(href) => {
                  /**
                   * Close the mobile nav menu when the user navigates to the
                   * same page.
                   */
                  if (href === pathname) {
                    setIsOpen(false);
                  }
                }}
              />
            </>
          )}
        </nav>
      </MobileNavMenuPortal>
    </>
  );
}

const MobileNavMenuPortalContext = React.createContext<
  | [
      HTMLDivElement | null,
      React.Dispatch<React.SetStateAction<HTMLDivElement | null>>,
    ]
  | null
>(null);

export function MobileNavMenuPortalProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const mobileNavMenuPortalState = React.useState<HTMLDivElement | null>(null);

  return (
    <MobileNavMenuPortalContext.Provider value={mobileNavMenuPortalState}>
      {children}
    </MobileNavMenuPortalContext.Provider>
  );
}

export function MobileNavMenuPortalContainer() {
  const [, setMobileNavMenuPortal] =
    React.useContext(MobileNavMenuPortalContext) ?? [];

  return (
    <div
      id="mobile-nav-menu"
      className="pointer-events-none h-full"
      ref={setMobileNavMenuPortal}
    />
  );
}

interface MobileNavMenuPortalProps {
  children: React.ReactNode;
}
function MobileNavMenuPortal({ children }: MobileNavMenuPortalProps) {
  const [mobileNavMenuPortal] =
    React.useContext(MobileNavMenuPortalContext) ?? [];

  return mobileNavMenuPortal
    ? ReactDOM.createPortal(children, mobileNavMenuPortal)
    : null;
}
