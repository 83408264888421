import Avatar, { SystemAvatar } from 'components/Avatar';
import Tooltip from 'components/Tooltip';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import InlineLink from '../InlineLink';
import DataCell from '../table/DataCell';

const AvatarWrap = styled.div`
  width: 80%;
  position: relative;
  display: flex;
`;

const AvatarLink = styled(InlineLink)`
  text-decoration: none;

  &:first-child {
    transform: translateX(11px);
  }
`;

const UserLink = ({ user, children }) =>
  user.type === 'participant' ? (
    <AvatarLink href={`/participants/${user.id}`}>{children}</AvatarLink>
  ) : (
    children
  );

const AddedByCell = ({ data, ...props }) => {
  if (!data) return null;
  const { addedBy: adder, addedSelf } = data;

  return (
    <DataCell {...props} style={{ width: '100%' }}>
      <AvatarWrap>
        {!adder && !addedSelf && (
          <SystemAvatar style={{ '--size': `33px` }} {...props} />
        )}

        {adder && (
          <Tooltip content={`${adder.firstName} ${adder.lastName}`}>
            <span>
              <UserLink user={adder}>
                <Avatar
                  src={adder.headshot?.location}
                  alt={`${adder.firstName} ${adder.lastName}`}
                  fallback={[adder.firstName, adder.lastName]}
                  size={33}
                  style={{ width: '33px' }}
                />
              </UserLink>
            </span>
          </Tooltip>
        )}

        {addedSelf && (
          <Tooltip
            content={`${data.participant.firstName} ${data.participant.lastName}`}
          >
            <span>
              <AvatarLink href={`/participants/${data.participant.id}`}>
                <Avatar
                  src={data.participant.headshot?.location}
                  alt=""
                  fallback={[
                    data.participant.firstName,
                    data.participant.lastName,
                  ]}
                  size={33}
                  style={{ width: '33px' }}
                />
              </AvatarLink>
            </span>
          </Tooltip>
        )}
      </AvatarWrap>
    </DataCell>
  );
};

UserLink.propTypes = {
  user: PropTypes.object,
  children: PropTypes.node,
};

AddedByCell.propTypes = {
  data: PropTypes.object,
};

export default AddedByCell;
